import { useEffect, useState } from "react";

const AUTH_CODE_EXPIRES_IN = 1000 * 60 * 3; // 3 minutes

interface TimerSpanProps {
  expiresIn?: number;
  onEnd: () => void;
  className?: string;
}
export const TimerSpan = ({ expiresIn, onEnd, className }: TimerSpanProps) => {
  const [currentTime, setCurrentTime] = useState(Date.now());
  const [expiredTime] = useState(
    currentTime + (expiresIn ? expiresIn : AUTH_CODE_EXPIRES_IN),
  );

  const diffTime = Math.max(expiredTime - currentTime, 0);
  const minutes = (Math.floor(diffTime / 1000 / 60) + "").padStart(1, "0");
  const seconds = (Math.floor((diffTime / 1000) % 60) + "").padStart(1, "0");

  useEffect(() => {
    let rafId: number | null = null;

    const refreshCurrentTime = () => {
      setCurrentTime(Date.now());
      rafId = requestAnimationFrame(refreshCurrentTime);
    };

    refreshCurrentTime();

    return () => {
      if (!!rafId) {
        cancelAnimationFrame(rafId);
      }
    };
  }, []);

  useEffect(() => {
    if (diffTime === 0) {
      onEnd();
    }
  }, [diffTime, onEnd]);

  return (
    <span className={className}>
      {minutes && `${minutes}:${seconds}`}
      {!minutes && `${seconds}`}
    </span>
  );
};

export const academyLandingCard = [
  {
    id: 1,
    title: "우리 학원 전용관 페이지 개설",
    description:
      "우리 학원만의 강점을 잘<br />보여줄 수 있는 전용관 페이지 운영",
  },
  {
    id: 2,
    title: "홍보/마케팅 지원",
    description:
      "우리 학원만의 강점을 잘<br />보여줄 수 있는 전용관 페이지 운영",
  },
  {
    id: 3,
    title: "글로벌 진출",
    description: "실시간 번역 솔루션을 활용한<br />해외 수강생 대상 강의 운영",
  },
] as const;

import { CSSProperties, FC } from "react";
import styled from "@emotion/styled";

interface SkeletonLoadingProps {
  style?: CSSProperties;
  className?: string;
}
export const SkeletonLoading: FC<SkeletonLoadingProps> = ({
  style,
  className,
}) => (
  <StyledSkeletonLoading
    style={style}
    className={className}
  />
);

const StyledSkeletonLoading = styled("div")`
  width: 100%;
  height: 100%;
  background-color: #f2f2f2;
  border-radius: 4px;
  display: inline-block;
  position: relative;
  overflow: hidden;

  ::before {
    content: "";
    position: absolute;
    top: 0;
    left: -100%;
    width: 100%;
    height: 100%;
    background: linear-gradient(
      90deg,
      transparent,
      rgba(255, 255, 255, 0.8),
      transparent
    );
    animation: skeleton-loading 1.5s infinite;
  }

  @keyframes skeleton-loading {
    0% {
      left: -100%;
    }
    100% {
      left: 100%;
    }
  }
`;

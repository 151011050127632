import {
  CSSProperties,
  FocusEvent,
  forwardRef,
  InputHTMLAttributes,
  ReactNode,
  useState,
} from "react";
import classNames from "classnames";
import styled from "@emotion/styled";

export interface TextFieldProps extends InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  children?: ReactNode;
  startAdornment?: ReactNode;
  endAdornment?: ReactNode;
  endActionButton?: ReactNode;
  border?: number;
  isError?: boolean;
  isRequired?: boolean;
  isLengthLabel?: boolean;
  message?: string | ReactNode;
  innerStyle?: CSSProperties;
}

export const TextField = forwardRef<HTMLInputElement, TextFieldProps>(
  (props, textFieldRef) => {
    const {
      label,
      children,
      startAdornment,
      endAdornment,
      endActionButton,
      border,
      isError,
      isRequired,
      isLengthLabel,
      maxLength,
      value,
      message,
      className,
      disabled,
      innerStyle,
      ...reset
    } = props;

    const [isFocus, setIsFocus] = useState(false);

    const onFocus = (e: FocusEvent<HTMLInputElement, Element>) => {
      setIsFocus(true);
      reset.onFocus && reset.onFocus(e);
    };

    const onBlur = (e: FocusEvent<HTMLInputElement, Element>) => {
      setIsFocus(false);
      reset.onBlur && reset.onBlur(e);
    };

    return (
      <TextFieldContainer className={className}>
        {label && (
          <TextFieldLabel>
            {label}
            {isRequired && <span>*</span>}
          </TextFieldLabel>
        )}

        <TextFieldInnerContainer>
          <TextFieldWrap>
            <TextFieldInnerWrap
              className={classNames({
                ["is-error"]: isError,
                ["is-focus"]: isFocus,
                ["is-disabled"]: disabled,
              })}
              style={innerStyle}
            >
              {startAdornment && (
                <AdornmentContainer
                  className={classNames({}, "is-start-adornment")}
                >
                  {startAdornment}
                </AdornmentContainer>
              )}
              <StyledTextField
                {...reset}
                ref={textFieldRef}
                className={classNames({
                  ["is-end-adornment"]: !!endAdornment,
                  [`border-${border}`]: !!border,
                  ["is-error"]: isError,
                })}
                disabled={disabled}
                onFocus={onFocus}
                onBlur={onBlur}
                maxLength={maxLength}
                value={value}
              />

              {endAdornment && (
                <AdornmentContainer
                  className={classNames({}, "is-end-adornment")}
                >
                  {endAdornment}
                </AdornmentContainer>
              )}
            </TextFieldInnerWrap>
            {endActionButton && <>{endActionButton}</>}
          </TextFieldWrap>
          {isLengthLabel && (
            <TextFieldValueLengthLabel>
              {`${typeof value === "string" ? value.length : 0}/${maxLength}`}
            </TextFieldValueLengthLabel>
          )}
        </TextFieldInnerContainer>
        {message && (
          <Message
            className={classNames({
              ["is-error"]: isError,
            })}
          >
            {message}
          </Message>
        )}
      </TextFieldContainer>
    );
  },
);

const TextFieldContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-content: center;
  gap: 8px;
`;

const TextFieldLabel = styled.div`
  display: flex;
  align-items: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 171.429% */
  letter-spacing: -0.4px;

  color: ${({ theme }) => theme.colors.text.high.black};

  span {
    ${({ theme }) => theme.typography.body2.m};
    color: ${({ theme }) => theme.colors.state.error.default};
  }
`;
const TextFieldInnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

const TextFieldValueLengthLabel = styled.div`
  display: flex;
  align-self: flex-end;
  ${({ theme }) => theme.typography.body3.m};
  color: ${({ theme }) => theme.colors.text.medium.black};
`;

const TextFieldWrap = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

const TextFieldInnerWrap = styled.div`
  flex-grow: 2;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  padding: 12px 16px;

  border-radius: 4px;
  border: 1px solid ${({ theme }) => theme.colors.gray.gray80};

  &.is-focus {
    border-color: ${({ theme }) => theme.colors.brand.primary.default};
  }

  &.is-error {
    border-color: ${({ theme }) => theme.colors.state.error.default};
  }

  &.is-disabled {
    border: 1px solid ${({ theme }) => theme.colors.text.disabled.black};
    background: ${({ theme }) => theme.colors.gray.gray95};
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    transition: white 5000s ease-in-out 0s;
    -webkit-transition: background-color 9999s ease-out;
    -webkit-box-shadow: 0 0 0px 1000px background-color inset !important;
    -webkit-text-fill-color: color !important;
  }
`;

const StyledTextField = styled.input`
  outline: none;
  border: 0;
  width: 100%;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
  letter-spacing: -0.4px;
  padding: 0;
  margin: 0;

  ::placeholder {
    color: ${({ theme }) => theme.colors.text.disabled.black};
  }

  &.is-start-adornment {
  }

  &.is-end-adornment {
    display: flex;
  }
`;

const AdornmentContainer = styled.div`
  position: relative;

  display: flex;
  justify-content: center;
  align-items: center;

  &.is-start-adornment {
    border-radius: 4px 0 0 4px;
  }

  &.is-end-adornment {
    border-radius: 0 4px 4px 0;
  }
`;

const Message = styled.div`
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 100%; /* 12px */
  letter-spacing: -0.4px;

  color: ${({ theme }) => theme.colors.icon.inactive.black};

  &.is-error {
    color: ${({ theme }) => theme.colors.state.error.default};
  }
`;

/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * KAC-API
 * K-Artist Class API
 * OpenAPI spec version: 0.0.1
 */

/**
 * 결제 수단
 * @nullable
 */
export type PaymentGatewayLogsForPurchasePaymentMethod =
  | (typeof PaymentGatewayLogsForPurchasePaymentMethod)[keyof typeof PaymentGatewayLogsForPurchasePaymentMethod]
  | null;

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PaymentGatewayLogsForPurchasePaymentMethod = {
  CARD: "CARD",
  VIRTUAL_ACCOUNT: "VIRTUAL_ACCOUNT",
  TRANSFER: "TRANSFER",
  MOBILE_PHONE: "MOBILE_PHONE",
} as const;

import { useState } from "react";
import { SubmitErrorHandler, SubmitHandler, useForm } from "react-hook-form";
import * as yup from "yup";
import styled from "@emotion/styled";
import { yupResolver } from "@hookform/resolvers/yup";
import { useQueryClient } from "@tanstack/react-query";

import { Button } from "@shared/lib/components/common/atom/button";
import { Icon } from "@shared/lib/components/common/atom/icon";
import { TextField } from "@shared/lib/components/common/atom/text-feild";
import { ICON_SVG } from "@shared/lib/constants/import/icon-svg";
import { BUTTON_TYPE } from "@shared/lib/styles/theme";
import { openToast, TOAST_TYPE } from "@shared/lib/utils/toast";
import { useAccessToken } from "@/hooks/auth/use-access-token";
import {
  getUserControllerGetProfileQueryKey,
  useUserControllerVerifyPassword,
} from "@shared/generated/api/fn/kac/user/user";

interface EditPasswordForm {
  password: string;
  updatePassword: string;
  updateMatchPassword: string;
}

interface EditPasswordProps {
  onEditCancel: () => void;
}

export const EditPassword = ({ onEditCancel }: EditPasswordProps) => {
  const queryClient = useQueryClient();

  const [isShowPassword, setIsShowPassword] = useState(false);
  const [isShowUpdatePassword, setIsShowUpdatePassword] = useState(false);
  const [isShowUpdateMatchPassword, setIsShowUpdateMatchPassword] =
    useState(false);

  const onShowPassword = () => {
    setIsShowPassword(!isShowPassword);
  };

  const onShowUpdatePassword = () => {
    setIsShowUpdatePassword(!isShowUpdatePassword);
  };

  const onShowUpdateMatchPassword = () => {
    setIsShowUpdateMatchPassword(!isShowUpdateMatchPassword);
  };

  const { accessToken } = useAccessToken();
  const updatePasswordMutation = useUserControllerVerifyPassword({
    request: {
      accessToken,
    },
    mutation: {
      onSuccess: async () => {
        openToast(TOAST_TYPE.SUCCESS, "비밀번호가 수정되었습니다.");
        await queryClient.invalidateQueries({
          queryKey: getUserControllerGetProfileQueryKey(),
        });
        onEditCancel();
      },
      onError: (error) => {
        const { errorCode, message } = error?.response?.data as any;

        if (errorCode === 100019) {
          setError("password", {
            type: "ERROR",
            message: "비밀번호가 일치하지 않습니다.",
          });
        } else if (errorCode === 100033) {
          setError("updatePassword", {
            type: "ERROR",
            message: "새 비밀번호가 기존 비밀번호와 동일합니다.",
          });
        } else {
          openToast(TOAST_TYPE.ERROR, "비밀번호 수정에 실패하였습니다.");
        }
      },
    },
  });

  const schema = yup.object({
    password: yup
      .string()
      .min(8, "비밀번호는 8~20자 이내로 입력해야 합니다.")
      .max(20, "비밀번호는 8~20자 이내로 입력해야 합니다.")
      .matches(
        /[a-zA-Z0-9d~!@#$%^&*()_+=]$/,
        "허용되지 않는 특수문자가 포함되어 있습니다.",
      )
      .matches(/^(?!.* )/, "빈 칸은 포함될 수 없습니다.")
      .matches(
        /^(?!((?:[A-Za-z]+)|(?:[~!@#$%^&*()_+=]+)|(?:[0-9]+))$)[A-Za-z\d~!@#$%^&*()_+=]/,
        "반드시 영문/숫자/특수문자 중 2개 이상의 조합을 사용해야 합니다.",
      )
      .trim("공백은 들어갈 수 없습니다.")
      .required("비밀번호를 입력해주세요."),
    updatePassword: yup
      .string()
      .min(8, "비밀번호는 8~20자 이내로 입력해야 합니다.")
      .max(20, "비밀번호는 8~20자 이내로 입력해야 합니다.")
      .matches(
        /[a-zA-Z0-9d~!@#$%^&*()_+=]$/,
        "허용되지 않는 특수문자가 포함되어 있습니다.",
      )
      .matches(/^(?!.* )/, "빈 칸은 포함될 수 없습니다.")
      .matches(
        /^(?!((?:[A-Za-z]+)|(?:[~!@#$%^&*()_+=]+)|(?:[0-9]+))$)[A-Za-z\d~!@#$%^&*()_+=]/,
        "반드시 영문/숫자/특수문자 중 2개 이상의 조합을 사용해야 합니다.",
      )
      .trim("공백은 들어갈 수 없습니다.")
      .required("비밀번호를 입력해주세요."),
    updateMatchPassword: yup
      .string()
      .min(8, "비밀번호는 8~20자 이내로 입력해야 합니다.")
      .max(20, "비밀번호는 8~20자 이내로 입력해야 합니다.")
      .matches(
        /[a-zA-Z0-9d~!@#$%^&*()_+=]$/,
        "허용되지 않는 특수문자가 포함되어 있습니다.",
      )
      .matches(/^(?!.* )/, "빈 칸은 포함될 수 없습니다.")
      .matches(
        /^(?!((?:[A-Za-z]+)|(?:[~!@#$%^&*()_+=]+)|(?:[0-9]+))$)[A-Za-z\d~!@#$%^&*()_+=]/,
        "반드시 영문/숫자/특수문자 중 2개 이상의 조합을 사용해야 합니다.",
      )
      .oneOf([yup.ref("updatePassword")], "비밀번호가 일치하지 않습니다.")
      .required("비밀번호를 입력해 주세요."),
  });

  const {
    handleSubmit,
    watch,
    setValue,
    getValues,
    setError,
    register,
    trigger,
    clearErrors,
    formState: { errors, isValid },
  } = useForm<EditPasswordForm>({
    resolver: yupResolver<EditPasswordForm>(schema),
    mode: "onChange",
  });

  const onSubmit: SubmitHandler<EditPasswordForm> = async (data) => {
    updatePasswordMutation.mutate({
      data: {
        password: data.password,
        newPassword: data.updatePassword,
      },
    });
  };

  const onError: SubmitErrorHandler<EditPasswordForm> = async (error) => {
    console.error(error);
  };

  return (
    <EditPasswordContainer>
      <form onSubmit={handleSubmit(onSubmit, onError)}>
        <EditPasswordFormContainer>
          <EditPasswordTextField
            type={isShowPassword ? "text" : "password"}
            label={"기존 비밀번호"}
            placeholder={"현재 비밀번호를 입력해주세요"}
            {...register("password")}
            maxLength={50}
            isError={!!errors.password}
            message={errors?.password?.message}
            endAdornment={
              <a onClick={onShowPassword}>
                <Icon
                  icon={
                    isShowPassword
                      ? ICON_SVG.LINE.SIZE_18.ICON_EYE
                      : ICON_SVG.LINE.SIZE_18.ICON_EYE_OFF
                  }
                />
              </a>
            }
          />

          <EditPasswordTextField
            type={isShowUpdatePassword ? "text" : "password"}
            label={"새 비밀번호"}
            placeholder={"특수문자, 숫자, 영문자 조합된 8 이상 문자"}
            {...register("updatePassword")}
            maxLength={50}
            isError={!!errors.updatePassword}
            message={errors?.updatePassword?.message}
            endAdornment={
              <a onClick={onShowUpdatePassword}>
                <Icon
                  icon={
                    isShowUpdatePassword
                      ? ICON_SVG.LINE.SIZE_18.ICON_EYE
                      : ICON_SVG.LINE.SIZE_18.ICON_EYE_OFF
                  }
                />
              </a>
            }
          />

          <EditPasswordTextField
            type={isShowUpdateMatchPassword ? "text" : "password"}
            label={"새 비밀번호 확인"}
            placeholder={"동일한 비밀번호를 다시 입력해 주세요."}
            {...register("updateMatchPassword")}
            maxLength={50}
            isError={!!errors.updateMatchPassword}
            message={errors?.updateMatchPassword?.message}
            endAdornment={
              <a onClick={onShowUpdateMatchPassword}>
                <Icon
                  icon={
                    isShowUpdateMatchPassword
                      ? ICON_SVG.LINE.SIZE_18.ICON_EYE
                      : ICON_SVG.LINE.SIZE_18.ICON_EYE_OFF
                  }
                />
              </a>
            }
          />
        </EditPasswordFormContainer>

        <EditButtonContainer>
          <CancelButton
            type={"button"}
            styleType={BUTTON_TYPE.OUTLINE}
            onClick={onEditCancel}
          >
            취소
          </CancelButton>
          <EditButton
            type={"submit"}
            styleType={BUTTON_TYPE.FILL}
            disabled={!isValid || updatePasswordMutation.isPending}
          >
            수정 완료
          </EditButton>
        </EditButtonContainer>
      </form>
    </EditPasswordContainer>
  );
};

const EditPasswordContainer = styled.div`
  form {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 80px;
  }

  ${({ theme }) => theme.media.tabletSm} {
    form {
      gap: 40px;
    }
  }
`;

const EditPasswordFormContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;

  width: 100%;
`;

const EditPasswordTextField = styled(TextField)`
  gap: 4px;
  flex: 1 0 0;
`;

const EditButtonContainer = styled.div`
  display: flex;
  align-self: flex-end;
  align-items: center;
  gap: 8px;

  ${({ theme }) => theme.media.tabletSm} {
    width: 100%;
  }
`;

const CancelButton = styled(Button)`
  &.btn-${BUTTON_TYPE.OUTLINE.toLowerCase()} {
    border-radius: 4px;
  }

  ${({ theme }) => theme.media.tabletSm} {
    width: 100%;
  }
`;

const EditButton = styled(Button)`
  &.btn-${BUTTON_TYPE.FILL.toLowerCase()} {
    border-radius: 4px;
  }

  ${({ theme }) => theme.media.tabletSm} {
    width: 100%;
  }
`;

import React from "react";
import { Box, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import {
  CourseProcessStatusEnum,
  CourseResponseDto,
  CourseTypeEnum,
} from "@shared/generated/api/model";
import InfoText from "@/components/template/my/class/InfoText";
import VerifiedIcon from "@mui/icons-material/Verified";

export const ClassCard: React.FC<{ course: CourseResponseDto }> = ({
  course,
}) => {
  const { title, thumbnailImage, type, cycles, instructor, totalSessions } =
    course;
  const refCycle = cycles[0];
  let isCycleOngoing = true;
  let isOpenForEnrollment = false;
  if (!refCycle || refCycle.status === CourseProcessStatusEnum.PLANNED) {
    isCycleOngoing = false;
    if (refCycle?.selling === true && !refCycle.enrollmentClosed) {
      isOpenForEnrollment = true;
    }
  }
  const isCycleFinished =
    refCycle?.status === CourseProcessStatusEnum.COMPLETED;

  return (
    <Box
      sx={{
        borderRadius: "7.72px",
        backgroundColor: "#FFFFFF",
        boxShadow: "0px 0px 9.65px 0px #00000033",
        overflow: "hidden",
        flexShrink: 0,
        flexGrow: 0,
        width: {
          xs: "343px",
          sm: "328px",
          md: "360px",
          lg: "272px",
        },
        height: {
          xs: "342px",
          sm: "352px",
          md: "334px",
          lg: "299px",
        },
      }}
    >
      <Box
        component="img"
        src={thumbnailImage}
        alt={title}
        sx={{
          width: "100%",
          height: {
            xs: "192px",
            sm: "202px",
            md: "184px",
            lg: "152.4px",
          },
          objectFit: "cover",
          opacity: isCycleFinished ? 0.5 : 1,
        }}
      />
      <ContentBox>
        <Badge $courseType={type}>
          {type === CourseTypeEnum.MULTI ? "다회차" : "원데이"}
        </Badge>
        <InfoText
          isCycleOngoing={isCycleOngoing}
          isCycleFinished={isCycleFinished}
          isOpenForEnrollment={isOpenForEnrollment}
          type={type}
          refCycle={refCycle}
          totalSessions={totalSessions ?? 0}
        />
        <Typography
          sx={{
            overflow: "hidden",
            textOverflow: "ellipsis",
            display: "-webkit-box",
            WebkitLineClamp: 2,
            WebkitBoxOrient: "vertical",
            fontSize: "14px",
            fontWeight: 700,
            color: "#333333",
            lineHeight: "1.5",
            minHeight: "42px",
          }}
        >
          {title}
        </Typography>
        <Typography
          sx={{
            mt: 1,
            display: "flex",
            alignItems: "center",
            gap: "4px",
            color: "#00000099",
            fontWeight: 500,
            fontSize: 14,
          }}
        >
          {instructor?.artistName ?? ""}
          <VerifiedIcon
            sx={{
              fontSize: 16,
              color: "#2065EC",
            }}
          />
        </Typography>
      </ContentBox>
    </Box>
  );
};

export default ClassCard;

const ContentBox = styled(Box)({
  padding: "16px",
});

const Badge = styled(Box)<{ $courseType: CourseTypeEnum }>(
  ({ $courseType }) => ({
    display: "inline-flex",
    borderRadius: "4px",
    fontFamily: "Pretendard Variable",
    fontSize: "14px",
    fontWeight: 700,
    lineHeight: "20px",
    letterSpacing: "-0.4px",
    backgroundColor: "#FFFFFF",
    color: $courseType === CourseTypeEnum.MULTI ? "#0FA858" : "#FF5B15",
  }),
);

// import { CycleResponseDto } from "@shared/generated/api/model";
import { create } from "zustand";

interface ScheduleStore {
  selectedScheduleId: number | null;
  setScheduleId: (id: number) => void;
  clearScheduleId: () => void;
}

export const useScheduleStore = create<ScheduleStore>((set) => ({
  selectedScheduleId: null,
  setScheduleId: (id) =>
    set(() => ({
      selectedScheduleId: id,
    })),
  clearScheduleId: () =>
    set(() => ({
      selectedScheduleId: null,
    })),
}));

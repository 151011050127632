import { useRouter } from "next/router";
import styled from "@emotion/styled";

import { Button } from "@shared/lib/components/common/atom/button";
import { BUTTON_COLOR, BUTTON_TYPE } from "@shared/lib/styles/theme";
import { MENU } from "@/constants/menu";
import { MAX_WIDTH } from "@/constants/style/layout";

export const WithSection = () => {
  const router = useRouter();
  const onApply = () => {
    router.push(MENU.TEACHER_APPLY.link.url);
  };
  return (
    <StyledWithSection>
      <WithSectionInnerContainer>
        <WithSectionLeftContainer>
          <WithSectionTitleContainer>
            {`K-ARTIST CLASS와 함께 하실\n강사 여러분을 모집합니다.`}
          </WithSectionTitleContainer>
          <WithSectionContentContainer>
            {`K-ARTIST CLASS(이하 케아클)는 아티스트들이 본인의 노하우를 바탕으로 온라인 강의를 진행할 수 있는 국내 유일의 Live + VOD 예술 교육 플랫폼입니다.\nK-ARTIST 이신가요? 그렇다면, 지금 강사로 등록하고 여러분의 스킬과 노하우를 기다리고 있는 수강생들과 만나보세요.`}
          </WithSectionContentContainer>
          <WithSectionApplyButtonContainer>
            <WithSectionApplyButton
              styleType={BUTTON_TYPE.FILL}
              color={BUTTON_COLOR.WHITE}
              onClick={onApply}
            >
              강사 등록하기
            </WithSectionApplyButton>
          </WithSectionApplyButtonContainer>
        </WithSectionLeftContainer>
        <WithSectionRightContainer>
          <img
            src={"/images/teacher/support/teacher-support-img.png"}
            alt={"강사 흥보 상단 콘텐츠 이미지"}
          />
        </WithSectionRightContainer>
      </WithSectionInnerContainer>
    </StyledWithSection>
  );
};

const StyledWithSection = styled.section`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 91px 0;
  width: 100%;

  background-color: ${({ theme }) => theme.colors.environment.background.on};

  ${({ theme }) => theme.media.tabletSm} {
    padding: 0 0 40px 0;
  }
`;

const WithSectionInnerContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  padding: 0 16px;
  width: 100%;
  max-width: ${MAX_WIDTH.INNER_CONTAINER.PC}px;

  ${({ theme }) => theme.media.tabletSm} {
    flex-direction: column-reverse;
    gap: 32px;
  }
`;

const WithSectionLeftContainer = styled.div`
  display: flex;
  flex-direction: column;

  ${({ theme }) => theme.media.tablet} {
    flex-shrink: 0;
    width: 483px;
  }

  ${({ theme }) => theme.media.tabletSm} {
    width: 100%;
  }
`;

const WithSectionTitleContainer = styled.div`
  color: #fff;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 125%; /* 40px */
  letter-spacing: -0.128px;

  white-space: pre-line;

  ${({ theme }) => theme.media.tabletSm} {
    color: #fff;

    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 125%; /* 30px */
    letter-spacing: -0.4px;
  }
`;

const WithSectionContentContainer = styled.div`
  margin-top: 16px;

  width: 410px;
  ${({ theme }) => theme.typography.body1.m};
  color: #fff;
  white-space: pre-line;

  ${({ theme }) => theme.media.tabletSm} {
    width: 100%;
  }
`;

const WithSectionApplyButtonContainer = styled.div`
  margin-top: 24px;

  ${({ theme }) => theme.media.tabletSm} {
    width: 100%;
  }
`;

const WithSectionApplyButton = styled(Button)`
  &.btn-${BUTTON_TYPE.FILL.toLowerCase()} {
    border-radius: 8px;
    min-width: 300px;
  }

  ${({ theme }) => theme.media.tabletSm} {
    width: 100%;
  }
`;

const WithSectionRightContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    width: 100%;
  }

  ${({ theme }) => theme.media.tabletSm} {
    max-width: 343px;
  }
`;

import { InquiryContent, InquiryTypeButtons } from "@/components/domain";
import { PageHeader } from "@/components/ui/headers";
import { useAccessToken } from "@/hooks/auth/use-access-token";
import styled from "@emotion/styled";
import {
  getInquiryControllerGetInquiriesForInstructorQueryKey,
  getInquiryControllerGetInquiriesForStudentQueryKey,
  useInquiryControllerDeleteInquiryByUser,
  useInquiryControllerGetInquiryById,
} from "@shared/generated/api/fn/kac/inquiry/inquiry";
import { UserControllerInquiriesTypes } from "@shared/generated/api/model";
import { openToast, TOAST_TYPE } from "@shared/lib/utils/toast";
import { useQueryClient } from "@tanstack/react-query";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";

export const InquiryDetailsTemplate = () => {
  const { accessToken } = useAccessToken();
  const queryClient = useQueryClient();
  const router = useRouter();
  const { id } = router.query;
  const [selected, setSelected] = useState("강의 문의");

  const { data: InquiryDetailsData } = useInquiryControllerGetInquiryById(
    Number(id),
    { request: { accessToken } },
  );

  const handleInquiryType = (type: string) => {};

  const deleteInquiry = useInquiryControllerDeleteInquiryByUser({
    request: { accessToken },
    mutation: {
      onSuccess: async () => {
        openToast(TOAST_TYPE.SUCCESS, "문의 삭제가 완료되었습니다.");
        const queryKeys = [
          getInquiryControllerGetInquiriesForStudentQueryKey(),
          getInquiryControllerGetInquiriesForInstructorQueryKey(),
        ];
        queryKeys.forEach((queryKey) => {
          queryClient.invalidateQueries({ queryKey });
        });

        router.back();
      },
      onError: (error) => {
        openToast(TOAST_TYPE.ERROR, "문의 삭제에 실패하였습니다.");
        console.error(error);
      },
    },
  });

  const handleDelete = () => {
    console.log(id);
    InquiryDetailsData?.status === "ANSWERED"
      ? openToast(TOAST_TYPE.ERROR, "답변 완료된 글은 삭제하실 수 없습니다!")
      : deleteInquiry.mutate({ inquiryId: Number(id) });
  };

  useEffect(
    () =>
      InquiryDetailsData?.type ===
        UserControllerInquiriesTypes.COURSE_GENERAL ||
      InquiryDetailsData?.type === UserControllerInquiriesTypes.COURSE_SCHEDULE
        ? setSelected("강의 문의")
        : setSelected("수업 Q&A"),
    [InquiryDetailsData?.type],
  );

  return (
    <>
      <PageHeader title="강의 게시판" />
      <InquirySection>
        <InquiryTypeButtons
          selected={selected}
          handleInquiryType={handleInquiryType}
          readonly={true}
        />
        {InquiryDetailsData && (
          <InquiryContent
            handleDelete={handleDelete}
            InquiryDetailsData={InquiryDetailsData}
            isQna={true}
          />
        )}
      </InquirySection>
    </>
  );
};

const InquirySection = styled.div`
  width: 100%;
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

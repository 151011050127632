import styled from "@emotion/styled";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Pagination,
} from "@mui/material";
import { InquiryResponseDto } from "@shared/generated/api/model";
import { getInquiryTypePrefix } from "@shared/lib/utils/inquiry";
import moment from "moment/moment";
import { useRouter } from "next/router";
import { ChangeEvent } from "react";
import { useMedia } from "react-use";

interface InquiryTableProps {
  total: number;
  curPage: number;
  handlePageChange: (_event: ChangeEvent<unknown>, newPage: number) => void;
  rows: InquiryResponseDto[];
  headers: string[];
}

export const InquiryTable = ({
  total,
  curPage,
  handlePageChange,
  rows,
  headers,
}: InquiryTableProps) => {
  const router = useRouter();
  const isMobile = useMedia(`(max-width: 590px)`, false);

  return (
    <>
      <StyledContainer>
        {isMobile ? (
          <>
            {rows.length !== 0 ? (
              rows.map((row) => (
                <ResponsiveTable
                  key={row.id}
                  onClick={() => router.push(`/my/inquiry/${row.id}`)}
                >
                  <StyledTableRow className="font-md">
                    <InquiryTypeText className="font-md">
                      {getInquiryTypePrefix(row.type)}
                    </InquiryTypeText>
                    {row.subject}
                  </StyledTableRow>
                  <StyledTableRow className="font-s">
                    <InquiryTypeText className="font-s">강사명</InquiryTypeText>
                    {row.course?.instructor?.artistName}
                  </StyledTableRow>
                  <StyledTableRow className="font-s">
                    <InquiryTypeText className="font-s">작성자</InquiryTypeText>
                    <span className="pr">{row?.user?.name}</span>
                    <InquiryTypeText className="font-s">등록일</InquiryTypeText>
                    <span>{moment(row.createdAt).format("YYYY-MM-DD")}</span>
                  </StyledTableRow>
                  <StyledTableRow>
                    <StatusText
                      status={row.status}
                      className="font-s"
                    >
                      {row.status === "PENDING" ? "답변 대기" : "답변 완료"}
                    </StatusText>
                  </StyledTableRow>
                </ResponsiveTable>
              ))
            ) : (
              <ResponsiveTable>
                <NoInquiry>등록된 게시글이 없습니다.</NoInquiry>
              </ResponsiveTable>
            )}
          </>
        ) : (
          <Table>
            <TableHead>
              <TableRow>
                {headers.map((header, index) => (
                  <StyledTableCell key={index}>{header}</StyledTableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.length !== 0 ? (
                rows.map((row) => (
                  <StyledTableRow
                    key={row.id}
                    onClick={() => router.push(`/my/inquiry/${row.id}`)}
                  >
                    <BodyTableCell style={{ width: "30px" }}>
                      {row.id}
                    </BodyTableCell>
                    <BodyTableCell>
                      <InquiryTypeText>
                        {getInquiryTypePrefix(row.type)}
                      </InquiryTypeText>
                      {row.subject}
                    </BodyTableCell>
                    <BodyTableCell>
                      {row.course?.instructor?.artistName}
                    </BodyTableCell>
                    <BodyTableCell>{row?.user?.name}</BodyTableCell>
                    <BodyTableCell>
                      {moment(row.createdAt).format("YYYY-MM-DD")}
                    </BodyTableCell>
                    <StyledStatusCell status={row.status}>
                      {row.status === "PENDING" ? "답변 대기" : "답변 완료"}
                    </StyledStatusCell>
                  </StyledTableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell
                    colSpan={12}
                    align="center"
                  >
                    <NoInquiry>등록된 게시글이 없습니다.</NoInquiry>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        )}
        <PaginationWrapper>
          <StyledPagination
            size="small"
            count={total}
            page={curPage}
            onChange={handlePageChange}
            color="primary"
          />
        </PaginationWrapper>
      </StyledContainer>
    </>
  );
};

const StyledContainer = styled(TableContainer)`
  border-radius: 8px;
  border: 1px solid #ddd;
  width: 100%;
`;

const ResponsiveTable = styled.div`
  width: 100%;
  border-bottom: 1px solid #ddd;
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 4px;
  cursor: pointer;

  &:hover {
    background: ${({ theme }) => theme.colors.brand.white.hover};
  }

  .font-s {
    font-size: 12px;
  }
  .font-md {
    font-size: 16px;
  }
  .pr {
    padding-right: 4px;
  }
`;

const StyledTableCell = styled(TableCell)`
  ${({ theme }) => theme.typography.body2.m}
  color: ${({ theme }) => theme.colors.brand.secondary.default};
  padding: 16px 26px;
  white-space: nowrap;
  @media (max-width: 950px) {
    padding: 12px;
  }
`;

const StyledTableRow = styled(TableRow)`
  ${({ theme }) => theme.typography.body2.m}
  cursor: pointer;
  &:hover {
    background: ${({ theme }) => theme.colors.brand.white.hover};
  }
`;

const BodyTableCell = styled(TableCell)`
  ${({ theme }) => theme.typography.body2.m}
  color: ${({ theme }) => theme.colors.brand.primary.default};
  padding: 16px 26px;
  white-space: nowrap;
  @media (max-width: 950px) {
    padding: 12px;
  }
`;

const InquiryTypeText = styled.span`
  ${({ theme }) => theme.typography.body2.m}
  color: ${({ theme }) => theme.colors.text.medium.black};
  padding-right: 4px;
`;

const StyledStatusCell = styled(TableCell)<{ status: string }>`
  white-space: nowrap;
  ${({ theme }) => theme.typography.body2.m}
  color: ${({ theme, status }) =>
    status === "PENDING"
      ? theme.colors.sub.blue.default
      : theme.colors.sub.green.default};
  padding: 16px 26px;
  @media (max-width: 950px) {
    padding: 12px;
  }
`;

const StatusText = styled.div<{ status: string }>`
  white-space: nowrap;
  ${({ theme }) => theme.typography.body2.m}
  color: ${({ theme, status }) =>
    status === "PENDING"
      ? theme.colors.sub.blue.default
      : theme.colors.sub.green.default};
`;

const PaginationWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 0;
`;

const StyledPagination = styled(Pagination)`
  display: flex;
  justify-content: center;
`;

const NoInquiry = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 16px;
  ${({ theme }) => theme.typography.body2.m}
  color: ${({ theme }) => theme.colors.text.medium.black}
`;

import styled from "@emotion/styled";
import { ContentCard } from "@/components/common";
import LeftArrowIcon from "@shared/lib/assets/images/icons/line/18/line-arrow-left.svg";
import { useRouter } from "next/router";
import { InquiryResponseDto } from "@shared/generated/api/model";
import moment from "moment/moment";
import { useMediaQueryMaxWidth } from "@shared/lib/hooks/use-media-query-max-width";

interface InquiryContentProps {
  handleDelete: () => void;
  InquiryDetailsData: InquiryResponseDto;
  isQna?: boolean | false;
}

export const InquiryContent = ({
  handleDelete,
  InquiryDetailsData,
  isQna,
}: InquiryContentProps) => {
  const { isMobile } = useMediaQueryMaxWidth();
  const router = useRouter();
  const handleBack = () => {
    router.back();
  };

  const {
    course,
    user,
    createdAt,
    subject,
    instructor,
    plainTextContent,
    inquiries,
    id,
    courseScheduleId,
  } = InquiryDetailsData || {};

  const handleLink = () => {
    isQna
      ? router.push({
          pathname: `/my/schedule/${courseScheduleId}`,
          query: { inquiryId: id },
        })
      : router.push(`/`); // TODO: 강의 카테고리 정해지면 이동 가능
  };

  return (
    <InquiryContentContainer>
      <ContentCard
        border={"1px solid #d2d2d2"}
        backgroundColor="#fff"
      >
        <InquiryContainer>
          <InquiryInfoSection>
            <InquiryTitle>{subject} </InquiryTitle>
            <UserInfo>
              <InfoText>{user?.name}</InfoText>
              <InfoText className="split">|</InfoText>
              <InfoText>{moment(createdAt).format("YYYY-MM-DD")}</InfoText>
            </UserInfo>
            {isMobile ? (
              <CourseInfo>
                {instructor && (
                  <>
                    <InfoText
                      className="class-info link"
                      onClick={handleLink}
                    >
                      {course?.title}
                    </InfoText>
                    <InfoText className="class-info">
                      {instructor?.artistName}
                    </InfoText>
                  </>
                )}
              </CourseInfo>
            ) : (
              <UserInfo>
                <InfoText
                  className="class-info link"
                  onClick={handleLink}
                >
                  {course?.title}
                </InfoText>
                {instructor && (
                  <>
                    <InfoText className="split">|</InfoText>
                    <InfoText className="class-info">
                      {instructor?.artistName}
                    </InfoText>
                  </>
                )}
              </UserInfo>
            )}
          </InquiryInfoSection>
          <SplitLine />
          <InquiryDetails style={{ whiteSpace: "pre-line" }}>
            {plainTextContent}
          </InquiryDetails>
        </InquiryContainer>
      </ContentCard>
      <InquiryContainer>
        <InquiryTitle>답변</InquiryTitle>
        {!!inquiries && inquiries.length === 0 ? (
          <>
            <SplitLine />
            <NoAnswer>강사님이 확인 중입니다.</NoAnswer>
          </>
        ) : (
          <ContentCard
            border={"1px solid #d2d2d2"}
            backgroundColor="#fff"
          >
            <AnswerContent>
              <InfoText bold={true}>
                {inquiries && inquiries[0]?.instructor?.artistName}
              </InfoText>
              <InfoText className="class-info">
                {inquiries &&
                  moment(inquiries[0]?.updatedAt).format("YYYY-MM-DD hh:mm")}
              </InfoText>
              <InquiryDetails
                isAnswer={true}
                style={{ whiteSpace: "pre-line" }}
              >
                {inquiries && inquiries[0]?.plainTextContent}
              </InquiryDetails>
            </AnswerContent>
          </ContentCard>
        )}
      </InquiryContainer>
      <ButtonWrapper>
        <BackButton onClick={handleBack}>
          <IconWrapper>
            <LeftArrowIcon />
          </IconWrapper>
          목록
        </BackButton>
        <DeleteButton onClick={handleDelete}>삭제</DeleteButton>
      </ButtonWrapper>
    </InquiryContentContainer>
  );
};

const InquiryContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
  .split {
    color: ${({ theme }) => theme.colors.text.disabled.black};
  }
  .class-info {
    color: ${({ theme }) => theme.colors.text.medium.black};
  }
  .link {
    text-decoration: underline;
    cursor: pointer;
  }
`;

const InquiryContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const InquiryInfoSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const InquiryTitle = styled.div`
  ${({ theme }) => theme.typography.h2.b}
  span {
    color: ${({ theme }) => theme.colors.text.medium.black};
    margin-right: 8px;
  }
`;

const UserInfo = styled.div`
  display: flex;
  gap: 8px;
`;

const CourseInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

const InfoText = styled.div<{ bold?: boolean | false }>`
  ${({ theme }) => theme.typography.body2.m}
  font-weight: ${({ bold }) => (bold ? 700 : 500)}
`;

const SplitLine = styled.div`
  width: 100%;
  border-bottom: 1px solid ${({ theme }) => theme.colors.gray.gray80};
`;

const InquiryDetails = styled.div<{ isAnswer?: boolean | false }>`
  ${({ theme }) => theme.typography.body1.m};
  height: ${({ isAnswer }) => (isAnswer ? "100%" : "300px")};
  padding-top: ${({ isAnswer }) => (isAnswer ? "10px" : 0)};
`;

const AnswerContent = styled.div`
  display: flex;
  flex-direction: column;
`;

const NoAnswer = styled.div`
  display: flex;
  justify-content: center;
  ${({ theme }) => theme.typography.body1.m};
  color: ${({ theme }) => theme.colors.text.medium.black};
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

const BackButton = styled.button`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
  padding: 8px 16px;
  border-radius: 8px;
  height: 36px;
  white-space: nowrap;
  ${({ theme }) => theme.typography.body2.b};
  border: 1px solid ${({ theme }) => theme.colors.gray.gray80};
  color: ${({ theme }) => theme.colors.text.high.black};
  &:hover {
    background: ${({ theme }) => theme.colors.gray.gray80};
  }
`;

const IconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const DeleteButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  padding: 8px 16px;
  border-radius: 8px;
  height: 36px;
  ${({ theme }) => theme.typography.body2.b};
  border: 1px solid ${({ theme }) => theme.colors.state.error.default};
  color: ${({ theme }) => theme.colors.state.error.default};
  &:hover {
    background: ${({ theme }) => theme.colors.state.error.default};
    color: ${({ theme }) => theme.colors.brand.white.default};
  }
`;

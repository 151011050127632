import styled from "@emotion/styled";
import { Button } from "@shared/lib/components/common/atom/button";
import { BUTTON_TYPE } from "@shared/lib/styles/theme";
import { ChangeEvent, useEffect, useState } from "react";
import {
  getReviewControllerFindReviewsQueryKey,
  useReviewControllerCreateReview,
  useReviewControllerDeleteReview,
  useReviewControllerFindReviews,
  useReviewControllerLikeReview,
  useReviewControllerUnlikeReview,
} from "@shared/generated/api/fn/kac/review/review";
import { useRouter } from "next/router";
import { useAccessToken } from "@/hooks/auth/use-access-token";
import { useQueryClient } from "@tanstack/react-query";
import { openToast, TOAST_TYPE } from "@shared/lib/utils/toast";

export const ReviewSection = () => {
  const [review, setReview] = useState("");
  const [isEmpty, setIsEmpty] = useState(true);
  const [orderBy, setOrderBy] = useState<string>("desc");
  const [filter, setFilter] = useState("");

  const router = useRouter();
  const { id } = router.query;
  const { accessToken } = useAccessToken();
  const queryClient = useQueryClient();

  const handleChangeReview = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setReview(e.target.value);
  };

  const handleOrderByChange = (option: string) => {
    setFilter(option);
    setOrderBy("desc");
  };

  const getQueryParams = () => {
    const params: any = {};
    if (id) params.courseId = id;
    if (filter && orderBy) params.orderBy = { [filter]: orderBy };
    return Object.keys(params).length > 0 ? params : undefined;
  };

  const request = accessToken ? { accessToken } : undefined;

  const { data: reviewData } = useReviewControllerFindReviews(
    getQueryParams(),
    { request },
  );

  const postReviewMutation = useReviewControllerCreateReview({
    request: { accessToken },
    mutation: {
      onSuccess: async () => {
        openToast(TOAST_TYPE.SUCCESS, "리뷰 작성 완료");
        queryClient.invalidateQueries({
          queryKey: getReviewControllerFindReviewsQueryKey(),
        });
        setReview("");
        setFilter("");
      },
      onError: (error) => {
        openToast(TOAST_TYPE.ERROR, "리뷰 작성 실패");
        console.error(error);
      },
    },
  });

  const handleSubmitReview = () => {
    const data = { courseId: Number(id), content: review };
    if (accessToken && id && review && review.length !== 0)
      postReviewMutation.mutate({ data });
  };

  const postReviewLike = useReviewControllerLikeReview({
    request: { accessToken },
    mutation: {
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: getReviewControllerFindReviewsQueryKey(),
        });
      },
      onError: (error) => {
        console.error(error);
      },
    },
  });

  const deleteReviewLike = useReviewControllerUnlikeReview({
    request: { accessToken },
    mutation: {
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: getReviewControllerFindReviewsQueryKey(),
        });
      },
      onError: (error) => {
        console.error(error);
      },
    },
  });

  const deleteReview = useReviewControllerDeleteReview({
    request: { accessToken },
    mutation: {
      onSuccess: () => {
        openToast(TOAST_TYPE.SUCCESS, "리뷰 삭제 완료");
        queryClient.invalidateQueries({
          queryKey: getReviewControllerFindReviewsQueryKey(),
        });
      },
      onError: (error) => {
        openToast(TOAST_TYPE.ERROR, "리뷰 삭제 실패");
        console.error(error);
      },
    },
  });

  const handleLikeReview = (id: number, isLiked: boolean) => {
    if (accessToken) {
      isLiked
        ? deleteReviewLike.mutate({ reviewId: id })
        : postReviewLike.mutate({ reviewId: id });
    } else {
      openToast(TOAST_TYPE.INFO, "로그인이 필요합니다.");
    }
  };

  const handleDeleteReview = (id: number) => {
    if (accessToken) deleteReview.mutate({ reviewId: id });
  };

  useEffect(
    () => (review.length === 0 ? setIsEmpty(true) : setIsEmpty(false)),
    [review],
  );

  return (
    <ReviewSectionContainer>
      <TitleWrapper>
        <ReviewTitle>
          리뷰
          <TotalReviewText>{reviewData ? reviewData.count : 0}</TotalReviewText>
        </ReviewTitle>
        <FilterWrapper>
          <FilterButton
            isActive={filter === "likesCount"}
            onClick={() => handleOrderByChange("likesCount")}
          >
            공감순
          </FilterButton>
          <FilterButton
            isActive={filter === "createdAt"}
            onClick={() => handleOrderByChange("createdAt")}
          >
            최신순
          </FilterButton>
        </FilterWrapper>
      </TitleWrapper>
      <TextareaWrapper>
        <StyledTextarea
          placeholder={
            accessToken
              ? "리뷰를 남겨주세요."
              : "리뷰를 작성하려면 로그인해주세요."
          }
          value={review}
          onChange={handleChangeReview}
          maxLength={1000}
          disabled={!accessToken}
        />
        <WriteButton
          type={"submit"}
          styleType={BUTTON_TYPE.FILL}
          disabled={isEmpty}
          onClick={handleSubmitReview}
        >
          리뷰 쓰기
        </WriteButton>
      </TextareaWrapper>
      <ReviewList>
        {reviewData &&
          reviewData.rows.map((review) => (
            <ReviewCard key={review.id}>
              <UserWrapper>
                <div className="user-info">
                  <div className="thumbnail-wrapper">
                    <img
                      src={
                        review.user.thumbnailImage
                          ? review.user.thumbnailImage
                          : "/images/avatar86.svg"
                      }
                      alt="user thumbnail"
                    />
                  </div>
                  <div>
                    <div className="nickname">{review.user.name}</div>
                    <div className="date">{review.createdAt}</div>
                  </div>
                </div>
                <p>{review.content}</p>
                <div className="interaction-wrapper">
                  <img
                    src={
                      review.isLiked
                        ? "/images/icons/filled-thumbs-up.svg"
                        : "/images/icons/line-thumbs-up.svg"
                    }
                    onClick={() =>
                      handleLikeReview(review.id, review.isLiked || false)
                    }
                  />
                  <span>공감 {review.likesCount}</span>
                  {review.canDelete && (
                    <span
                      className="delete"
                      onClick={() => handleDeleteReview(review.id)}
                    >
                      삭제
                    </span>
                  )}
                </div>
              </UserWrapper>
              {review.adminReply && (
                <AdminReplyCard>
                  <div>
                    <div className="nickname">관리자</div>
                    <div className="date">{review.adminReply.createdAt}</div>
                  </div>
                  <p>{review.adminReply.content}</p>
                </AdminReplyCard>
              )}
            </ReviewCard>
          ))}
      </ReviewList>
    </ReviewSectionContainer>
  );
};

const ReviewSectionContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px;

  .nickname {
    ${({ theme }) => theme.typography.body2.b};
    color: ${({ theme }) => theme.colors.text.high.white};
  }
  .date {
    ${({ theme }) => theme.typography.body3.r};
    color: ${({ theme }) => theme.colors.text.medium.white};
  }
  p {
    ${({ theme }) => theme.typography.body2.m};
    color: ${({ theme }) => theme.colors.text.high.white};
    margin: 0;
  }
`;

const TitleWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

const ReviewTitle = styled.div`
  ${({ theme }) => theme.typography.h3.b};
  color: ${({ theme }) => theme.colors.text.high.white};
`;

const TotalReviewText = styled.span`
  ${({ theme }) => theme.typography.h3.b};
  color: ${({ theme }) => theme.colors.sub.blue.default};
  padding-left: 8px;
`;

const FilterWrapper = styled.div`
  display: flex;
`;

const FilterButton = styled.button<{ isActive: boolean }>`
  ${({ theme }) => theme.typography.body2.b};
  color: ${({ theme, isActive }) =>
    isActive ? theme.colors.text.high.white : theme.colors.text.disabled.white};
  padding: 8px;
`;

const TextareaWrapper = styled.div`
  display: flex;
  gap: 8px;
`;

const StyledTextarea = styled.textarea`
  display: flex;
  min-height: 46px;
  height: 46px;
  max-width: 663px;
  flex: 1 0 0;
  padding: 12px 16px;
  align-items: flex-end;
  gap: 8px;
  border-radius: 4px;
  color: ${({ theme }) => theme.colors.brand.white.default};
  border: 1px solid ${({ theme }) => theme.colors.text.disabled.white};
  transition: border-color 0.3s ease;
  background-color: transparent;
  box-sizing: border-box;
  overflow-y: auto;
  word-wrap: break-word;
  white-space: pre-wrap;

  &:focus {
    outline: none;
    border: 1px solid ${({ theme }) => theme.colors.brand.white.default};
  }

  &::placeholder {
    color: ${({ theme }) => theme.colors.text.disabled.white};
  }
`;

const WriteButton = styled(Button)`
  &.btn-${BUTTON_TYPE.FILL.toLowerCase()} {
    ${({ theme }) => theme.typography.body2.b};
    color: ${({ theme }) => theme.colors.text.high.white};
    max-width: 100px;
    height: 46px;
    border-radius: 4px;
    padding: 12px 24px;
    background-color: ${({ theme }) => theme.colors.sub.blue.default};

    &:focus {
      background-color: ${({ theme }) => theme.colors.sub.blue.pressed};
    }
    &:hover {
      background-color: ${({ theme }) => theme.colors.sub.blue.hover};
    }

    &:disabled {
      background-color: ${({ theme }) => theme.colors.brand.white.disabled};
      color: ${({ theme }) => theme.colors.text.disabled.white};
    }
  }
`;

const ReviewList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;
`;

const ReviewCard = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const UserWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;

  .user-info {
    display: flex;
    align-items: center;
    gap: 8px;
  }
  .thumbnail-wrapper {
    width: 34px;
    height: 34px;
    img {
      width: 100%;
    }
  }
  .interaction-wrapper {
    display: flex;
    align-items: center;
    gap: 2px;
    span {
      ${({ theme }) => theme.typography.body2.m};
      color: ${({ theme }) => theme.colors.text.medium.white};
    }
    img {
      cursor: pointer;
    }
  }
  .delete {
    padding-left: 16px;
    cursor: pointer;
  }
`;

const AdminReplyCard = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 auto;
  padding: 16px;
  gap: 8px;
  border-radius: 8px;
  background-color: #242424;
`;

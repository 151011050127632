import { QuillDeltaToHtmlConverter } from "quill-delta-to-html";
import { DeltaStatic } from "react-quill-new";

interface QuillRendererProps {
  ops: DeltaStatic[];
}

export const QuillRenderer = ({ ops }: QuillRendererProps) => {
  const converter = new QuillDeltaToHtmlConverter(ops, {});
  const html = converter.convert();

  return <div dangerouslySetInnerHTML={{ __html: html }} />;
};

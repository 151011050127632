import styled from "@emotion/styled";
import CardIcon from "@shared/lib/assets/images/icons/line/18/line-card.svg";
import AccountIcon from "@shared/lib/assets/images/icons/line/18/line-account.svg";
import CardTextIcon from "@shared/lib/assets/images/icons/line/18/line-card-text.svg";
import PointIcon from "@shared/lib/assets/images/icons/line/18/line-point.svg";
import { ContentCard } from "@/components/common";
import { PurchaseResultDto } from "@shared/generated/api/model";
import {
  convertPurchaseStatus,
  convertPurchaseType,
  PurchaseStatus,
  PurchaseTypes,
} from "./convertPurchaseResult";

const paymentOptions = [
  { icon: <CardIcon />, text: "신용카드", type: "creditCard" },
  { icon: <AccountIcon />, text: "계좌이체", type: "bankTransfer" },
  { icon: <CardTextIcon />, text: "가상계좌", type: "virtualAccount" },
  { icon: <PointIcon />, text: "포인트결제", isPoint: true, type: "point" },
];

interface PurchaseTypeProps {
  selectedPurchaseType?: string | null;
  handlePurchaseType?: (type: string) => void;
  purchaseResult?: PurchaseResultDto;
}

export const PurchaseType = ({
  selectedPurchaseType,
  handlePurchaseType,
  purchaseResult,
}: PurchaseTypeProps) => {
  return (
    <ContentCard>
      <PurchaseTypeContainer>
        <CardTitle>결제 {handlePurchaseType ? "방법" : "수단"}</CardTitle>
        {handlePurchaseType ? (
          <BtnWrapper>
            {paymentOptions.map(
              ({ icon, text, isPoint = false, type }, index) => (
                <StyledButton
                  key={index}
                  type="button"
                  isActive={type === selectedPurchaseType}
                  onClick={() => handlePurchaseType(type)}
                >
                  <IconWrapper
                    isActive={type === selectedPurchaseType}
                    isPoint={isPoint}
                  >
                    {icon}
                    <TypeText isActive={type === selectedPurchaseType}>
                      {text}
                    </TypeText>
                  </IconWrapper>
                </StyledButton>
              ),
            )}
          </BtnWrapper>
        ) : (
          <>
            <InfoWrapper>
              <InfoText>상태</InfoText>
              <InfoText>
                {purchaseResult?.purchaseStatus &&
                  convertPurchaseStatus(
                    purchaseResult?.purchaseStatus as PurchaseStatus,
                  )}
              </InfoText>
            </InfoWrapper>
            <InfoWrapper>
              <InfoText>결제 수단</InfoText>
              <InfoText>
                {purchaseResult?.purchaseType ??
                  convertPurchaseType(
                    purchaseResult?.purchaseType as PurchaseTypes,
                  )}
              </InfoText>
            </InfoWrapper>
          </>
        )}
      </PurchaseTypeContainer>
    </ContentCard>
  );
};

const PurchaseTypeContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const CardTitle = styled.div`
  ${({ theme }) => theme.typography.h3.b};
  color: ${({ theme }) => theme.colors.text.high.white};
  padding-bottom: 16px;
`;

const BtnWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 8px;
  @media (max-width: 600px) {
    grid-template-columns: repeat(2, 1fr);
  }
`;

const StyledButton = styled.button<{ isActive: boolean }>`
  border: 1px solid ${({ theme }) => theme.colors.text.high.white};
  border-radius: 8px;
  padding: 12px 16px;
  width: 100%;
  background: ${({ isActive, theme }) =>
    isActive ? theme.colors.brand.white.default : "transparent"};
`;

const IconWrapper = styled.div<{ isPoint?: boolean; isActive: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  path {
    stroke: ${({ isPoint, isActive, theme }) =>
      !isPoint
        ? isActive
          ? theme.colors.text.high.black
          : theme.colors.brand.white.default
        : null};
    fill: ${({ isPoint, isActive, theme }) =>
      isPoint
        ? isActive
          ? theme.colors.text.high.black
          : theme.colors.brand.white.default
        : null};
  }
  circle {
    stroke: ${({ isPoint, isActive, theme }) =>
      isPoint
        ? isActive
          ? theme.colors.text.high.black
          : theme.colors.brand.white.default
        : null};
  }
`;

const TypeText = styled.div<{ isActive: boolean }>`
  ${({ theme }) => theme.typography.body1.b};
  color: ${({ isActive, theme }) =>
    isActive ? theme.colors.text.high.black : theme.colors.brand.white.default};
`;

const InfoWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const InfoText = styled.div`
  ${({ theme }) => theme.typography.body2.m};
  color: ${({ theme }) => theme.colors.text.high.white};
`;

import styled from "@emotion/styled";

import { MAX_WIDTH } from "@/constants/style/layout";

export const ClassCategorySection = () => (
  <StyledClassCategorySection>
    <ClassCategorySectionInnerContainer>
      <ClassCategorySectionTitleContainer>
        {`케아클에서 어떤 장르로\n강의할 수 있나요?`}
      </ClassCategorySectionTitleContainer>
      <ClassCategoryRow>
        <ClassCategoryItem>
          <ClassCategoryItemIcon>
            <img
              src={"/images/teacher/support/fill-music.svg"}
              alt="작곡 아이콘"
            />
          </ClassCategoryItemIcon>
          <ClassCategoryItemTitleContainer>
            <ClassCategoryItemTitle>작곡</ClassCategoryItemTitle>
            <ClassCategoryItemSubTitle>
              프로듀싱·자작곡
            </ClassCategoryItemSubTitle>
          </ClassCategoryItemTitleContainer>
        </ClassCategoryItem>

        <ClassCategoryItem>
          <ClassCategoryItemIcon>
            <img
              src={"/images/teacher/support/fill-vocal.svg"}
              alt="보컬 아이콘"
            />
          </ClassCategoryItemIcon>
          <ClassCategoryItemTitleContainer>
            <ClassCategoryItemTitle>보컬</ClassCategoryItemTitle>
            <ClassCategoryItemSubTitle>취미·입시</ClassCategoryItemSubTitle>
          </ClassCategoryItemTitleContainer>
        </ClassCategoryItem>

        <ClassCategoryItem>
          <ClassCategoryItemIcon>
            <img
              src={"/images/teacher/support/fill-piano.svg"}
              alt="기악 아이콘"
            />
          </ClassCategoryItemIcon>
          <ClassCategoryItemTitleContainer>
            <ClassCategoryItemTitle>기악</ClassCategoryItemTitle>
            <ClassCategoryItemSubTitle>이론·연주</ClassCategoryItemSubTitle>
          </ClassCategoryItemTitleContainer>
        </ClassCategoryItem>

        <ClassCategoryItem>
          <ClassCategoryItemIcon>
            <img
              src={"/images/teacher/support/fill-voiceactor.svg"}
              alt="성우 아이콘"
            />
          </ClassCategoryItemIcon>
          <ClassCategoryItemTitleContainer>
            <ClassCategoryItemTitle>성우</ClassCategoryItemTitle>
            <ClassCategoryItemSubTitle>기초·공채</ClassCategoryItemSubTitle>
          </ClassCategoryItemTitleContainer>
        </ClassCategoryItem>
      </ClassCategoryRow>
    </ClassCategorySectionInnerContainer>
  </StyledClassCategorySection>
);

const StyledClassCategorySection = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 80px 0;
  width: 100%;
`;

const ClassCategorySectionInnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: 56px;

  padding: 0 16px;
  width: 100%;
  max-width: ${MAX_WIDTH.INNER_CONTAINER.PC}px;
`;

const ClassCategorySectionTitleContainer = styled.div`
  text-align: center;
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  line-height: 125%; /* 37.5px */
  letter-spacing: -0.4px;

  ${({ theme }) => theme.media.tabletSm} {
    text-align: center;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 125%; /* 30px */
    letter-spacing: -0.4px;

    white-space: pre-line;
  }
`;

const ClassCategoryRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;

  width: 100%;

  ${({ theme }) => theme.media.tablet} {
    max-width: 590px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 20px;
    row-gap: 20px;
  }

  ${({ theme }) => theme.media.tabletSm} {
    max-width: 100%;
    display: flex;
    flex-direction: column;
  }
`;

const ClassCategoryItem = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;

  border-radius: 8px;
  padding: 24px;
  width: 100%;
  max-width: 285px;

  box-shadow: 4px 4px 24px 0 rgba(0, 0, 0, 0.15);

  ${({ theme }) => theme.media.tabletSm} {
    max-width: 100%;
  }
`;

const ClassCategoryItemIcon = styled.div``;

const ClassCategoryItemTitleContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const ClassCategoryItemTitle = styled.div`
  ${({ theme }) => theme.typography.h2.b};
  color: ${({ theme }) => theme.colors.text.high.black};
`;

const ClassCategoryItemSubTitle = styled.div`
  ${({ theme }) => theme.typography.body1.m};
  color: ${({ theme }) => theme.colors.text.high.black};
`;

import { UserPointLogResponseDtoPointChangeType } from "@shared/generated/api/model";

export const convertPointType = (
  pointType: UserPointLogResponseDtoPointChangeType,
): string => {
  const earnActions: UserPointLogResponseDtoPointChangeType[] = [
    UserPointLogResponseDtoPointChangeType.REWARD,
    UserPointLogResponseDtoPointChangeType.ADMIN_GRANT,
    UserPointLogResponseDtoPointChangeType.SIGNUP_BONUS,
  ];
  return earnActions.includes(pointType) ? "earn" : "deducted";
};

export const getPointDescription = (
  pointType: UserPointLogResponseDtoPointChangeType,
): string => {
  if (pointType === UserPointLogResponseDtoPointChangeType.ADMIN_GRANT) {
    return "admin_grant";
  } else if (
    pointType === UserPointLogResponseDtoPointChangeType.SIGNUP_BONUS
  ) {
    return "signup_bonus";
  } else if (pointType === UserPointLogResponseDtoPointChangeType.PURCHASE) {
    return "use_points_when_paying";
  } else if (pointType === UserPointLogResponseDtoPointChangeType.EXPIRED) {
    return "expired_points";
  }
  return "";
};

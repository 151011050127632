import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";

interface WebSocketMessage {
  code: string;
  value: string;
}

interface TranslationProps {
  meetingId?: string;
}
export const Translation = ({ meetingId }: TranslationProps) => {
  const [ws, setWs] = useState<WebSocket | null>(null);

  const [sttMessage, setSttMessage] = useState<string>();
  const [transMessage, setTransMessage] = useState<string>();
  const [ragMessage, setRagMessage] = useState<string>();

  useEffect(() => {
    const connectWebSocket = () => {
      const ws = new WebSocket(`wss://api.k-artistclass.dev/ws`);
      setWs(ws);

      ws.onopen = () => {
        console.log("WebSocket connected");
        // 서버에 `meetingId` 전송
        ws.send(JSON.stringify({ code: "MEET_CREATED", meetingId: meetingId }));
      };

      ws.onmessage = (event: MessageEvent) => {
        const data = JSON.parse(event.data) as WebSocketMessage;
        console.log(event.data);

        if (data.code === "STT") {
          setSttMessage(data.value);
        }

        if (data.code === "TRANS") {
          setTransMessage(data.value);
        }

        if (data.code === "RAG") {
          setRagMessage(data.value);
        }
      };

      ws.onclose = () => {
        console.log("WebSocket disconnected, attempting to reconnect...");
        setTimeout(connectWebSocket, 300);
      };
    };

    connectWebSocket();

    return () => {
      ws?.close();
    };
  }, []);

  return (
    <TranslationContainer>
      <MessageContainer>
        <p>{sttMessage}</p>
      </MessageContainer>

      <MessageContainer>
        <p>{transMessage}</p>
      </MessageContainer>

      <MessageContainer>
        <p>{ragMessage}</p>
      </MessageContainer>
    </TranslationContainer>
  );
};

const TranslationContainer = styled.div`
  position: fixed;
  left: 50%;
  bottom: 12px;
  transform: translateX(-50%);

  max-width: 700px;
  width: 100%;

  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const MessageContainer = styled.div`
  padding: 12px;
  border-radius: 12px;
  background-color: ${({ theme }) => theme.colors.text.high.white};
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.1);
`;

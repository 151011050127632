import { useRef, useState } from "react";
import styled from "@emotion/styled";
import { IntroductionSection, ReviewSection, ScheduleSelection } from ".";
import { CourseResponseDto } from "@shared/generated/api/model";
import { QuillRenderer } from "@/components";

export const ScrollTab = ({ classData }: { classData: CourseResponseDto }) => {
  const sectionsRef = useRef<(HTMLElement | null)[]>([]);
  const [activeTab, setActiveTab] = useState<number>(0);

  const scrollToSection = (index: number) => {
    setActiveTab(index);
    const section = sectionsRef.current[index];
    if (section) {
      const offsetTop = section.offsetTop - 160;
      window.scrollTo({ top: offsetTop, behavior: "smooth" });
    }
  };

  console.log("classData", classData);

  return (
    <Container>
      <TabHeader>
        <TabButton
          isActive={activeTab === 0}
          onClick={() => scrollToSection(0)}
        >
          스케줄
        </TabButton>
        <TabButton
          isActive={activeTab === 1}
          onClick={() => scrollToSection(1)}
        >
          강의 소개
        </TabButton>
        <TabButton
          isActive={activeTab === 2}
          onClick={() => scrollToSection(2)}
        >
          리뷰
        </TabButton>
      </TabHeader>

      <ContentContainer>
        <Section
          ref={(el) => {
            sectionsRef.current[0] = el;
          }}
        >
          <ScheduleSelection />
        </Section>
        <Section
          ref={(el) => {
            sectionsRef.current[1] = el;
          }}
        >
          <IntroductionSection>
            {classData?.contents?.pcContent && (
              <QuillRenderer
                ops={JSON.parse(
                  JSON.stringify(classData.contents.pcContent.ops),
                )}
              />
            )}
          </IntroductionSection>
        </Section>
        <Section
          ref={(el) => {
            sectionsRef.current[2] = el;
          }}
        >
          <ReviewSection />
        </Section>
      </ContentContainer>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const TabHeader = styled.div`
  display: flex;
  padding: 40px 0 0;
  position: sticky;
  top: 100px;
  background: ${({ theme }) => theme.colors.gray.gray0};
  border-bottom: 1px solid ${({ theme }) => theme.colors.text.disabled.white};
  ${({ theme }) => theme.media.tablet} {
    top: 60px;
  }
`;

const TabButton = styled.button<{ isActive: boolean }>`
  min-width: 100px;
  padding: 0 16px 16px;
  font-size: 16px;
  color: ${({ isActive, theme }) =>
    isActive ? theme.colors.text.high.white : theme.colors.text.medium.white};
  position: relative;
  font-weight: ${({ isActive }) => (isActive ? 700 : 500)};
  line-height: 22px;
  letter-spacing: -0.4px;
  gap: 4px;
  cursor: pointer;

  &::after {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    bottom: -1px;
    height: 2px;
    background-color: ${({ isActive, theme }) =>
      isActive ? theme.colors.icon.active.white : ""};
    transition: background-color 0.3s ease;
  }
`;

const ContentContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 40px;
`;

const Section = styled.section`
  padding: 40px 0;
  box-sizing: border-box;
`;

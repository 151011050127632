import { ContentCard } from "@/components/common";
import styled from "@emotion/styled";
import {
  calculateFinalPrice,
  priceToLocaleString,
} from "@shared/lib/utils/price";
import { useEffect, useState } from "react";
interface PriceProps {
  usedPoints: number;
  price: number;
  discountRate: number;
  onFinalPriceChange?: (finalPrice: number) => void;
  paymentStatus?: boolean | false;
}

export const PriceContent = ({
  usedPoints,
  price,
  discountRate,
  onFinalPriceChange,
  paymentStatus,
}: PriceProps) => {
  const [finalPrice, setFinalPrice] = useState<number>(
    calculateFinalPrice(price, discountRate, usedPoints) || 0,
  );

  useEffect(() => {
    const newFinalPrice = calculateFinalPrice(price, discountRate, usedPoints);
    setFinalPrice(newFinalPrice || 0);

    // 부모 컴포넌트로 값 전달
    if (onFinalPriceChange) {
      onFinalPriceChange(newFinalPrice || 0);
    }
  }, [usedPoints, price, discountRate, onFinalPriceChange]);

  return (
    <ContentCard>
      <PriceContentContainer>
        {!paymentStatus && (
          <CommonWrapper>
            <CardTitle>결제 금액</CardTitle>
          </CommonWrapper>
        )}
        <CommonWrapper>
          <PriceInfoWrapper>
            <PriceText>정상가</PriceText>
            <PriceText>{priceToLocaleString(price)}원</PriceText>
          </PriceInfoWrapper>
          <PriceInfoWrapper isDiscount={true}>
            <PriceText>할인 금액</PriceText>
            <PriceText>
              {price === 0 ? 0 : priceToLocaleString(price * discountRate)}원
            </PriceText>
          </PriceInfoWrapper>
          <PriceInfoWrapper>
            <PriceText>포인트 사용</PriceText>
            <PriceText>
              {usedPoints === 0 ? 0 : priceToLocaleString(usedPoints)}원
            </PriceText>
          </PriceInfoWrapper>
          <DivideLine />
          <PriceInfoWrapper>
            <CardTitle>총 결제 금액</CardTitle>
            <CardTitle>{priceToLocaleString(finalPrice || 0)}원</CardTitle>
          </PriceInfoWrapper>
        </CommonWrapper>
      </PriceContentContainer>
    </ContentCard>
  );
};

const PriceContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

const CommonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

const CardTitle = styled.div`
  ${({ theme }) => theme.typography.h3.b};
  color: ${({ theme }) => theme.colors.text.high.white};
`;

const PriceInfoWrapper = styled.div<{ isDiscount?: boolean }>`
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: 8px;
  ${({ theme }) => theme.typography.body2.m};
  color: ${({ isDiscount, theme }) =>
    isDiscount
      ? theme.colors.state.error.default
      : theme.colors.text.high.white};
`;

const PriceText = styled.div``;

const DivideLine = styled.div`
  position: relative;
  margin: 12px 0;

  &::after {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 1px;
    background-color: ${({ theme }) => theme.colors.gray.gray30};
  }
`;

/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * KAC-API
 * K-Artist Class API
 * OpenAPI spec version: 0.0.1
 */
import { useQuery } from "@tanstack/react-query";
import type {
  QueryFunction,
  QueryKey,
  UseQueryOptions,
  UseQueryResult,
} from "@tanstack/react-query";
import type {
  MainContentsResponseDto,
  SectionContentsResponseDto,
} from "../../../model";
import { axiosInstance } from "../../../config/axios-instance";
import type { ErrorType } from "../../../config/axios-instance";

type AwaitedInput<T> = PromiseLike<T> | T;

type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];

/**
 * <strong>기능 설명:</strong><br>
<dd> 각 섹션( ex: 음악 입문, 음원발매 ...) 별 언어타입별 컨텐츠 조회 </dd><br>
<dd> 각 description 은 줄바꿈에 따라 br 태그 적용해뒀습니다 </dd><br>
<dd>  - 레거시에서 mo 일때는 br을 무시하도록 해뒀던 부분 참고함 </dd><br>
<dd>CategoryPageResponseDto - name, desscription은 해당 섹션 최상단 표기내용 입니다. </dd><br>
<dd>현재 Academy는  sectionId: 3, 7 에서 표기하도록 해둠 ( 임시로 학원 하나만 전달합니다!)</dd><br>
<dd>MAIN, ACADEMY, COURSE는 관리자의 설정에 따라 키값 노출 순서가 달라질 수 있습니다!</dd><br>
<strong> 기본적으로 키값의 순서, contents는 인덱스 순으로 노출해주시면 됩니다! </strong><br>
<strong> 구조의 개선이 필요하다면 언제든 말씀주세요! </strong><br>


 * @summary 강의 섹션 별 컨텐츠 조회
 */
export const contentsControllerGetGlobalSectionContentsById = (
  lang: "ko" | "en",
  sectionId: number,
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal,
) => {
  return axiosInstance<SectionContentsResponseDto>(
    { url: `/contents/${lang}/section/${sectionId}`, method: "GET", signal },
    options,
  );
};

export const getContentsControllerGetGlobalSectionContentsByIdQueryKey = (
  lang: "ko" | "en",
  sectionId: number,
) => {
  return [`/contents/${lang}/section/${sectionId}`] as const;
};

export const getContentsControllerGetGlobalSectionContentsByIdQueryOptions = <
  TData = Awaited<
    ReturnType<typeof contentsControllerGetGlobalSectionContentsById>
  >,
  TError = ErrorType<SectionContentsResponseDto>,
>(
  lang: "ko" | "en",
  sectionId: number,
  options?: {
    query?: UseQueryOptions<
      Awaited<
        ReturnType<typeof contentsControllerGetGlobalSectionContentsById>
      >,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getContentsControllerGetGlobalSectionContentsByIdQueryKey(lang, sectionId);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof contentsControllerGetGlobalSectionContentsById>>
  > = ({ signal }) =>
    contentsControllerGetGlobalSectionContentsById(
      lang,
      sectionId,
      requestOptions,
      signal,
    );

  return {
    queryKey,
    queryFn,
    enabled: !!(lang && sectionId),
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof contentsControllerGetGlobalSectionContentsById>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type ContentsControllerGetGlobalSectionContentsByIdQueryResult =
  NonNullable<
    Awaited<ReturnType<typeof contentsControllerGetGlobalSectionContentsById>>
  >;
export type ContentsControllerGetGlobalSectionContentsByIdQueryError =
  ErrorType<SectionContentsResponseDto>;

/**
 * @summary 강의 섹션 별 컨텐츠 조회
 */
export const useContentsControllerGetGlobalSectionContentsById = <
  TData = Awaited<
    ReturnType<typeof contentsControllerGetGlobalSectionContentsById>
  >,
  TError = ErrorType<SectionContentsResponseDto>,
>(
  lang: "ko" | "en",
  sectionId: number,
  options?: {
    query?: UseQueryOptions<
      Awaited<
        ReturnType<typeof contentsControllerGetGlobalSectionContentsById>
      >,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions =
    getContentsControllerGetGlobalSectionContentsByIdQueryOptions(
      lang,
      sectionId,
      options,
    );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * <strong>기능 설명:</strong><br>
<dd> 설정 언어 기반 메인페이지 컨텐츠 조회 </dd><br>
<dd> 각 description 은 줄바꿈에 따라 br 태그 적용해뒀습니다 </dd><br>
<dd> 어레이 타입은 인덱스 순으로 노출해주시면 됩니다.  </dd><br>
<strong>Banner - router = "academy" 인 경우 academyId 확인</strong><br>
<strong>Banner - router != "academy" 인 경우 courseId 확인</strong><br>


 * @summary main 페이지 contents 조회
 */
export const contentsControllerGetGlobalContents = (
  lang: "ko" | "en",
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal,
) => {
  return axiosInstance<MainContentsResponseDto>(
    { url: `/contents/${lang}/main/contents`, method: "GET", signal },
    options,
  );
};

export const getContentsControllerGetGlobalContentsQueryKey = (
  lang: "ko" | "en",
) => {
  return [`/contents/${lang}/main/contents`] as const;
};

export const getContentsControllerGetGlobalContentsQueryOptions = <
  TData = Awaited<ReturnType<typeof contentsControllerGetGlobalContents>>,
  TError = ErrorType<MainContentsResponseDto>,
>(
  lang: "ko" | "en",
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof contentsControllerGetGlobalContents>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getContentsControllerGetGlobalContentsQueryKey(lang);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof contentsControllerGetGlobalContents>>
  > = ({ signal }) =>
    contentsControllerGetGlobalContents(lang, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!lang,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof contentsControllerGetGlobalContents>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type ContentsControllerGetGlobalContentsQueryResult = NonNullable<
  Awaited<ReturnType<typeof contentsControllerGetGlobalContents>>
>;
export type ContentsControllerGetGlobalContentsQueryError =
  ErrorType<MainContentsResponseDto>;

/**
 * @summary main 페이지 contents 조회
 */
export const useContentsControllerGetGlobalContents = <
  TData = Awaited<ReturnType<typeof contentsControllerGetGlobalContents>>,
  TError = ErrorType<MainContentsResponseDto>,
>(
  lang: "ko" | "en",
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof contentsControllerGetGlobalContents>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getContentsControllerGetGlobalContentsQueryOptions(
    lang,
    options,
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

import styled from "@emotion/styled";

import { useMediaQueryMaxWidth } from "@shared/lib/hooks/use-media-query-max-width";
import { MAX_WIDTH } from "@/constants/style/layout";

export const ClassProgressSection = () => {
  const { isTabletSm } = useMediaQueryMaxWidth();

  return (
    <StyledClassProgressSection>
      <ClassProgressSectionInnerContainer>
        <ClassProgressSectionTitleContainer>
          {`지금 케아클에서 진행 중인\n강의를 확인해보세요.`}
        </ClassProgressSectionTitleContainer>
        <ClassProgressSectionContentContainer>
          <img
            src={`/images/teacher/support/${isTabletSm ? "class-progress-img-m.png" : "class-progress-img.png"}`}
            alt={"진행 중인 강의 이미지"}
          />
        </ClassProgressSectionContentContainer>
      </ClassProgressSectionInnerContainer>
    </StyledClassProgressSection>
  );
};

const StyledClassProgressSection = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 80px 0;
  width: 100%;
`;

const ClassProgressSectionInnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: 56px;

  width: 100%;
  max-width: ${MAX_WIDTH.INNER_CONTAINER.PC}px;
`;

const ClassProgressSectionTitleContainer = styled.div`
  padding: 0 16px;

  text-align: center;
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  line-height: 125%; /* 37.5px */
  letter-spacing: -0.4px;

  color: ${({ theme }) => theme.colors.text.high.black};

  ${({ theme }) => theme.media.tabletSm} {
    text-align: center;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 125%; /* 30px */
    letter-spacing: -0.4px;

    white-space: pre-line;
  }
`;

const ClassProgressSectionContentContainer = styled.div`
  img {
    width: 100%;
  }
`;

import { useEffect, useMemo, useState } from "react";
import { useMedia } from "react-use";

import { MEDIA_QUERY_MAX_WIDTH } from "@shared/lib/styles/theme";

export const useMediaQueryMaxWidth = () => {
  const isDesktop = useMedia(MEDIA_QUERY_MAX_WIDTH.DESKTOP, false);
  const isTablet = useMedia(MEDIA_QUERY_MAX_WIDTH.TABLET, false);
  const isTabletSm = useMedia(MEDIA_QUERY_MAX_WIDTH.TABLET_SM, false);
  const isMobile = useMedia(MEDIA_QUERY_MAX_WIDTH.MOBILE, false);

  const [mounted, setMounted] = useState<boolean>(false);

  useEffect(() => {
    setMounted(true);
    return () => setMounted(false);
  }, []);

  const result = useMemo(
    () => ({
      isDesktop,
      isTablet,
      isTabletSm,
      isMobile,
    }),
    [isDesktop, isTablet, isTabletSm, isMobile, mounted],
  );

  if (mounted) {
    return result;
  } else {
    return {
      isDesktopLg: false,
      isDesktop: false,
      isTablet: false,
      isTabletSm: false,
      isMobile: false,
    };
  }
};

import { ContentCard } from "@/components/common";
import { refundPolicy } from "@/constants/refundPolicy";
import styled from "@emotion/styled";
import { Button } from "@shared/lib/components/common/atom/button";
import { CheckBox } from "@shared/lib/components/common/atom/check-box";
import { BUTTON_TYPE } from "@shared/lib/styles/theme";
import { ChangeEvent } from "react";

interface PurchaseProps {
  handlePurchase: () => void;
  disabled: boolean;
  checked: boolean;
  onCheckedChange: (value: boolean) => void;
}

export const PurchaseForm = ({
  handlePurchase,
  disabled,
  checked,
  onCheckedChange,
}: PurchaseProps) => {
  const handleAgreePolicy = (event: ChangeEvent<HTMLInputElement>) => {
    onCheckedChange(event.target.checked);
  };

  return (
    <ContentCard>
      <PurchaseContainer>
        <CommonWrapper>
          <CardTitle>
            <CheckBox
              checked={checked}
              onChange={handleAgreePolicy}
            />
            약관 동의
          </CardTitle>
          <Description>
            상품의 구매조건(개인정보 제 3자 제공) 및 이용약관, 환불정책을
            확인하였으며, 본인은 결제에 동의합니다.
          </Description>
        </CommonWrapper>
        <CommonWrapper>
          <TermsSection dangerouslySetInnerHTML={{ __html: refundPolicy }} />
        </CommonWrapper>
        <CommonWrapper>
          <NoticeTitle>이용 안내</NoticeTitle>
          <DivideLine />
          <NoticeContent>
            <Subtitle className="title">가상계좌 이용안내</Subtitle>
            <Subtitle>
              1. 가상계좌로 결제하신 경우, 환불 과정에서 관련 법률에 따라
              결제금액의 10%를 공제하고 환불할 수 있습니다.
            </Subtitle>
          </NoticeContent>
          <NoticeContent>
            <Subtitle className="title">현금영수증 이용안내</Subtitle>
            <Subtitle>
              1. 현금영수증은 1원 이상의 현금성거래(가상계좌, 실시간계좌이체,
              에스크로, 예치금)에 대해 발행이 됩니다.
              <br />
              2. 현금영수증 발행 금액에는 배송비는 포함되고, 적립금사용액은
              포함되지 않습니다.
              <br />
              3. 발행신청 기간제한 현금영수증은 현금을 받은 날부터 5일 이내에
              발행을 해야 합니다.
              <br />
              4. 현금영수증 발행 취소의 경우는 시간 제한이 없습니다. (국세청의
              정책에 따라 변경 될 수 있습니다.)
              <br />
              5. 현금영수증이나 세금계산서 중 하나만 발행 가능 합니다.
            </Subtitle>
          </NoticeContent>
          <NoticeContent>
            <Subtitle className="title">
              부가가치세법 변경에 따른 신용카드매출전표 및 세금계산서 변경안내
            </Subtitle>
            <Subtitle>
              1. 변경된 부가가치세법에 의거, 2004.7.1 이후 신용카드로 결제하신
              주문에 대해서는 세금계산서 발행이 불가하며
              <br />
              2. 신용카드매출전표로 부가가치세 신고를 하셔야
              합니다.(부가가치세법 시행령 57조)
              <br />
              3. 상기 부가가치세법 변경내용에 따라 신용카드 이외의 결제건에
              대해서만 세금계산서 발행이 가능함을 양지하여 주시기 바랍니다.
            </Subtitle>
          </NoticeContent>
        </CommonWrapper>
        <BuyButton
          type={"submit"}
          styleType={BUTTON_TYPE.FILL}
          onClick={handlePurchase}
          disabled={disabled}
        >
          결제하기
        </BuyButton>
      </PurchaseContainer>
    </ContentCard>
  );
};

const PurchaseContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

const CommonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

const CardTitle = styled.div`
  display: flex;
  gap: 4px;
  ${({ theme }) => theme.typography.h3.b};
  color: ${({ theme }) => theme.colors.text.high.white};
`;

const Description = styled.div`
  ${({ theme }) => theme.typography.body2.m};
  color: ${({ theme }) => theme.colors.text.medium.white};
  padding-left: 28px;
`;

const TermsSection = styled.div`
  border-radius: 4px;
  border: 1px solid ${({ theme }) => theme.colors.brand.white.disabled};
  background: ${({ theme }) => theme.colors.brand.primary.default};
  width: 100%;
  height: 100px;
  overflow-y: auto;
  ${({ theme }) => theme.typography.body3.m};
  color: ${({ theme }) => theme.colors.text.medium.white};
  padding: 12px 16px;
`;

const NoticeTitle = styled.div`
  ${({ theme }) => theme.typography.body2.b};
  color: ${({ theme }) => theme.colors.text.medium.white};
`;

const DivideLine = styled.div`
  position: relative;

  &::after {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 1px;
    background-color: ${({ theme }) => theme.colors.gray.gray30};
  }
`;

const NoticeContent = styled.div`
  ${({ theme }) => theme.typography.body3.m};
  color: ${({ theme }) => theme.colors.text.medium.white};
  .title {
    font-weight: 700;
  }
`;

const Subtitle = styled.p`
  margin: 0;
`;

const BuyButton = styled(Button)`
  &.btn-${BUTTON_TYPE.FILL.toLowerCase()} {
    ${({ theme }) => theme.typography.body2.b};
    width: 100%;
    border-radius: 8px;
    padding: 12px 16px;
    background-color: ${({ theme }) => theme.colors.sub.blue.default};
    color: ${({ theme }) => theme.colors.text.high.white};

    &:focus {
      background-color: ${({ theme }) => theme.colors.sub.blue.pressed};
    }
    &:hover {
      background-color: ${({ theme }) => theme.colors.sub.blue.hover};
    }

    &:disabled {
      background-color: ${({ theme }) => theme.colors.brand.white.disabled};
      color: ${({ theme }) => theme.colors.text.disabled.white};
    }
  }
`;

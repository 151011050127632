import axios from "axios";
import qs from "qs";

const paramsSerializer = (params: any) => qs.stringify(params);

export const axiosInstance = axios.create({
  baseURL: process.env.NEXT_PUBLIC_BACKEND_HOST,
  paramsSerializer,
});

export const externalAxiosInstance = axios.create({
  paramsSerializer,
});

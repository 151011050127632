import { forwardRef, InputHTMLAttributes, useMemo } from "react";
import classNames from "classnames";
import styled from "@emotion/styled";

import { Icon } from "@shared/lib/components/common/atom/icon";
import { ICON_SVG } from "@shared/lib/constants/import/icon-svg";

interface RadioProps extends InputHTMLAttributes<HTMLInputElement> {
  isError?: boolean;
  label?: string;
}
export const Radio = forwardRef<HTMLInputElement, RadioProps>(
  (props, radioRef) => {
    const {
      children,
      id,
      checked,
      disabled,
      className,
      isError,
      label,
      ...inputProps
    } = props;

    const icon = useMemo(() => {
      //TODO: 에러일경우 처리
      // if (isError) {
      //   return ICON_SVG.GRAPHIC.RADIO.ICON_OUTLINE_ERROR
      // }

      if (checked) {
        return ICON_SVG.GRAPHIC.RADIO.ICON_CHECKED;
      }

      return ICON_SVG.GRAPHIC.RADIO.ICON_DEFAULT;
    }, [checked, isError]);

    return (
      <RadioRowContainer>
        <RadioContainer
          className={classNames(
            {
              error: isError,
              checked: checked,
            },
            className,
          )}
        >
          <HiddenRadio
            {...inputProps}
            id={id}
            ref={radioRef}
            checked={checked}
            disabled={disabled}
            type={"radio"}
          />

          <Icon icon={icon} />
        </RadioContainer>
        {label && <LabelContainer htmlFor={id}>{label}</LabelContainer>}
      </RadioRowContainer>
    );
  },
);

const RadioRowContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const RadioContainer = styled.div`
  cursor: pointer;
  vertical-align: middle;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;

  width: 20px;
  height: 20px;

  svg {
    width: 100%;
    height: 100%;
  }
`;
const HiddenRadio = styled.input`
  cursor: pointer;
  opacity: 0;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 1;
`;

const LabelContainer = styled.label`
  cursor: pointer;
  ${({ theme }) => theme.typography.body1.m};
  line-height: unset;
  color: ${({ theme }) => theme.colors.text.high.black};
`;

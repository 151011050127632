import { UserControllerInquiriesTypes } from "@shared/generated/api/model";

export const getInquiryTypePrefix = (
  type: UserControllerInquiriesTypes,
): string => {
  switch (type) {
    case UserControllerInquiriesTypes.COURSE_GENERAL:
      return "[강의]";
    case UserControllerInquiriesTypes.COURSE_SCHEDULE:
      return "[일정]";
    default:
      return "";
  }
};

import { ChangeEvent, InputHTMLAttributes, ReactNode } from "react";
import styled from "@emotion/styled";

interface FileUploadButton extends InputHTMLAttributes<HTMLInputElement> {
  children?: ReactNode;
}

export const FileUploadButton = (props: FileUploadButton) => {
  const { children, onChange, ...reset } = props;

  const onChangeFile = (e: ChangeEvent<HTMLInputElement>) => {
    onChange && onChange(e);
    e.target.value = "";
  };

  return (
    <FileUploadButtonContainer>
      <FileUploadLabel htmlFor="file">{children}</FileUploadLabel>
      <input
        type={"file"}
        id="file"
        onChange={onChangeFile}
        {...reset}
      />
    </FileUploadButtonContainer>
  );
};

const FileUploadButtonContainer = styled.div`
  input[type="file"] {
    position: absolute;
    width: 0;
    height: 0;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    border: 0;
  }
`;

const FileUploadLabel = styled.label`
  padding: 8px 16px;
  border-radius: 8px;
  background: ${({ theme }) => theme.colors.gray.gray95};

  display: inline-block;
  font-size: inherit;
  line-height: normal;
  vertical-align: middle;
  cursor: pointer;

  &:hover {
    background: #e1e1e1;
  }

  ${({ theme }) => theme.typography.body3.b};
  color: ${({ theme }) => theme.colors.text.high.black};
  text-align: center;
`;

/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * KAC-API
 * K-Artist Class API
 * OpenAPI spec version: 0.0.1
 */

export type AcademyControllerRequestAcademyAffiliationStatus =
  (typeof AcademyControllerRequestAcademyAffiliationStatus)[keyof typeof AcademyControllerRequestAcademyAffiliationStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AcademyControllerRequestAcademyAffiliationStatus = {
  REQUESTED: "REQUESTED",
  CANCEL_REQ: "CANCEL_REQ",
} as const;

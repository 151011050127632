import { ButtonHTMLAttributes, ReactNode } from "react";
import classNames from "classnames";
import styled from "@emotion/styled";

import {
  BUTTON_COLOR,
  BUTTON_POSITION,
  BUTTON_TYPE,
  ButtonColor,
  ButtonPosition,
  ButtonType,
} from "@shared/lib/styles/theme";

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  styleType: ButtonType;
  icon?: ReactNode;
  iconPosition?: ButtonPosition;
  children: ReactNode;
  color?: ButtonColor;
}

export const Button = (props: ButtonProps) => {
  const {
    styleType,
    color,
    icon,
    iconPosition,
    children,
    className,
    ...reset
  } = props;

  return (
    <StyledButton
      className={classNames(
        {
          [`btn-color-${color?.toLowerCase()}`]: color,
          [`btn-icon-position-${iconPosition?.toLowerCase()}`]: !!iconPosition,
          [`btn-${styleType.toLowerCase()}`]: !!styleType,
        },
        className,
      )}
      {...reset}
    >
      {children}
    </StyledButton>
  );
};

const StyledButton = styled.button`
  cursor: pointer;

  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  padding: 10px 24px;

  &.btn-icon-position-${BUTTON_POSITION.LEFT.toLowerCase()} {
    padding: 16px 10px 24px 10px;
  }

  &.btn-icon-position-${BUTTON_POSITION.RIGHT.toLowerCase()} {
    padding: 24px 10px 16px 10px;
  }

  &.btn-${BUTTON_TYPE.FILL.toLowerCase()} {
    ${({ theme }) => theme.typography.body1.b};

    border-radius: 36px;
    background-color: ${({ theme }) => theme.colors.brand.primary.default};
    color: ${({ theme }) => theme.colors.brand.white.default};

    &:hover {
      background-color: ${({ theme }) => theme.colors.brand.primary.hover};
      box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.1);
    }

    &:focus {
      background-color: ${({ theme }) => theme.colors.brand.primary.hover};
    }

    &:disabled {
      color: ${({ theme }) => theme.colors.text.disabled.black};
      background-color: ${({ theme }) => theme.colors.brand.tertiary.disabled};
      &:hover {
        box-shadow: none;
      }
    }

    &.btn-color-${BUTTON_COLOR.WHITE.toLowerCase()} {
      background-color: ${({ theme }) => theme.colors.brand.white.default};
      color: ${({ theme }) => theme.colors.text.high.black};

      &:hover {
        background-color: ${({ theme }) => theme.colors.brand.white.hover};
        box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.1);
      }

      &:focus {
        background-color: ${({ theme }) => theme.colors.brand.white.pressed};
      }

      &:disabled {
        color: ${({ theme }) => theme.colors.text.disabled.white};
        background-color: ${({ theme }) => theme.colors.brand.white.disabled};
      }
    }
  }

  &.btn-${BUTTON_TYPE.OUTLINE.toLowerCase()} {
    ${({ theme }) => theme.typography.body1.b};

    border-radius: 36px;
    border: 1px solid ${({ theme }) => theme.colors.text.high.black};
    background-color: ${({ theme }) => theme.colors.brand.white};

    &:hover {
      border: 1px solid ${({ theme }) => theme.colors.brand.primary.default};
      background-color: ${({ theme }) => theme.colors.brand.white.hover};
    }

    &:focus {
      border: 1px solid ${({ theme }) => theme.colors.brand.primary.default};
      background-color: ${({ theme }) => theme.colors.brand.white.hover};
    }

    &:disabled {
      border: 1px solid ${({ theme }) => theme.colors.brand.secondary.disabled};
      background-color: ${({ theme }) => theme.colors.brand.white.default};
    }
  }

  &.btn-${BUTTON_TYPE.TEXT.toLowerCase()} {
    ${({ theme }) => theme.typography.body1.b};
    color: ${({ theme }) => theme.colors.text.high.black};

    &:hover {
      color: ${({ theme }) => theme.colors.text.high.black};
    }

    &:focus {
      color: ${({ theme }) => theme.colors.text.high.black};
    }

    &:disabled {
      color: ${({ theme }) => theme.colors.text.disabled.black};
    }
  }

  &.btn-${BUTTON_TYPE.TONAL.toLowerCase()} {
    border-radius: 36px;
    background-color: ${({ theme }) => theme.colors.brand.primary.default};
    color: ${({ theme }) => theme.colors.text.high.white};

    &:hover {
      background-color: ${({ theme }) => theme.colors.brand.primary};
      box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.1);
    }

    &:focus {
      background-color: ${({ theme }) => theme.colors.brand.primary};
      box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.1);
    }

    &:disabled {
      background-color: ${({ theme }) => theme.colors.brand.tertiary.disabled};
      box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.1);
    }
  }
`;

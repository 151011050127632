/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * KAC-API
 * K-Artist Class API
 * OpenAPI spec version: 0.0.1
 */
import { useMutation, useQuery } from "@tanstack/react-query";
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult,
} from "@tanstack/react-query";
import type {
  AuthResponseDto,
  CreateResetCodeRequestDto,
  ResetPasswordRequestDto,
  SendVerificationRequestDto,
  SignInRequestDto,
  SignUpRequestDto,
  VerificationQueryDto,
} from "../../../model";
import { axiosInstance } from "../../../config/axios-instance";
import type { ErrorType } from "../../../config/axios-instance";

type AwaitedInput<T> = PromiseLike<T> | T;

type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];

export const authControllerKakaoAuth = (
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal,
) => {
  return axiosInstance<AuthResponseDto>(
    { url: `/auth/kakao`, method: "GET", signal },
    options,
  );
};

export const getAuthControllerKakaoAuthQueryKey = () => {
  return [`/auth/kakao`] as const;
};

export const getAuthControllerKakaoAuthQueryOptions = <
  TData = Awaited<ReturnType<typeof authControllerKakaoAuth>>,
  TError = ErrorType<AuthResponseDto>,
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof authControllerKakaoAuth>>,
    TError,
    TData
  >;
  request?: SecondParameter<typeof axiosInstance>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getAuthControllerKakaoAuthQueryKey();

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof authControllerKakaoAuth>>
  > = ({ signal }) => authControllerKakaoAuth(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof authControllerKakaoAuth>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type AuthControllerKakaoAuthQueryResult = NonNullable<
  Awaited<ReturnType<typeof authControllerKakaoAuth>>
>;
export type AuthControllerKakaoAuthQueryError = ErrorType<AuthResponseDto>;

export const useAuthControllerKakaoAuth = <
  TData = Awaited<ReturnType<typeof authControllerKakaoAuth>>,
  TError = ErrorType<AuthResponseDto>,
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof authControllerKakaoAuth>>,
    TError,
    TData
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getAuthControllerKakaoAuthQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const authControllerNaverSignIn = (
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal,
) => {
  return axiosInstance<AuthResponseDto>(
    { url: `/auth/naver`, method: "GET", signal },
    options,
  );
};

export const getAuthControllerNaverSignInQueryKey = () => {
  return [`/auth/naver`] as const;
};

export const getAuthControllerNaverSignInQueryOptions = <
  TData = Awaited<ReturnType<typeof authControllerNaverSignIn>>,
  TError = ErrorType<AuthResponseDto>,
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof authControllerNaverSignIn>>,
    TError,
    TData
  >;
  request?: SecondParameter<typeof axiosInstance>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getAuthControllerNaverSignInQueryKey();

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof authControllerNaverSignIn>>
  > = ({ signal }) => authControllerNaverSignIn(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof authControllerNaverSignIn>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type AuthControllerNaverSignInQueryResult = NonNullable<
  Awaited<ReturnType<typeof authControllerNaverSignIn>>
>;
export type AuthControllerNaverSignInQueryError = ErrorType<AuthResponseDto>;

export const useAuthControllerNaverSignIn = <
  TData = Awaited<ReturnType<typeof authControllerNaverSignIn>>,
  TError = ErrorType<AuthResponseDto>,
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof authControllerNaverSignIn>>,
    TError,
    TData
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getAuthControllerNaverSignInQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const authControllerGoogleSignIn = (
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal,
) => {
  return axiosInstance<AuthResponseDto>(
    { url: `/auth/google`, method: "GET", signal },
    options,
  );
};

export const getAuthControllerGoogleSignInQueryKey = () => {
  return [`/auth/google`] as const;
};

export const getAuthControllerGoogleSignInQueryOptions = <
  TData = Awaited<ReturnType<typeof authControllerGoogleSignIn>>,
  TError = ErrorType<AuthResponseDto>,
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof authControllerGoogleSignIn>>,
    TError,
    TData
  >;
  request?: SecondParameter<typeof axiosInstance>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getAuthControllerGoogleSignInQueryKey();

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof authControllerGoogleSignIn>>
  > = ({ signal }) => authControllerGoogleSignIn(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof authControllerGoogleSignIn>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type AuthControllerGoogleSignInQueryResult = NonNullable<
  Awaited<ReturnType<typeof authControllerGoogleSignIn>>
>;
export type AuthControllerGoogleSignInQueryError = ErrorType<AuthResponseDto>;

export const useAuthControllerGoogleSignIn = <
  TData = Awaited<ReturnType<typeof authControllerGoogleSignIn>>,
  TError = ErrorType<AuthResponseDto>,
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof authControllerGoogleSignIn>>,
    TError,
    TData
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getAuthControllerGoogleSignInQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary 회원 가입
 */
export const authControllerSignUp = (
  signUpRequestDto: SignUpRequestDto,
  options?: SecondParameter<typeof axiosInstance>,
) => {
  return axiosInstance<AuthResponseDto>(
    {
      url: `/auth/sign-up`,
      method: "POST",
      headers: { "Content-Type": "application/json" },
      data: signUpRequestDto,
    },
    options,
  );
};

export const getAuthControllerSignUpMutationOptions = <
  TError = ErrorType<AuthResponseDto>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof authControllerSignUp>>,
    TError,
    { data: SignUpRequestDto },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof authControllerSignUp>>,
  TError,
  { data: SignUpRequestDto },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof authControllerSignUp>>,
    { data: SignUpRequestDto }
  > = (props) => {
    const { data } = props ?? {};

    return authControllerSignUp(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type AuthControllerSignUpMutationResult = NonNullable<
  Awaited<ReturnType<typeof authControllerSignUp>>
>;
export type AuthControllerSignUpMutationBody = SignUpRequestDto;
export type AuthControllerSignUpMutationError = ErrorType<AuthResponseDto>;

/**
 * @summary 회원 가입
 */
export const useAuthControllerSignUp = <
  TError = ErrorType<AuthResponseDto>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof authControllerSignUp>>,
    TError,
    { data: SignUpRequestDto },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof authControllerSignUp>>,
  TError,
  { data: SignUpRequestDto },
  TContext
> => {
  const mutationOptions = getAuthControllerSignUpMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary 회원 로그인(유저)
 */
export const authControllerSignIn = (
  signInRequestDto: SignInRequestDto,
  options?: SecondParameter<typeof axiosInstance>,
) => {
  return axiosInstance<AuthResponseDto>(
    {
      url: `/auth/sign-in`,
      method: "POST",
      headers: { "Content-Type": "application/json" },
      data: signInRequestDto,
    },
    options,
  );
};

export const getAuthControllerSignInMutationOptions = <
  TError = ErrorType<AuthResponseDto>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof authControllerSignIn>>,
    TError,
    { data: SignInRequestDto },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof authControllerSignIn>>,
  TError,
  { data: SignInRequestDto },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof authControllerSignIn>>,
    { data: SignInRequestDto }
  > = (props) => {
    const { data } = props ?? {};

    return authControllerSignIn(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type AuthControllerSignInMutationResult = NonNullable<
  Awaited<ReturnType<typeof authControllerSignIn>>
>;
export type AuthControllerSignInMutationBody = SignInRequestDto;
export type AuthControllerSignInMutationError = ErrorType<AuthResponseDto>;

/**
 * @summary 회원 로그인(유저)
 */
export const useAuthControllerSignIn = <
  TError = ErrorType<AuthResponseDto>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof authControllerSignIn>>,
    TError,
    { data: SignInRequestDto },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof authControllerSignIn>>,
  TError,
  { data: SignInRequestDto },
  TContext
> => {
  const mutationOptions = getAuthControllerSignInMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary 인증 코드 발송 요청
 */
export const authControllerSendVerification = (
  sendVerificationRequestDto: SendVerificationRequestDto,
  options?: SecondParameter<typeof axiosInstance>,
) => {
  return axiosInstance<void>(
    {
      url: `/auth/send-verification`,
      method: "POST",
      headers: { "Content-Type": "application/json" },
      data: sendVerificationRequestDto,
    },
    options,
  );
};

export const getAuthControllerSendVerificationMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof authControllerSendVerification>>,
    TError,
    { data: SendVerificationRequestDto },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof authControllerSendVerification>>,
  TError,
  { data: SendVerificationRequestDto },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof authControllerSendVerification>>,
    { data: SendVerificationRequestDto }
  > = (props) => {
    const { data } = props ?? {};

    return authControllerSendVerification(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type AuthControllerSendVerificationMutationResult = NonNullable<
  Awaited<ReturnType<typeof authControllerSendVerification>>
>;
export type AuthControllerSendVerificationMutationBody =
  SendVerificationRequestDto;
export type AuthControllerSendVerificationMutationError = ErrorType<unknown>;

/**
 * @summary 인증 코드 발송 요청
 */
export const useAuthControllerSendVerification = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof authControllerSendVerification>>,
    TError,
    { data: SendVerificationRequestDto },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof authControllerSendVerification>>,
  TError,
  { data: SendVerificationRequestDto },
  TContext
> => {
  const mutationOptions =
    getAuthControllerSendVerificationMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary 인증 코드 확인
 */
export const authControllerVerifyCode = (
  verificationQueryDto: VerificationQueryDto,
  options?: SecondParameter<typeof axiosInstance>,
) => {
  return axiosInstance<string>(
    {
      url: `/auth/verify-code`,
      method: "POST",
      headers: { "Content-Type": "application/json" },
      data: verificationQueryDto,
    },
    options,
  );
};

export const getAuthControllerVerifyCodeMutationOptions = <
  TError = ErrorType<string>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof authControllerVerifyCode>>,
    TError,
    { data: VerificationQueryDto },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof authControllerVerifyCode>>,
  TError,
  { data: VerificationQueryDto },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof authControllerVerifyCode>>,
    { data: VerificationQueryDto }
  > = (props) => {
    const { data } = props ?? {};

    return authControllerVerifyCode(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type AuthControllerVerifyCodeMutationResult = NonNullable<
  Awaited<ReturnType<typeof authControllerVerifyCode>>
>;
export type AuthControllerVerifyCodeMutationBody = VerificationQueryDto;
export type AuthControllerVerifyCodeMutationError = ErrorType<string>;

/**
 * @summary 인증 코드 확인
 */
export const useAuthControllerVerifyCode = <
  TError = ErrorType<string>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof authControllerVerifyCode>>,
    TError,
    { data: VerificationQueryDto },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof authControllerVerifyCode>>,
  TError,
  { data: VerificationQueryDto },
  TContext
> => {
  const mutationOptions = getAuthControllerVerifyCodeMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary 비밀번호 재설정 요청
 */
export const authControllerRequestResetPassword = (
  createResetCodeRequestDto: CreateResetCodeRequestDto,
  options?: SecondParameter<typeof axiosInstance>,
) => {
  return axiosInstance<void>(
    {
      url: `/auth/reset-password/send`,
      method: "POST",
      headers: { "Content-Type": "application/json" },
      data: createResetCodeRequestDto,
    },
    options,
  );
};

export const getAuthControllerRequestResetPasswordMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof authControllerRequestResetPassword>>,
    TError,
    { data: CreateResetCodeRequestDto },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof authControllerRequestResetPassword>>,
  TError,
  { data: CreateResetCodeRequestDto },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof authControllerRequestResetPassword>>,
    { data: CreateResetCodeRequestDto }
  > = (props) => {
    const { data } = props ?? {};

    return authControllerRequestResetPassword(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type AuthControllerRequestResetPasswordMutationResult = NonNullable<
  Awaited<ReturnType<typeof authControllerRequestResetPassword>>
>;
export type AuthControllerRequestResetPasswordMutationBody =
  CreateResetCodeRequestDto;
export type AuthControllerRequestResetPasswordMutationError =
  ErrorType<unknown>;

/**
 * @summary 비밀번호 재설정 요청
 */
export const useAuthControllerRequestResetPassword = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof authControllerRequestResetPassword>>,
    TError,
    { data: CreateResetCodeRequestDto },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof authControllerRequestResetPassword>>,
  TError,
  { data: CreateResetCodeRequestDto },
  TContext
> => {
  const mutationOptions =
    getAuthControllerRequestResetPasswordMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary 비밀번호 재설정
 */
export const authControllerModifyPassword = (
  resetPasswordRequestDto: ResetPasswordRequestDto,
  options?: SecondParameter<typeof axiosInstance>,
) => {
  return axiosInstance<void>(
    {
      url: `/auth/reset-password`,
      method: "POST",
      headers: { "Content-Type": "application/json" },
      data: resetPasswordRequestDto,
    },
    options,
  );
};

export const getAuthControllerModifyPasswordMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof authControllerModifyPassword>>,
    TError,
    { data: ResetPasswordRequestDto },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof authControllerModifyPassword>>,
  TError,
  { data: ResetPasswordRequestDto },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof authControllerModifyPassword>>,
    { data: ResetPasswordRequestDto }
  > = (props) => {
    const { data } = props ?? {};

    return authControllerModifyPassword(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type AuthControllerModifyPasswordMutationResult = NonNullable<
  Awaited<ReturnType<typeof authControllerModifyPassword>>
>;
export type AuthControllerModifyPasswordMutationBody = ResetPasswordRequestDto;
export type AuthControllerModifyPasswordMutationError = ErrorType<unknown>;

/**
 * @summary 비밀번호 재설정
 */
export const useAuthControllerModifyPassword = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof authControllerModifyPassword>>,
    TError,
    { data: ResetPasswordRequestDto },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof authControllerModifyPassword>>,
  TError,
  { data: ResetPasswordRequestDto },
  TContext
> => {
  const mutationOptions =
    getAuthControllerModifyPasswordMutationOptions(options);

  return useMutation(mutationOptions);
};

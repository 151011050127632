import styled from "@emotion/styled";
import { Box, Typography } from "@mui/material";
import { CourseTypeEnum } from "@shared/generated/api/model/courseTypeEnum";
import {
  formatClassPeriodWithoutYear,
  formatDate,
} from "@shared/lib/utils/date/formatSchedule";

interface InfoTextProps {
  isCycleOngoing: boolean;
  isCycleFinished: boolean;
  isOpenForEnrollment: boolean;
  type: CourseTypeEnum;
  refCycle: {
    startsAt: string;
    endsAt: string;
  };
  totalSessions: number;
}

const InfoText = ({
  isCycleOngoing,
  isCycleFinished,
  isOpenForEnrollment,
  type,
  refCycle,
  totalSessions,
}: InfoTextProps) => {
  const renderStatus = () => {
    if (isCycleFinished) {
      return (
        <Typography
          color="error"
          fontWeight={500}
        >
          종료
        </Typography>
      );
    }

    if (!isCycleOngoing) {
      return (
        <Typography
          color="error"
          fontWeight={500}
        >
          {isOpenForEnrollment ? "모집 중" : "준비 중"}
        </Typography>
      );
    }

    return null;
  };

  return (
    <InfoTextContainer>
      {isCycleOngoing && refCycle ? (
        <Box
          sx={{
            display: "flex",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            gap: "4px",
          }}
        >
          <Typography color="#00000099">기간</Typography>
          <Typography color="black">
            {type === CourseTypeEnum.MULTI
              ? formatClassPeriodWithoutYear(
                  new Date(refCycle.startsAt),
                  new Date(refCycle.endsAt),
                  totalSessions,
                )
              : formatDate(new Date(refCycle.startsAt), { withTime: true })}
          </Typography>
          {renderStatus()}
        </Box>
      ) : (
        <Typography color="black">{renderStatus()}</Typography>
      )}
    </InfoTextContainer>
  );
};

export default InfoText;

const InfoTextContainer = styled(Box)({
  fontSize: "14px",
  display: "flex",
  alignItems: "center",
  gap: "4px",
});

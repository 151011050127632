import { ContentCard } from "@/components/common";
import { academyLandingCard } from "@/constants";
import { MAX_WIDTH } from "@/constants/style/layout";
import styled from "@emotion/styled";
import { Button } from "@shared/lib/components/common/atom/button";
import { BUTTON_TYPE } from "@shared/lib/styles/theme";
import { useRouter } from "next/router";

export const AcademyBanner = ({
  isLandingPage,
}: {
  isLandingPage?: boolean | false;
}) => {
  const router = useRouter();

  return (
    <AcademyBannerContainer isLandingPage={isLandingPage || false}>
      <AcademyContent>
        <ImgContent>
          <LeftWrapper>
            <ImgTitle isLandingPage={isLandingPage || false}>
              더 많은 수강 상담과
              <br />
              온라인 라이브 강의 운영까지
              <br />
              우리 학원 매출 성장, 케아클과 함께하세요.
            </ImgTitle>
            <DetailButton
              isLandingPage={isLandingPage || false}
              type={"button"}
              styleType={BUTTON_TYPE.FILL}
              onClick={() => router.push("/academy")}
            >
              {isLandingPage ? "자세히 보기" : "우리 학원 입점 신청하기"}
            </DetailButton>
          </LeftWrapper>
          <ImgContainer>
            <img src="images/main/landing-banner_academy.png" />
          </ImgContainer>
        </ImgContent>
        <InfoCardWrapper>
          {isLandingPage &&
            academyLandingCard.map((info) => (
              <ContentCard
                key={info.id}
                backgroundColor="#fff"
              >
                <NumberTitle>
                  <InfoTitle>{info.title}</InfoTitle>
                  <CircleNumber id="responsive">{info.id}</CircleNumber>
                </NumberTitle>
                <InfoDescription
                  dangerouslySetInnerHTML={{ __html: info.description }}
                />
              </ContentCard>
            ))}
        </InfoCardWrapper>
      </AcademyContent>
    </AcademyBannerContainer>
  );
};

const AcademyBannerContainer = styled.div<{ isLandingPage: boolean | false }>`
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  width: 100vw;
  padding: ${({ isLandingPage }) => (isLandingPage ? "80px 0" : "90px 0")};
  background: ${({ theme }) => theme.colors.sub.blue.default};
  box-sizing: border-box;
`;

const AcademyContent = styled.div`
  width: 100%;
  max-width: ${MAX_WIDTH.INNER_CONTAINER.PC}px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 40px;
  padding: 0 16px;
  ${({ theme }) => theme.media.tablet} {
    gap: 32px;
  }
`;

const ImgContent = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  ${({ theme }) => theme.media.tabletSm} {
    flex-direction: column-reverse;
    gap: 32px;
  }
`;

const ImgTitle = styled.div<{ isLandingPage: boolean | false }>`
  ${({ theme }) => theme.typography.h1.b};
  font-size: ${({ isLandingPage }) => (isLandingPage ? "24px" : "32px")};
  line-height: ${({ isLandingPage }) => (isLandingPage ? "34px" : "125%")};
  color: ${({ theme }) => theme.colors.text.high.white};
  ${({ theme }) => theme.media.tablet} {
    ${({ theme, isLandingPage }) =>
      !isLandingPage && theme.typography.h1.b};
    color: ${({ theme }) => theme.colors.text.high.white};
  }
  ${({ theme }) => theme.media.tabletSm} {
    ${({ theme, isLandingPage }) =>
      isLandingPage ? theme.typography.h2.b : theme.typography.h1.b};
    color: ${({ theme }) => theme.colors.text.high.white};
  }
`;

const LeftWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 24px;
`;

const ImgContainer = styled.div`
  width: 100%;
  max-width: 520px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-left: 20px;
  ${({ theme }) => theme.media.tablet} {
    max-width: 430px;
  }
  ${({ theme }) => theme.media.tabletSm} {
    width: 100%;
    max-width: 100%;
    padding: 0 16px;
  }
  img {
    width: 100%;
  }
`;

const DetailButton = styled(Button)<{ isLandingPage: boolean | false }>`
  &.btn-${BUTTON_TYPE.FILL.toLowerCase()} {
    width: 100%;
    max-width: 385px;
    border-radius: 8px;
    ${({ theme, isLandingPage }) =>
      isLandingPage ? theme.typography.body1.b : theme.typography.h2.b};
    padding: 12px 24px;
    background-color: ${({ theme }) => theme.colors.brand.white.default};
    &:focus,
    &:hover {
      background-color: ${({ theme }) => theme.colors.brand.white.default};
      box-shadow: none;
    }
    ${({ theme }) => theme.media.tabletSm} {
      max-width: 100%;
    }
  }
`;

const InfoCardWrapper = styled.div`
  display: flex;
  gap: 24px;
  width: 100%;
  padding: 0 16px;
  ${({ theme }) => theme.media.tablet} {
    gap: 16px;
  }
  ${({ theme }) => theme.media.tabletSm} {
    gap: 24px;
    box-sizing: border-box;
    overflow-x: auto;
  }
`;

const NumberTitle = styled.div`
  display: flex;
  justify-content: space-between;
  ${({ theme }) => theme.media.tablet} {
    #responsive {
      display: none;
    }
  }
  ${({ theme }) => theme.media.tabletSm} {
    width: 300px;
  }
`;

const InfoTitle = styled.div`
  ${({ theme }) => theme.media.tablet} {
    ${({ theme }) => theme.typography.h3.b};
    color: ${({ theme }) => theme.colors.sub.blue.default};
  }
  ${({ theme }) => theme.media.tabletSm} {
    ${({ theme }) => theme.typography.h2.b};
    color: ${({ theme }) => theme.colors.sub.blue.default};
  }
  ${({ theme }) => theme.typography.h2.b};
  color: ${({ theme }) => theme.colors.sub.blue.default};
`;

const CircleNumber = styled.div`
  width: 26px;
  height: 26px;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  ${({ theme }) => theme.typography.body1.b};
  background: ${({ theme }) => theme.colors.sub.blue.default};
  color: ${({ theme }) => theme.colors.brand.white.default};
`;

const InfoDescription = styled.div`
  ${({ theme }) => theme.typography.body1.m};
  line-height: 22px;
  letter-spacing: -0.4px;
  padding-top: 8px;
  ${({ theme }) => theme.media.tablet} {
    ${({ theme }) => theme.typography.body2.m};
  }
  ${({ theme }) => theme.media.tabletSm} {
    ${({ theme }) => theme.typography.body1.m};
  }
`;

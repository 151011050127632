import styled from "@emotion/styled";

import { MAX_WIDTH } from "@/constants/style/layout";

export const BenefitsSection = () => (
  <StyledBenefitsSection>
    <BenefitsSectionInnerContainer>
      <BenefitSectionTitleContainer>
        {`케아클과 함께하면\n어떤 혜택이 있을까요?`}
      </BenefitSectionTitleContainer>
      <BenefitSectionContentContainer>
        <BenefitCard>
          <BenefitCardIcon>
            <img
              src={"/images/teacher/support/fill-people.svg"}
              alt={"수강생 모집 아이콘"}
            />
          </BenefitCardIcon>
          <BenefitCardTitle>수강생 모집</BenefitCardTitle>
          <BenefitCardContent>
            강의 구성과 운영에는 자신이 있는데 수강생을 어디서 모집해야 할지
            고민되셨나요? 케아클에서 내 강의를 원하는 다양한 수강생을 모집해
            드립니다.
          </BenefitCardContent>
        </BenefitCard>

        <BenefitCard>
          <BenefitCardIcon>
            <img
              src={"/images/teacher/support/fill-curriculum.svg"}
              alt={"커리큘럼 아이콘"}
            />
          </BenefitCardIcon>
          <BenefitCardTitle>새로운 커리큘럼 개발</BenefitCardTitle>
          <BenefitCardContent>
            매번 똑같은 강의 커리큘럼이 아닌 새로운 내용의 강의를 만들고 싶다면,
            최신 트렌드에 민감하게 반응 중인 케아클 전담 매니저와 상의해 보세요.
          </BenefitCardContent>
        </BenefitCard>

        <BenefitCard>
          <BenefitCardIcon>
            <img
              src={"/images/teacher/support/fill-coin.svg"}
              alt={"고정 수입 아이콘"}
            />
          </BenefitCardIcon>
          <BenefitCardTitle>매월 정산되는 고정 수입</BenefitCardTitle>
          <BenefitCardContent>
            케아클 라이브 클래스 수강생의 60%는 강의를 연달아 수강하고 있습니다.
            꾸준히 이어지는 수업을 통해 고정 수입을 기대할 수 있어요!
          </BenefitCardContent>
        </BenefitCard>

        <BenefitCard>
          <BenefitCardIcon>
            <img
              src={"/images/teacher/support/fill-free.svg"}
              alt={"학원 포인트 아이콘"}
            />
          </BenefitCardIcon>
          <BenefitCardTitle>수수료 0원</BenefitCardTitle>
          <BenefitCardContent>
            수강생이 내 수업을 결제하기 전까지 강사님께 청구 되는 금액은
            0원입니다. 모집부터 마케팅, 강의 운영 지원까지 모두 무료로
            지원받으세요!
          </BenefitCardContent>
        </BenefitCard>
      </BenefitSectionContentContainer>
    </BenefitsSectionInnerContainer>
  </StyledBenefitsSection>
);

const StyledBenefitsSection = styled.section`
  padding: 80px 0;
  display: flex;
  align-items: center;
  justify-content: center;

  ${({ theme }) => theme.media.tabletSm} {
    width: 100%;
  }
`;

const BenefitsSectionInnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 56px;

  padding: 0 16px;
  width: 100%;
  max-width: ${MAX_WIDTH.INNER_CONTAINER.PC}px;
`;

const BenefitSectionTitleContainer = styled.div`
  text-align: center;
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  line-height: 125%; /* 37.5px */
  letter-spacing: -0.4px;
  color: ${({ theme }) => theme.colors.text.high.black};

  ${({ theme }) => theme.media.tabletSm} {
    text-align: center;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 125%; /* 30px */
    letter-spacing: -0.4px;

    white-space: pre-line;
  }
`;

const BenefitSectionContentContainer = styled.div`
  display: flex;
  gap: 20px;

  ${({ theme }) => theme.media.tablet} {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 20px;
    row-gap: 20px;
  }

  ${({ theme }) => theme.media.tabletSm} {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
`;

const BenefitCard = styled.div`
  padding: 24px;

  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;

  width: 100%;
  max-width: 285px;

  border-radius: 8px;
  background-color: ${({ theme }) => theme.colors.gray.gray95};

  ${({ theme }) => theme.media.tabletSm} {
    width: 100%;
    max-width: 100%;
  }
`;

const BenefitCardIcon = styled.div``;

const BenefitCardTitle = styled.div`
  text-align: center;

  ${({ theme }) => theme.typography.h2.b};
  color: ${({ theme }) => theme.colors.sub.orange.default};
`;

const BenefitCardContent = styled.div`
  text-align: center;

  ${({ theme }) => theme.typography.body1.m};
  color: ${({ theme }) => theme.colors.text.high.black};

  ${({ theme }) => theme.media.tabletSm} {
    max-width: 237px;
  }
`;

import styled from "@emotion/styled";
import { useModalStore } from "@/stores";
import { Icon } from "@shared/lib/components/common/atom/icon";
import { ICON_SVG } from "@shared/lib/constants/import/icon-svg";
import { Button } from "@shared/lib/components/common/atom/button";
import { BUTTON_TYPE } from "@shared/lib/styles/theme";
import LinkIcon from "@shared/lib/assets/images/icons/line/18/line-link.svg";
import KakaoIcon from "@shared/lib/assets/images/graphic/login/kakao-icon.svg";
import FacebookIcon from "@shared/lib/assets/images/graphic/profile/facebook.svg";

export const ShareContent = () => {
  const { closeModal } = useModalStore();
  return (
    <ShareContainer>
      <ShareWrapper>
        <ShareTitle>강의 공유하기</ShareTitle>
        <CloseIcon
          onClick={() => closeModal("shareModal")}
          icon={ICON_SVG.LINE.SIZE_24.ICON_CLOSE}
        />
      </ShareWrapper>
      <BtnWrapper>
        <StyledButton
          type={"button"}
          styleType={BUTTON_TYPE.OUTLINE}
          // disabled={isEmpty}
          // onClick={handleInquiry}
        >
          <StyledLinkIcon />
          링크복사
        </StyledButton>
        <StyledButton
          type={"button"}
          snsType={"kakao"}
          styleType={BUTTON_TYPE.FILL}
          // disabled={isEmpty}
          // onClick={handleInquiry}
        >
          <StyledKakaoIcon />
          카카오톡
        </StyledButton>
        <StyledButton
          type={"button"}
          snsType={"facebook"}
          styleType={BUTTON_TYPE.FILL}
          // disabled={isEmpty}
          // onClick={handleInquiry}
        >
          <StyledFacebookIcon />
          페이스북
        </StyledButton>
      </BtnWrapper>
    </ShareContainer>
  );
};

const ShareContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 356px;
  gap: 16px;
`;

const ShareWrapper = styled.div`
  height: 100%;
  display: flex;
  justify-content: space-between;
`;

const ShareTitle = styled.div`
  ${({ theme }) => theme.typography.h3.b};
  color: ${({ theme }) => theme.colors.text.high.black};
`;

const CloseIcon = styled(Icon)`
  width: 24px;
  height: 24px;
  flex-shrink: 0;
  &:hover {
    cursor: pointer;
  }

  path {
    stroke: ${({ theme }) => theme.colors.icon.active.black};
  }
`;

const BtnWrapper = styled.div`
  display: flex;
  width: 100%;
  gap: 16px;
`;

const StyledButton = styled(Button)<{ snsType?: "kakao" | "facebook" }>`
  &.btn-${BUTTON_TYPE.OUTLINE.toLowerCase()} {
    ${({ theme }) => theme.typography.body1.b};
    width: 356px;
    border-radius: 8px;
    padding: 12px;
    &:focus,
    &:hover {
      background-color: ${({ theme }) => theme.colors.icon.active.white};
    }
  }
  &.btn-${BUTTON_TYPE.FILL.toLowerCase()} {
    ${({ theme }) => theme.typography.body1.b};
    width: 356px;
    border-radius: 8px;
    padding: 12px;
    background-color: ${({ theme, snsType }) =>
      snsType ? theme.colors.sns[snsType].bg : "transparent"};
    color: ${({ theme, snsType }) =>
      snsType === "kakao"
        ? theme.colors.sns.kakao.text
        : theme.colors.text.high.white};
    &:focus,
    &:hover {
      background-color: ${({ theme, snsType }) =>
        snsType ? theme.colors.sns[snsType].bg : "transparent"};
      box-shadow: none;
    }
  }
`;

const StyledLinkIcon = styled(LinkIcon)`
  width: 18px;
  height: 18px;
  path {
    stroke: ${({ theme }) => theme.colors.icon.active.black};
  }
`;

const StyledKakaoIcon = styled(KakaoIcon)`
  /* width: 18px; */
`;

const StyledFacebookIcon = styled(FacebookIcon)`
  path {
    fill: white;
  }
`;

/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * KAC-API
 * K-Artist Class API
 * OpenAPI spec version: 0.0.1
 */

export type UserControllerInquiriesTypes =
  (typeof UserControllerInquiriesTypes)[keyof typeof UserControllerInquiriesTypes];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const UserControllerInquiriesTypes = {
  COURSE_GENERAL: "COURSE_GENERAL",
  COURSE_SCHEDULE: "COURSE_SCHEDULE",
  COURSE_QNA: "COURSE_QNA",
  SERVICE_GENERAL: "SERVICE_GENERAL",
} as const;

import { useMemo, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useRouter } from "next/router";
import { signIn } from "next-auth/react";
import * as yup from "yup";
import styled from "@emotion/styled";
import { yupResolver } from "@hookform/resolvers/yup";

import { encoding } from "@shared/lib/utils/crypto";
import { MENU } from "@/constants/menu";
import { MAX_WIDTH } from "@/constants/style/layout";

import {
  BottomLinks,
  LoginCard,
  LoginForm,
  SnsLoginContainer,
  SnsLoginRedirect,
} from "./";
import { useAuthControllerSignIn } from "@shared/generated/api/fn/kac/auth/auth";

interface LoginFormInputs {
  email: string;
  password: string;
}

export const LoginTemplate = () => {
  const router = useRouter();
  const { callbackUrl = "/" } = router.query;
  const decodeCallbackUrl = useMemo(
    () => (callbackUrl ? decodeURIComponent(callbackUrl as string) : "/"),
    [callbackUrl],
  );

  const [isShowPassword, setIsShowPassword] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | undefined>(
    undefined,
  );

  const onShowPassword = () => {
    setIsShowPassword(!isShowPassword);
  };

  const mutationSignIn = useAuthControllerSignIn({
    mutation: {
      onSuccess: async (data) => {
        setErrorMessage(undefined);
        /** 로그인 성공 */
        const { accessToken } = data;
        if (accessToken) {
          const result = await signIn("credentials", {
            accessToken,
            redirect: false,
          });
          if (result?.status === 200) {
            router.push(decodeCallbackUrl);
          }
        } else {
          /** 이미 소셜로 가입한 이메일로 일반 로그인 시도 시 */
          const { email, name, phoneNumber, socialType } = data;
          const queryObject = {
            email: email,
            name,
            phone: phoneNumber,
            providerType: socialType,
          };
          const encodeString = encoding(JSON.stringify(queryObject));
          router.push(
            `${MENU.CONNECT_USER.link.url}?user=${encodeURIComponent(encodeString)}`,
          );
        }
      },
      onError: (error) => {
        const data = error.response?.data;
        if (data) {
          const { errorCode } = data;
          if (errorCode === 100007) {
            setErrorMessage("존재하지 않는 계정입니다.");
          } else if (errorCode === 100005) {
            setErrorMessage("이메일 혹은 비밀번호가 올바르지 않습니다.");
          } else {
            setErrorMessage("로그인에 실패했습니다.");
          }
        }
      },
    },
  });

  const schema = yup.object({
    email: yup
      .string()
      .trim("공백은 들어갈 수 없습니다.")
      .email("유효하지 않은 이메일 형식입니다.")
      .matches(/^(?!.* )/, "빈 칸은 포함될 수 없습니다.")
      .required("이메일주소를 입력해주세요."),
    password: yup
      .string()
      .required("비밀번호를 입력해주세요.")
      .trim("공백은 들어갈 수 없습니다."),
  });

  const {
    handleSubmit,
    register,
    formState: { errors, isValid },
  } = useForm<LoginFormInputs>({
    resolver: yupResolver<LoginFormInputs>(schema),
    mode: "onChange",
  });

  const onSubmit: SubmitHandler<LoginFormInputs> = async (data) => {
    mutationSignIn.mutate({
      data: {
        ...data,
        // isSns: false,
      },
    });
  };

  return (
    <LoginTemplateContainer>
      <SnsLoginRedirect />
      <LoginTemplateInnerContainer>
        <LoginCard>
          <LoginTitleContainer>로그인</LoginTitleContainer>
          <LoginForm
            onSubmit={handleSubmit(onSubmit)}
            register={register}
            errors={errors}
            isShowPassword={isShowPassword}
            onShowPassword={onShowPassword}
            errorMessage={errorMessage}
            isValid={isValid}
            isPending={mutationSignIn.isPending}
          />
          <BottomLinks />
          <SnsLoginContainer />
        </LoginCard>
      </LoginTemplateInnerContainer>
    </LoginTemplateContainer>
  );
};

const LoginTemplateContainer = styled.div`
  width: 100%;
  height: 100%;
  min-height: 50vh;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const LoginTemplateInnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: ${MAX_WIDTH.INNER_CONTAINER.PC}px;
  padding: 48px 24px;
`;

const LoginTitleContainer = styled.div`
  ${({ theme }) => theme.typography.h1.b};
  color: ${({ theme }) => theme.colors.text.high.black};

  text-align: center;
`;

import styled from "@emotion/styled";

import {
  ClassCardSection,
  ClassTypeBanner,
  findIdByRouter,
  SlideBanner,
  TitleDescriptionSection,
} from "@/components";
import { useAccessToken } from "@/hooks/auth/use-access-token";
import { useCourseControllerGetSectionsGlobal } from "@shared/generated/api/fn/kac/course/course";
import { useRouter } from "next/router";
import { MAX_WIDTH } from "@/constants/style/layout";
import { useContentsControllerGetGlobalSectionContentsById } from "@shared/generated/api/fn/kac/contents/contents";

export const LiveClassTemplate = () => {
  const router = useRouter();
  const { category } = router.query;
  const { accessToken } = useAccessToken();
  const { data } = useCourseControllerGetSectionsGlobal("ko", undefined, {
    request: { accessToken },
  });
  const sectionId = findIdByRouter(data, category as string) || 0;

  const { data: sectionData } =
    useContentsControllerGetGlobalSectionContentsById("ko", sectionId, {
      request: { accessToken },
    });

  const {
    MAIN: mainData,
    ACADEMY: academyData,
    COURSE: courseData,
    router: classSection,
  } = sectionData || {};

  return (
    <SubMainPageContainer>
      <ClassSectionWrapper>
        <ClassTypeBanner
          name={sectionData?.name || ""}
          description={sectionData?.description || ""}
        />
        {mainData && mainData?.contents.length !== 0 && (
          <section>
            <TitleDescriptionSection
              title={mainData?.title}
              description={mainData?.description || ""}
            />
            <ClassCardSection
              courseData={mainData?.contents}
              classSection={classSection || null}
            />
          </section>
        )}
        {academyData && academyData?.contents.length !== 0 && (
          <section>
            <TitleDescriptionSection
              title={academyData?.title || ""}
              description={academyData?.description || ""}
            />
            <SlideBanner academyInfo={academyData?.contents} />
          </section>
        )}
        {courseData &&
          courseData[0]?.contents.length !== 0 &&
          courseData.map((course) => (
            <section key={course.title}>
              <TitleDescriptionSection
                title={course?.title || ""}
                description={course?.description || ""}
              />
              <ClassCardSection
                courseData={course?.contents}
                classSection={classSection || null}
              />
            </section>
          ))}
      </ClassSectionWrapper>
    </SubMainPageContainer>
  );
};

const SubMainPageContainer = styled.div`
  background: ${({ theme }) => theme.colors.brand.primary.default};
  color: ${({ theme }) => theme.colors.text.high.white} !important;
  width: 100%;
  height: 100%;
  min-height: calc(100vh - 530px);
  padding: 0 0 120px;
  display: flex;
  justify-content: center;
`;

const ClassSectionWrapper = styled.section`
  width: 100%;
  max-width: ${MAX_WIDTH.INNER_CONTAINER.PC}px;
  margin: 0 auto;
  padding: 0 16px;
  display: flex;
  flex-direction: column;
  gap: 64px;
`;

import { ContentCard } from "@/components/common";
import styled from "@emotion/styled";
import { Pagination } from "@mui/material";
import {
  PaginatedUserPointLogsResponseDto,
  UserPointResponseDto,
} from "@shared/generated/api/model";
import PointImage from "@shared/lib/assets/images/icons/fill/etc/fill-point-none.svg";
import PointIcon from "@shared/lib/assets/images/icons/line/28/line-point.svg";
import { priceToLocaleString } from "@shared/lib/utils/price";
import { ChangeEvent } from "react";
import { convertPointType, getPointDescription } from "./convertPointType";
import { formatDate } from "@shared/lib/utils/date/formatSchedule";
import { useTranslation } from "react-i18next";
import { PageHeader } from "@/components/ui/headers";

interface PointProps {
  pointsData: UserPointResponseDto;
  pointsHistoryData: PaginatedUserPointLogsResponseDto;
  total: number;
  curPage: number;
  handlePageChange: (_event: ChangeEvent<unknown>, newPage: number) => void;
}

export const PointInfo = ({
  pointsData,
  pointsHistoryData,
  total,
  curPage,
  handlePageChange,
}: PointProps) => {
  const { currentPoints } = pointsData;
  const { count, rows } = pointsHistoryData;
  const { t } = useTranslation();

  return (
    <>
      <PageHeader title={t("point")} />
      <PointInfoContainer>
        <PointInfoSection>
          <PointTitle>{t("available_points")}</PointTitle>
          <PointText>{priceToLocaleString(currentPoints)}P</PointText>
        </PointInfoSection>
        <PointHistorySection>
          <PointTitle>{t("point_history")}</PointTitle>
          {rows.map((pointData) => (
            <ContentCard
              backgroundColor="#fff"
              border={"1px solid #d2d2d2"}
              key={pointData.id}
            >
              <PointContentWrapper>
                <PointContent>
                  <PointIcon />
                  {pointData?.points > 0 && "+"}
                  {priceToLocaleString(pointData?.points)}
                </PointContent>
                <SplitLine />
                <PointHistory>
                  <HistoryTitle>
                    <span>
                      [{t(`${convertPointType(pointData?.pointChangeType)}`)}]
                    </span>
                    {t(`${getPointDescription(pointData?.pointChangeType)}`)}
                    {pointData?.pointChangeType === "PURCHASE" && (
                      <ClassTitle>
                        {pointData?.purchase?.course?.title as string}
                      </ClassTitle>
                    )}
                  </HistoryTitle>
                  <HistoryDate>
                    {formatDate(new Date(pointData?.createdAt), {
                      withTime: true,
                    })}
                  </HistoryDate>
                </PointHistory>
              </PointContentWrapper>
            </ContentCard>
          ))}
          {count === 0 && (
            <NoPointSction>
              <ImgWrapper>
                <PointImage />
              </ImgWrapper>
              <NoPointDesc>{t("empty_point_history")}</NoPointDesc>
            </NoPointSction>
          )}
          {pointsHistoryData?.count !== undefined &&
            pointsHistoryData?.count > 0 &&
            pointsHistoryData?.count > 3 && (
              <ContentCard
                backgroundColor="#fff"
                border={"1px solid #d2d2d2"}
              >
                <StyledPagination
                  count={total}
                  page={curPage}
                  color="primary"
                  onChange={handlePageChange}
                />
              </ContentCard>
            )}
        </PointHistorySection>
      </PointInfoContainer>
    </>
  );
};

const PointInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
  padding-top: 40px;
`;

const PointInfoSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

const PointTitle = styled.div`
  ${({ theme }) => theme.typography.h3.b};
`;

const PointHistorySection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const NoPointSction = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
  min-height: 360px;
`;

const PointText = styled.div`
  ${({ theme }) => theme.typography.h1.b};
  color: ${({ theme }) => theme.colors.sub.blue.default};
`;

const ImgWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const NoPointDesc = styled.div`
  ${({ theme }) => theme.typography.body2.m};
  color: ${({ theme }) => theme.colors.text.medium.black};
`;

const PointContentWrapper = styled.div`
  display: flex;
  height: 90px;
  align-items: center;
`;

const PointContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 80px;
  ${({ theme }) => theme.typography.body2.b};
  color: ${({ theme }) => theme.colors.sub.blue.default};
`;

const SplitLine = styled.div`
  height: 100%;
  border-right: 1px solid ${({ theme }) => theme.colors.gray.gray90};
  margin: 0 16px;
`;

const PointHistory = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const HistoryTitle = styled.div`
  ${({ theme }) => theme.typography.body2.b};
  span {
    color: ${({ theme }) => theme.colors.text.medium.black};
    padding-right: 4px;
  }
`;

const HistoryDate = styled.div`
  ${({ theme }) => theme.typography.body2.b};
  color: ${({ theme }) => theme.colors.text.disabled.black};
`;

const ClassTitle = styled.div`
  ${({ theme }) => theme.typography.body2.m};
  color: ${({ theme }) => theme.colors.text.disabled.black};
  padding-bottom: 16px;
`;

const StyledPagination = styled(Pagination)`
  display: flex;
  justify-content: center;
`;

import styled from "@emotion/styled";

import { Icon } from "@shared/lib/components/common/atom/icon";
import { ICON_SVG } from "@shared/lib/constants/import/icon-svg";
import { useMediaQueryMaxWidth } from "@shared/lib/hooks/use-media-query-max-width";
import { colors } from "@shared/lib/styles/theme";
import { MAX_WIDTH } from "@/constants/style/layout";

export const ProcedureSection = () => {
  const { isTabletSm } = useMediaQueryMaxWidth();

  return (
    <StyledProcedureSection>
      <ProcedureSectionInnerContainer>
        <ProcedureSectionTitleContainer>
          {`케아클의 강사가 되기 위해\n아래 과정을 거치게 됩니다.`}
        </ProcedureSectionTitleContainer>
        <ProcedureSectionContentContainer>
          <ProcedureCard>
            <ProcedureCardIcon>
              <img
                src="/images/teacher/support/fill-apply.svg"
                alt="강사 지원 아이콘"
              />
            </ProcedureCardIcon>
            <ProcedureCardTitleContainer>
              <ProcedureCardTitle>
                <span className="number">1</span>
                <span className="label">지원</span>
              </ProcedureCardTitle>
              <ProcedureCardSubTitle>
                케아클 홈페이지 강사 지원 페이지를 통해 지원서를 접수해주세요.
              </ProcedureCardSubTitle>
            </ProcedureCardTitleContainer>
          </ProcedureCard>

          <ProcedureCardArrowContainer>
            <Icon
              icon={
                isTabletSm
                  ? ICON_SVG.LINE.SIZE_24.ICON_ARROW_DOWN
                  : ICON_SVG.LINE.SIZE_24.ICON_ARROW_RIGHT
              }
              stroke={colors.icon.active.black}
            />
          </ProcedureCardArrowContainer>

          <ProcedureCard>
            <ProcedureCardIcon>
              <img
                src="/images/teacher/support/fill-review.svg"
                alt="서류 검토 아이콘"
              />
            </ProcedureCardIcon>
            <ProcedureCardTitleContainer>
              <ProcedureCardTitle>
                <span className="number">2</span>
                <span className="label">서류 검토</span>
              </ProcedureCardTitle>
              <ProcedureCardSubTitle>
                제출해주신 지원서를 바탕으로 담당 매니저가 내용 검토를
                진행합니다.
              </ProcedureCardSubTitle>
            </ProcedureCardTitleContainer>
          </ProcedureCard>

          <ProcedureCardArrowContainer className="pc tablet-sm mobile">
            <Icon
              icon={
                isTabletSm
                  ? ICON_SVG.LINE.SIZE_24.ICON_ARROW_DOWN
                  : ICON_SVG.LINE.SIZE_24.ICON_ARROW_RIGHT
              }
              stroke={colors.icon.active.black}
            />
          </ProcedureCardArrowContainer>

          <ProcedureCard>
            <ProcedureCardIcon>
              <img
                src="/images/teacher/support/fill-meet.svg"
                alt="대면/비대면 미팅 아이콘"
              />
            </ProcedureCardIcon>
            <ProcedureCardTitleContainer>
              <ProcedureCardTitle>
                <span className="number">3</span>
                <span className="label">대면/비대면 미팅</span>
              </ProcedureCardTitle>
              <ProcedureCardSubTitle>
                미팅을 통해 강의 개설 여부, 커리큘럼 구성, 일정 등에 대해
                논의합니다.
              </ProcedureCardSubTitle>
            </ProcedureCardTitleContainer>
          </ProcedureCard>

          <ProcedureCardArrowContainer>
            <Icon
              icon={
                isTabletSm
                  ? ICON_SVG.LINE.SIZE_24.ICON_ARROW_DOWN
                  : ICON_SVG.LINE.SIZE_24.ICON_ARROW_RIGHT
              }
              stroke={colors.icon.active.black}
            />
          </ProcedureCardArrowContainer>

          <ProcedureCard>
            <ProcedureCardIcon>
              <img
                src="/images/teacher/support/fill-class-open.svg"
                alt="강의 개설 아이콘"
              />
            </ProcedureCardIcon>
            <ProcedureCardTitleContainer>
              <ProcedureCardTitle>
                <span className="number">4</span>
                <span className="label">강의 개설</span>
              </ProcedureCardTitle>
              <ProcedureCardSubTitle>
                협의 된 내용을 바탕으로 강의를 개설하고 수강생들과 만납니다.
              </ProcedureCardSubTitle>
            </ProcedureCardTitleContainer>
          </ProcedureCard>
        </ProcedureSectionContentContainer>
      </ProcedureSectionInnerContainer>
    </StyledProcedureSection>
  );
};

const StyledProcedureSection = styled.section`
  padding: 80px 0;
  display: flex;
  align-items: center;
  justify-content: center;

  ${({ theme }) => theme.media.tabletSm} {
    width: 100%;
  }
`;

const ProcedureSectionInnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 56px;

  padding: 0 16px;
  width: 100%;
  max-width: ${MAX_WIDTH.INNER_CONTAINER.PC}px;
`;

const ProcedureSectionTitleContainer = styled.div`
  text-align: center;
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  line-height: 125%; /* 37.5px */
  letter-spacing: -0.4px;
  color: ${({ theme }) => theme.colors.text.high.black};

  ${({ theme }) => theme.media.tabletSm} {
    white-space: pre-line;

    text-align: center;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 125%; /* 30px */
    letter-spacing: -0.4px;
  }
`;

const ProcedureSectionContentContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;

  border-radius: 8px;
  background: ${({ theme }) => theme.colors.environment.background.default};
  box-shadow: 4px 4px 24px 0 rgba(0, 0, 0, 0.15);

  padding: 56px;

  ${({ theme }) => theme.media.tablet} {
    display: grid;
    grid-template-columns: 1fr auto 1fr;
    column-gap: 20px;
    row-gap: 56px;
  }

  ${({ theme }) => theme.media.tabletSm} {
    width: 100%;
    display: flex;
    flex-direction: column;
    row-gap: 20px;
  }
`;

const ProcedureCard = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;

  width: 100%;
  max-width: 224px;
`;

const ProcedureCardIcon = styled.div``;

const ProcedureCardTitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
`;

const ProcedureCardTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;

  span {
    &.number {
      display: flex;
      width: 24px;
      height: 24px;
      justify-content: center;
      align-items: center;

      border-radius: 30px;
      background-color: ${({ theme }) => theme.colors.icon.inactive.black};
      color: ${({ theme }) => theme.colors.text.high.white};
    }

    &.label {
      text-align: center;
      ${({ theme }) => theme.typography.h2.b};
      color: ${({ theme }) => theme.colors.text.high.black};
    }
  }
`;

const ProcedureCardSubTitle = styled.div`
  text-align: center;

  ${({ theme }) => theme.typography.body1.m};
  color: ${({ theme }) => theme.colors.text.high.black};
`;

const ProcedureCardArrowContainer = styled.div``;

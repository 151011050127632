import styled from "@emotion/styled";

interface TitleDescriptionProps {
  title: string;
  description: string;
}

export const TitleDescriptionSection = ({
  title,
  description,
}: TitleDescriptionProps) => {
  return (
    <>
      <CourseTitle>{title}</CourseTitle>
      <CourseDescription dangerouslySetInnerHTML={{ __html: description }} />
    </>
  );
};

const CourseTitle = styled.div`
  ${({ theme }) => theme.typography.h1.b};
  color: ${({ theme }) => theme.colors.text.high.white};
  ${({ theme }) => theme.media.tabletSm} {
    ${({ theme }) => theme.typography.h2.b};
    color: ${({ theme }) => theme.colors.text.high.white};
  }
`;

const CourseDescription = styled.p`
  ${({ theme }) => theme.typography.body1.m};
  color: ${({ theme }) => theme.colors.text.medium.white};
  margin: 0;
  padding: 8px 0 24px;
  ${({ theme }) => theme.media.tabletSm} {
    ${({ theme }) => theme.typography.body2.m};
    color: ${({ theme }) => theme.colors.text.medium.white};
  }
`;

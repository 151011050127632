import Link from "next/link";
import styled from "@emotion/styled";

import { MENU } from "@/constants/menu";

export const BottomLinks = () => (
  <BottomAreaContainer>
    <Link
      href={MENU.SIGNUP.link.url}
      passHref
    >
      <span>이메일 가입</span>
    </Link>
    <span className="divider">|</span>
    <Link
      href={MENU.FIND_PASSWORD.link.url}
      passHref
    >
      <span>비밀번호 찾기</span>
    </Link>
  </BottomAreaContainer>
);

const BottomAreaContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  gap: 16px;

  span {
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 100%; /* 14px */
    letter-spacing: 0.5px;

    color: ${({ theme }) => theme.colors.text.medium.black};

    &.divider {
      color: ${({ theme }) => theme.colors.text.disabled.black};
    }
  }
`;

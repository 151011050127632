import { useRouter } from "next/router";
import styled from "@emotion/styled";

import { Button } from "@shared/lib/components/common/atom/button";
import { BUTTON_COLOR, BUTTON_TYPE } from "@shared/lib/styles/theme";
import { MENU } from "@/constants/menu";
import { MAX_WIDTH } from "@/constants/style/layout";

export const TeacherApplySection = () => {
  const router = useRouter();
  const onApply = () => {
    router.push(MENU.TEACHER_APPLY.link.url);
  };

  return (
    <StyledTeacherApplySection>
      <TeacherApplySectionInnerContainer>
        <TeacherApplySectionTitle>
          케아클 전담 매니저 배정으로 빠르게 도와드립니다.
        </TeacherApplySectionTitle>
        <TeacherApplyButton
          styleType={BUTTON_TYPE.FILL}
          color={BUTTON_COLOR.WHITE}
          onClick={onApply}
        >
          강사 등록하기
        </TeacherApplyButton>
      </TeacherApplySectionInnerContainer>
    </StyledTeacherApplySection>
  );
};

const StyledTeacherApplySection = styled.section`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 32px 0;
  width: 100%;

  background-color: ${({ theme }) => theme.colors.environment.background.on};
`;

const TeacherApplySectionInnerContainer = styled.div`
  display: flex;
  gap: 40px;
  align-items: center;
  justify-content: center;

  padding: 0 16px;
  width: 100%;
  max-width: ${MAX_WIDTH.INNER_CONTAINER.PC}px;

  ${({ theme }) => theme.media.tabletSm} {
    flex-direction: column;
  }
`;

const TeacherApplySectionTitle = styled.div`
  color: ${({ theme }) => theme.colors.text.high.white};
  text-align: center;
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  line-height: 145%; /* 43.5px */
  letter-spacing: -0.4px;
`;

const TeacherApplyButton = styled(Button)`
  &.btn-${BUTTON_TYPE.FILL.toLowerCase()} {
    border-radius: 8px;
    min-width: 300px;
  }
`;

export const phoneRegex = /^\d{3}-\d{3,4}-\d{4}$/;

export const getFormattedPhoneNumber = (value: string) => {
  const formattedNumber = value
    .replace(/\D/g, "")
    .replace(
      /^(\d{3})(\d{0,4})(\d{0,4})/,
      (match, p1, p2, p3) => `${p1}${p2 ? "-" + p2 : ""}${p3 ? "-" + p3 : ""}`,
    );

  return formattedNumber;
};

export const getFormattedDate = (value: string) => {
  const formattedDate = value
    .replace(/\D/g, "")
    .replace(
      /^(\d{4})(\d{0,2})(\d{0,2})/,
      (match, p1, p2, p3) => `${p1}${p2 ? "-" + p2 : ""}${p3 ? "-" + p3 : ""}`,
    );

  return formattedDate;
};

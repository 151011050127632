import { useRouter } from "next/router";
import styled from "@emotion/styled";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { Button } from "@mui/material";

interface PageHeaderProps {
  title: string;
  backButtonEnabled?: boolean | false;
  buttonText?: string;
  handleBtnClick?: () => void;
  addButtonText?: string | null;
  handleAddBtnClick?: () => void;
  path?: string | null;
}

export const PageHeader = ({
  title,
  backButtonEnabled,
  buttonText,
  handleBtnClick,
  addButtonText,
  handleAddBtnClick,
  path,
}: PageHeaderProps) => {
  const router = useRouter();

  const handleBack = () => {
    path ? router.push(path) : router.back();
  };

  return (
    <PageHeaderContainer>
      <Wrapper>
        {backButtonEnabled && (
          <IconWrapper onClick={handleBack}>
            <ChevronLeftIcon />
          </IconWrapper>
        )}
        <h1>{title}</h1>
      </Wrapper>
      <Wrapper>
        {buttonText && (
          <Button
            variant="contained"
            sx={{ boxShadow: "none" }}
            onClick={handleBtnClick}
          >
            {buttonText}
          </Button>
        )}
        {addButtonText && (
          <Button
            variant="contained"
            sx={{ boxShadow: "none" }}
            onClick={handleAddBtnClick}
          >
            {addButtonText}
          </Button>
        )}
      </Wrapper>
    </PageHeaderContainer>
  );
};

const PageHeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  h1 {
    font-size: 24px;
    line-height: 34px;
    margin: 0;
  }
`;

const IconWrapper = styled.button`
  width: 29px;
  ${({ theme }) => theme.typography.h1.b};
  color: ${({ theme }) => theme.colors.text.high.black};
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Wrapper = styled.div`
  display: flex;
  gap: 8px;
`;

import styled from "@emotion/styled";
import { Button } from "@shared/lib/components/common/atom/button";
import { BUTTON_TYPE } from "@shared/lib/styles/theme";
import { ClassContent } from "./ClassContent";
import { PriceContent } from "./PriceContent";
import { PaymentResponseDto } from "@shared/generated/api/model";
import { PointContent } from "./PointContent";
import { CustomerInfo } from "./CustomerInfo";
import { PurchaseType } from "./PurchaseType";
import { convertPurchaseStatus, PurchaseStatus } from "./convertPurchaseResult";
import { useRouter } from "next/router";

interface SuccessProps {
  PurchaseData: PaymentResponseDto;
}

export const SuccessContent = ({ PurchaseData }: SuccessProps) => {
  const router = useRouter();
  const { courseInfo, amountInfo, pointInfo, userInfo, purchaseResult } =
    (PurchaseData as PaymentResponseDto) || {};

  const handleBackPurchase = () => {
    router.push(`/my/orders/${purchaseResult?.purchaseId}`);
  };
  return (
    <>
      <SuccessTitle>
        {purchaseResult?.purchaseStatus &&
          convertPurchaseStatus(
            purchaseResult?.purchaseStatus as PurchaseStatus,
          )}
      </SuccessTitle>
      <CardWrapper>
        {courseInfo && (
          <ClassContent
            classData={courseInfo}
            paymentStatus={true}
          />
        )}
        {courseInfo && (
          <PriceContent
            usedPoints={amountInfo?.usedPoints || 0}
            price={amountInfo?.amount || 0}
            discountRate={amountInfo?.discountRate || 0}
            paymentStatus={true}
          />
        )}
        <PointContent
          earnedPoint={pointInfo?.earnedPoint}
          usedPoints={pointInfo?.usedPoints}
        />
        {userInfo && <CustomerInfo customerData={userInfo} />}
        <PurchaseType purchaseResult={purchaseResult} />
        <TermsSection>
          <Description className="bold">
            LIVE 클래스, 꼭 확인하세요!
          </Description>
          <Description>
            · 줌(ZOOM)을 통해 화상으로 진행되는 온라인 라이브 수업입니다.
            <br />
            · 스케줄 48시간 이내 취소하시는 경우, 시점에 따른 취소 수수료가
            발생합니다.
            <br />
            · 스케줄 48시간 이전에 취소하시는 경우, 취소 수수료 없이 전액
            환불됩니다.
            <br />· 스케줄의 변경은 취소하시는 경우, 전액 환불되며 사용한 쿠폰은
            복구됩니다.
          </Description>
        </TermsSection>
        <LinkButton
          type={"submit"}
          styleType={BUTTON_TYPE.OUTLINE}
          onClick={handleBackPurchase}
          disabled={false}
        >
          구매 내역 확인
        </LinkButton>
      </CardWrapper>
    </>
  );
};

const SuccessTitle = styled.div`
  ${({ theme }) => theme.typography.h1.b};
  color: ${({ theme }) => theme.colors.text.high.white};
  padding-bottom: 40px;
`;

const CardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
`;

const TermsSection = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  .bold {
    font-weight: 700;
  }
`;

const Description = styled.div`
  ${({ theme }) => theme.typography.body2.m};
  color: ${({ theme }) => theme.colors.text.medium.white};
`;

const BackButton = styled(Button)`
  &.btn-${BUTTON_TYPE.OUTLINE.toLowerCase()} {
    ${({ theme }) => theme.typography.body2.b};
    width: 300px;
    border-radius: 8px;
    padding: 12px 16px;
    margin-top: 24px;
    color: ${({ theme }) => theme.colors.text.high.white};

    &:focus {
      background-color: ${({ theme }) => theme.colors.brand.primary.hover};
    }
    &:hover {
      background-color: ${({ theme }) => theme.colors.brand.primary.pressed};
    }

    &:disabled {
      background-color: ${({ theme }) => theme.colors.brand.white.disabled};
      color: ${({ theme }) => theme.colors.text.disabled.white};
    }
  }
`;

const LinkButton = styled(Button)`
  &.btn-${BUTTON_TYPE.OUTLINE.toLowerCase()} {
    ${({ theme }) => theme.typography.body1.b};
    width: 100%;
    border-radius: 8px;
    padding: 12px 16px;
    /* margin-top: 24px; */
    border: 1px solid ${({ theme }) => theme.colors.icon.inactive.white};
    color: ${({ theme }) => theme.colors.text.high.white};

    &:focus {
      background-color: ${({ theme }) => theme.colors.brand.primary.hover};
    }
    &:hover {
      background-color: ${({ theme }) => theme.colors.brand.primary.pressed};
    }

    &:disabled {
      background-color: ${({ theme }) => theme.colors.brand.white.disabled};
      color: ${({ theme }) => theme.colors.text.disabled.white};
    }
  }
`;

export const Purchase_Status = {
  COMPLETED: "COMPLETED",
  IN_PROGRESS: "IN_PROGRESS",
} as const;

export type PurchaseStatus = keyof typeof Purchase_Status;

export const Purchase_Type = {
  CARD: "CARD",
  VIRTUAL_ACCOUNT: "VIRTUAL_ACCOUNT",
  MOBILE_PHONE: "MOBILE_PHONE",
  TRANSFER: "TRANSFER",
  POINT: "POINT",
} as const;

export type PurchaseTypes = keyof typeof Purchase_Type;

const purchaseTypeMap: Record<PurchaseTypes, string> = {
  CARD: "카드",
  VIRTUAL_ACCOUNT: "가상계좌",
  MOBILE_PHONE: "휴대폰",
  TRANSFER: "계좌이체",
  POINT: "포인트 결제",
};

export const convertPurchaseStatus = (status: PurchaseStatus) => {
  if (status === Purchase_Status.COMPLETED) {
    return "결제 완료";
  } else if (status === Purchase_Status.IN_PROGRESS) {
    return "가상계좌 발급 완료";
  }
  return "";
};

export const convertPurchaseType = (type: PurchaseTypes): string => {
  if (type === null) {
    return "";
  }
  return purchaseTypeMap[type] || "";
};

export type PaymentStatus =
  | "PENDING"
  | "IN_PROGRESS"
  | "COMPLETED"
  | "FAILED"
  | "CANCELED"
  | "REFUNDED";

type StatusInfo = {
  text: string;
  color: string;
};

export const getStatusInfo = (status: PaymentStatus): StatusInfo => {
  const statusMap: Record<PaymentStatus, StatusInfo> = {
    PENDING: {
      text: "결제 진행중",
      color: "#9F9F9F",
    },
    IN_PROGRESS: {
      text: "입금 대기",
      color: "#9F9F9F",
    },
    COMPLETED: {
      text: "결제 완료",
      color: "#0FA858",
    },
    FAILED: {
      text: "결제 실패",
      color: "#9F9F9F",
    },
    CANCELED: {
      text: "주문 취소",
      color: "#D2D2D2",
    },
    REFUNDED: {
      text: "환불",
      color: "#9F9F9F",
    },
  };

  return statusMap[status];
};

import styled from "@emotion/styled";
import { ContentCard } from "@/components/common";
import { PageHeader } from "@/components/ui/headers";
import { OrderContent, PaymentStatus } from "@/components/domain";
import {
  getPurchaseControllerPurchasesQueryKey,
  usePurchaseControllerPurchases,
} from "@shared/generated/api/fn/kac/purchase/purchase";
import { useAccessToken } from "@/hooks/auth/use-access-token";
import { Pagination, Skeleton } from "@mui/material";
import { ChangeEvent, useEffect, useState } from "react";
import { NoClassPlaceholder } from "../class/NoClassPlaceholder";
import { useRouter } from "next/router";

export const OrdersTemplate = () => {
  const router = useRouter();
  const [total, setTotal] = useState(1);
  const [curPage, setCurPage] = useState(1);
  const PAGE_SIZE = 3;

  const { accessToken } = useAccessToken();
  const { data: orderList, isLoading } = usePurchaseControllerPurchases(
    { skip: (curPage - 1) * PAGE_SIZE, take: PAGE_SIZE },
    {
      request: { accessToken },
      query: {
        enabled: !!accessToken,
        queryKey: [
          getPurchaseControllerPurchasesQueryKey(),
          { curPage, pageSize: PAGE_SIZE },
        ],
      },
    },
  );

  const handlePageChange = (_event: ChangeEvent<unknown>, newPage: number) => {
    setCurPage(newPage);
  };

  useEffect(() => {
    orderList ? setTotal(Math.ceil(orderList?.count / PAGE_SIZE)) : setTotal(1);
  }, [orderList]);

  if (isLoading) {
    return (
      <OrdersWrapper>
        <Skeleton
          variant="rounded"
          width={"100%"}
          height={"230px"}
          animation={"pulse"}
          sx={{ background: "#d2d2d2", borderRadius: "8px" }}
        />
        <Skeleton
          variant="rounded"
          width={"100%"}
          height={"230px"}
          animation={"pulse"}
          sx={{ background: "#d2d2d2", borderRadius: "8px" }}
        />
        <Skeleton
          variant="rounded"
          width={"100%"}
          height={"60px"}
          animation={"pulse"}
          sx={{ background: "#d2d2d2", borderRadius: "8px" }}
        />
      </OrdersWrapper>
    );
  }

  return (
    <>
      <PageHeader title={"구매내역"} />
      <OrdersWrapper>
        {orderList?.count !== 0 ? (
          orderList?.rows.map((order) => (
            <ContentCard
              backgroundColor="#fff"
              border={"1px solid #d2d2d2"}
              key={order.id}
            >
              <OrderContent
                onClick={() => router.push(`/my/orders/${order.id}`)}
                courseInfo={order.course || {}}
                paidAmount={order.paidAmount}
                status={order.status as PaymentStatus}
                paymentGatewayLogs={order.paymentGatewayLogs}
                completedAt={order.completedAt || ""}
              />
            </ContentCard>
          ))
        ) : (
          <NoClassPlaceholder placeholder="구매한 강의가 없습니다." />
        )}
        {orderList?.count !== undefined &&
          orderList.count > 0 &&
          orderList.count > PAGE_SIZE && (
            <ContentCard
              backgroundColor="#fff"
              border={"1px solid #d2d2d2"}
            >
              <StyledPagination
                count={total}
                page={curPage}
                color="primary"
                // showFirstButton
                // showLastButton
                onChange={handlePageChange}
              />
            </ContentCard>
          )}
      </OrdersWrapper>
    </>
  );
};

const OrdersWrapper = styled.div`
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
`;

const StyledPagination = styled(Pagination)`
  display: flex;
  justify-content: center;
`;

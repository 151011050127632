import { SuccessContent } from "@/components/domain";
import { useAccessToken } from "@/hooks/auth/use-access-token";
import styled from "@emotion/styled";
import { CircularProgress } from "@mui/material";

import {
  getPurchaseControllerHandleTossSuccessQueryKey,
  usePurchaseControllerHandleTossSuccess,
} from "@shared/generated/api/fn/kac/purchase/purchase";
import { Button } from "@shared/lib/components/common/atom/button";
import { Icon } from "@shared/lib/components/common/atom/icon";
import { ICON_SVG } from "@shared/lib/constants/import/icon-svg";
import { BUTTON_TYPE } from "@shared/lib/styles/theme";
import { openToast, TOAST_TYPE } from "@shared/lib/utils/toast";
import { useRouter } from "next/router";
import { useEffect } from "react";

export const SuccessTemplate = () => {
  const { accessToken, isLoading: isTokenLoading } = useAccessToken();
  const router = useRouter();

  useEffect(() => {
    // 로딩이 완료되었고, accessToken이 없는 경우 리다이렉트
    if (!isTokenLoading && !accessToken) {
      router.push("/");
    }
  }, [accessToken, isTokenLoading, router]);

  const { courseId, cycleId, orderId, paymentKey, amount } = router.query;
  const {
    data: purchaseData,
    isLoading: isPaymentLoading,
    error,
  } = usePurchaseControllerHandleTossSuccess(
    {
      orderId: orderId as string,
      paymentKey: paymentKey as string,
      amount: Number(amount),
    },
    {
      request: { accessToken },
      query: {
        enabled: !!orderId && !!accessToken,
        queryKey: getPurchaseControllerHandleTossSuccessQueryKey({
          orderId: orderId as string,
          paymentKey: paymentKey as string,
          amount: Number(amount),
        }),
      },
    },
  );

  const handleBackPurchase = () => {
    router.push({
      pathname: "/payment",
      query: {
        courseId: courseId,
        cycleId: cycleId,
      },
    });
  };

  if (isPaymentLoading) {
    return (
      <SuccessContainer>
        <SuccessContentWrapper>
          <LoadingSpinnerWrapper>
            <CircularProgress />
          </LoadingSpinnerWrapper>
          <Description>결제 진행 중입니다...</Description>
        </SuccessContentWrapper>
      </SuccessContainer>
    );
  }

  // 에러 상태 처리
  if (error) {
    const { errorCode } = error.response?.data as any;
    if (errorCode === 100023) {
      openToast(TOAST_TYPE.ERROR, "이미 마감된 강의입니다.");
      router.push("/");
    } else if (errorCode === 100025) {
      openToast(TOAST_TYPE.ERROR, "이미 구매한 강의입니다.");
      // setHasClicked(true);
      router.push("/");
    } else if (errorCode === 100026) {
      openToast(TOAST_TYPE.ERROR, "이미 완료된 결제입니다.");
      router.push("/");
    }
    return (
      <SuccessContainer>
        <SuccessContentWrapper>
          <ErrorIcon icon={ICON_SVG.LINE.SIZE_24.ICON_WARNING} />
          <SuccessTitle>결제 실패</SuccessTitle>
          <Description>
            결제 처리 중 오류가 발생했습니다. 다시 시도해주세요.
          </Description>
          <BackButton
            type={"submit"}
            styleType={BUTTON_TYPE.OUTLINE}
            onClick={handleBackPurchase}
            disabled={false}
          >
            돌아가기
          </BackButton>
        </SuccessContentWrapper>
      </SuccessContainer>
    );
  }

  return (
    <SuccessContainer>
      <SuccessContentWrapper>
        {purchaseData && <SuccessContent PurchaseData={purchaseData} />}
      </SuccessContentWrapper>
    </SuccessContainer>
  );
};

const SuccessContainer = styled.div`
  width: 100%;
  min-height: calc(100vh - 560px);
  padding: 48px 0 120px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  background: ${({ theme }) => theme.colors.brand.primary.default};
`;

const SuccessContentWrapper = styled.div`
  width: 100%;
  max-width: 720px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const LoadingSpinnerWrapper = styled.div`
  display: flex;
  padding: 24px;
`;

const SuccessTitle = styled.div`
  ${({ theme }) => theme.typography.h1.b};
  color: ${({ theme }) => theme.colors.text.high.white};
  padding-bottom: 40px;
`;

const ErrorIcon = styled(Icon)`
  flex-shrink: 0;
  width: 24px;
  margin-bottom: 24px;

  rect {
    fill: ${({ theme }) => theme.colors.state.error.default};
  }

  circle {
    &:first-of-type {
      stroke: ${({ theme }) => theme.colors.state.error.default};
    }

    &:last-of-type {
      fill: ${({ theme }) => theme.colors.state.error.default};
    }
  }
`;

const Description = styled.div`
  ${({ theme }) => theme.typography.body2.m};
  color: ${({ theme }) => theme.colors.text.medium.white};
`;

const BackButton = styled(Button)`
  &.btn-${BUTTON_TYPE.OUTLINE.toLowerCase()} {
    ${({ theme }) => theme.typography.body2.b};
    width: 300px;
    border-radius: 8px;
    padding: 12px 16px;
    margin-top: 24px;
    color: ${({ theme }) => theme.colors.text.high.white};

    &:focus {
      background-color: ${({ theme }) => theme.colors.brand.primary.hover};
    }
    &:hover {
      background-color: ${({ theme }) => theme.colors.brand.primary.pressed};
    }

    &:disabled {
      background-color: ${({ theme }) => theme.colors.brand.white.disabled};
      color: ${({ theme }) => theme.colors.text.disabled.white};
    }
  }
`;

import styled from "@emotion/styled";

import { useMediaQueryMaxWidth } from "@shared/lib/hooks/use-media-query-max-width";
import { MAX_WIDTH } from "@/constants/style/layout";

export const TeacherReviewSection = () => {
  const { isTabletSm } = useMediaQueryMaxWidth();

  return (
    <StyledTeacherReviewSection>
      <TeacherReviewSectionInnerContainer>
        <TeacherReviewSectionTitleContainer>
          {`케아클 강사의 실제\n후기도 들어볼까요?`}
        </TeacherReviewSectionTitleContainer>
        <TeacherReviewSectionContentContainer>
          <TeacherReviewItem>
            <img
              src={`/images/teacher/support/${isTabletSm ? "teacher-m.png" : "teacher.png"}`}
              alt={"강사 후기"}
            />
          </TeacherReviewItem>
          <TeacherReviewItem>
            <img
              src={`/images/teacher/support/${isTabletSm ? "teacher1-m.png" : "teacher1.png"}`}
              alt={"강사1 후기"}
            />
          </TeacherReviewItem>
          <TeacherReviewItem>
            <img
              src={`/images/teacher/support/${isTabletSm ? "teacher2-m.png" : "teacher2.png"}`}
              alt={"강사2 후기"}
            />
          </TeacherReviewItem>
          <TeacherReviewItem>
            <img
              src={`/images/teacher/support/${isTabletSm ? "teacher3-m.png" : "teacher3.png"}`}
              alt={"강사3 후기"}
            />
          </TeacherReviewItem>
        </TeacherReviewSectionContentContainer>
      </TeacherReviewSectionInnerContainer>
    </StyledTeacherReviewSection>
  );
};

const StyledTeacherReviewSection = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 80px 0;
  width: 100%;

  background-color: ${({ theme }) => theme.colors.environment.background.on};
`;

const TeacherReviewSectionInnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: 56px;

  padding: 0 16px;
  width: 100%;
  max-width: ${MAX_WIDTH.INNER_CONTAINER.PC}px;
`;

const TeacherReviewSectionTitleContainer = styled.div`
  color: ${({ theme }) => theme.colors.text.high.white};
  text-align: center;
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  line-height: 125%; /* 37.5px */
  letter-spacing: -0.4px;

  ${({ theme }) => theme.media.tabletSm} {
    color: ${({ theme }) => theme.colors.text.high.white};
    text-align: center;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 125%; /* 30px */
    letter-spacing: -0.4px;

    white-space: pre-line;
  }
`;

const TeacherReviewSectionContentContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;

  ${({ theme }) => theme.media.tablet} {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 20px;
    row-gap: 20px;
  }

  ${({ theme }) => theme.media.tabletSm} {
    display: flex;
    flex-direction: column;

    width: 100%;
  }
`;

const TeacherReviewItem = styled.div`
  img {
    width: 100%;
    max-width: 285px;
  }

  ${({ theme }) => theme.media.tabletSm} {
    width: 100%;

    img {
      max-width: 100%;
    }
  }
`;

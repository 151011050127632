import {
  ChangeEvent,
  KeyboardEvent,
  useEffect,
  useMemo,
  useState,
} from "react";
import { SubmitErrorHandler, SubmitHandler, useForm } from "react-hook-form";
import Link from "next/link";
import { useRouter } from "next/router";
import { signIn } from "next-auth/react";
import * as yup from "yup";
import styled from "@emotion/styled";
import { yupResolver } from "@hookform/resolvers/yup";

import { Button } from "@shared/lib/components/common/atom/button";
import { CheckBox } from "@shared/lib/components/common/atom/check-box";
import { Icon } from "@shared/lib/components/common/atom/icon";
import { TimerSpan } from "@shared/lib/components/common/atom/span/timer-span";
import { TextField } from "@shared/lib/components/common/atom/text-feild";
import { ICON_SVG } from "@shared/lib/constants/import/icon-svg";
import { BUTTON_TYPE } from "@shared/lib/styles/theme";
import { openToast, TOAST_TYPE } from "@shared/lib/utils/toast";
import { MENU } from "@/constants/menu";
import { MAX_WIDTH } from "@/constants/style/layout";
import { getFormattedPhoneNumber, phoneRegex } from "@/utils/string";

import {
  useAuthControllerSendVerification,
  useAuthControllerSignUp,
  useAuthControllerVerifyCode,
} from "@shared/generated/api/fn/kac/auth/auth";
import {
  SendVerificationRequestDtoType,
  SignUpRequestDtoSocialType,
} from "@shared/generated/api/model";

interface SignUpForm {
  name: string;
  password?: string;
  email: string;
  matchNewPassword?: string;
  phoneNumber: string;
  verificationCode: string;
  isAgeAgree: boolean;
  isServiceAgree: boolean;
  isPrivateAgree: boolean;
  marketingOptIn?: boolean;
  isProvider?: boolean;
}
interface QueryParams {
  socialType?: string;
  socialId?: string;
}

export const SignUpTemplate = () => {
  const router = useRouter();

  const { socialType, socialId } = router.query as QueryParams;
  // const userDecoded: SignUpQuery | undefined = useMemo(() => {
  //   if (user) {
  //     return JSON.parse(decoding(user as string))
  //   }
  // }, [user])

  const isProviderUser = useMemo(() => !!socialType, [socialType]);

  useEffect(() => {
    if (isProviderUser) {
      // 소셜 로그인 사용자 정보 초기화
      setValue("isProvider", true);
      setValue("email", ""); // 소셜 로그인 시 이메일을 자동으로 설정할 수 있음
      trigger("isProvider");
    } else {
      setValue("isProvider", false);
      trigger("isProvider");
    }
  }, [isProviderUser]);

  const [isShowPassword, setIsShowPassword] = useState(false);
  const [isShowMatchPassword, setIsShowMatchPassword] = useState(false);

  const [verifyActionCount, setVerifyActionCount] = useState(0);
  const [isVerifyNumberDisabled, setIsVerifyNumberDisabled] =
    useState<boolean>(true);
  const [isSuccessVerify, setIsSuccessVerify] = useState<boolean>(false);

  const onShowPassword = () => {
    setIsShowPassword(!isShowPassword);
  };

  const onShowMatchPassword = () => {
    setIsShowMatchPassword(!isShowMatchPassword);
  };

  const schema = yup.object({
    isProvider: yup.boolean(),
    name: yup
      .string()
      .trim("공백은 들어갈 수 없습니다.")
      .matches(/^[ㄱ-ㅎ가-힣a-zA-Z\s]+$/, "이름은 한글,영문만 가능 합니다.")
      .required("이름(실명)을 입력해주세요."),
    email: yup
      .string()
      .trim("공백은 들어갈 수 없습니다.")
      .email("규칙에 맞는 이메일 주소를 입력해주세요.")
      .matches(/^(?!.* )/, "빈 칸은 포함될 수 없습니다.")
      .required("이메일을 입력해주세요."),
    password: yup.string().when("isProvider", {
      is: false,
      then: (schema) =>
        schema
          .min(8, "비밀번호는 8~20자 이내로 입력해야 합니다.")
          .max(20, "비밀번호는 8~20자 이내로 입력해야 합니다.")
          .matches(
            /[a-zA-Z0-9d~!@#$%^&*()_+=]$/,
            "허용되지 않는 특수문자가 포함되어 있습니다.",
          )
          .matches(/^(?!.* )/, "빈 칸은 포함될 수 없습니다.")
          .matches(
            /^(?!((?:[A-Za-z]+)|(?:[~!@#$%^&*()_+=]+)|(?:[0-9]+))$)[A-Za-z\d~!@#$%^&*()_+=]/,
            "반드시 영문/숫자/특수문자 중 2개 이상의 조합을 사용해야 합니다.",
          )
          .trim("공백은 들어갈 수 없습니다.")
          .required("비밀번호를 입력해주세요."),
    }),
    matchNewPassword: yup.string().when("isProvider", {
      is: false,
      then: (schema) =>
        schema
          .min(8, "비밀번호는 8~20자 이내로 입력해야 합니다.")
          .max(20, "비밀번호는 8~20자 이내로 입력해야 합니다.")
          .matches(
            /[a-zA-Z0-9d~!@#$%^&*()_+=]$/,
            "허용되지 않는 특수문자가 포함되어 있습니다.",
          )
          .matches(/^(?!.* )/, "빈 칸은 포함될 수 없습니다.")
          .matches(
            /^(?!((?:[A-Za-z]+)|(?:[~!@#$%^&*()_+=]+)|(?:[0-9]+))$)[A-Za-z\d~!@#$%^&*()_+=]/,
            "반드시 영문/숫자/특수문자 중 2개 이상의 조합을 사용해야 합니다.",
          )
          .oneOf([yup.ref("password")], "비밀번호가 일치하지 않습니다.")
          .required("비밀번호를 입력해 주세요."),
    }),
    phoneNumber: yup
      .string()
      .trim()
      .matches(phoneRegex, "유효하지 않은 핸드폰 형식입니다.")
      .required("핸드폰 번호 입력해주세요."),
    verificationCode: yup.string().trim().required("인증 코드를 입력해주세요."),
    isAgeAgree: yup.boolean().required().isTrue(),
    isServiceAgree: yup.boolean().required().isTrue(),
    isPrivateAgree: yup.boolean().required().isTrue(),
    marketingOptIn: yup.boolean(),
  });

  const {
    handleSubmit,
    watch,
    setValue,
    getValues,
    setError,
    register,
    trigger,
    clearErrors,
    formState: { errors, isValid },
  } = useForm<SignUpForm>({
    resolver: yupResolver<SignUpForm>(schema),
    mode: "onChange",
    defaultValues: {
      phoneNumber: "",
    },
  });

  const phoneNumber = watch("phoneNumber");
  const onChangePhoneNumber = async (e: ChangeEvent<HTMLInputElement>) => {
    setValue("phoneNumber", getFormattedPhoneNumber(e.target.value));
    await trigger("phoneNumber");
  };

  const onKeyDownPhoneNumber = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      onSendVerifyNumber();
    }
  };

  const sendVerifyNumberMutation = useAuthControllerSendVerification({
    mutation: {
      onSuccess: (data) => {
        //에러는 아니지만 인증번호 요청 후 요청 성공하면 인증번호 가이드 문구를 위해 에러를 발생시킨다.
        setError("verificationCode", {
          type: "SUCCESS",
          message: "인증번호가 전송되었습니다. 카카오톡/문자를 확인해 주세요.",
        });
        setIsVerifyNumberDisabled(false);
        setVerifyActionCount(verifyActionCount + 1);
        clearErrors("phoneNumber");
      },
      onError: (e) => {
        // const { errorCode, message, user } = e.response?.data;
        console.log(e);
        // if (code === PostKacAuthVerifyNumberSend400Code.E02) {
        //   const providerType = user?.providerType;
        //   if (providerType === SignUpRequestDtoSocialType.KAKAO)
        //     setError("phoneNumber", {
        //       type: PostKacAuthVerifyNumberSend400Code.E02,
        //       message:
        //         "동일한 전화번호를 사용하는 카카오 계정이 이미 존재합니다.",
        //     });
        //   if (providerType === SignUpRequestDtoSocialType.APPLE)
        //     setError("phoneNumber", {
        //       type: PostKacAuthVerifyNumberSend400Code.E02,
        //       message:
        //         "동일한 전화번호를 사용하는 애플 계정이 이미 존재합니다.",
        //     });
        //   if (providerType === SignUpRequestDtoSocialType.FACEBOOK)
        //     setError("phoneNumber", {
        //       type: PostKacAuthVerifyNumberSend400Code.E02,
        //       message:
        //         "동일한 전화번호를 사용하는 페이스북 계정이 이미 존재합니다.",
        //     });
        //   if (providerType === SignUpRequestDtoSocialType.GOOGLE)
        //     setError("phoneNumber", {
        //       type: PostKacAuthVerifyNumberSend400Code.E02,
        //       message:
        //         "동일한 전화번호를 사용하는 구글 계정이 이미 존재합니다.",
        //     });
        //   if (providerType === SignUpRequestDtoSocialType.NAVER)
        //     setError("phoneNumber", {
        //       type: PostKacAuthVerifyNumberSend400Code.E02,
        //       message:
        //         "동일한 전화번호를 사용하는 네이버 계정이 이미 존재합니다.",
        //     });
        //   if (!providerType)
        //     setError("phoneNumber", {
        //       type: PostKacAuthVerifyNumberSend400Code.E02,
        //       message:
        //         "동일한 전화번호를 사용하는 이메일 계정이 이미 존재합니다.",
        //     });
        // } else {
        //   setError("phoneNumber", {
        //     type: PostKacAuthVerifyNumberSend400Code.E01,
        //     message,
        //   });
        // }
      },
    },
  });

  const onSendVerifyNumber = async () => {
    sendVerifyNumberMutation.mutate({
      data: {
        identifier: phoneNumber.replaceAll("-", ""),
        method: "SMS",
        type: SendVerificationRequestDtoType.SIGNUP,
      },
    });
  };

  const onVerifyNumberEndTime = () => {
    if (!isSuccessVerify) {
      setIsVerifyNumberDisabled(true);
    }
  };

  const verifyNumberCheckMutation = useAuthControllerVerifyCode({
    mutation: {
      onSuccess: () => {
        setIsSuccessVerify(true);
        clearErrors("verificationCode");
      },
      onError: (e) => {
        // const { code, message } = e.response?.data;
        // setError("verificationCode", { type: code, message: message });
      },
    },
  });

  const verificationCode = watch("verificationCode");
  const onVerifyNumberCheck = () => {
    verifyNumberCheckMutation.mutate({
      data: {
        type: SendVerificationRequestDtoType.SIGNUP,
        method: "SMS",
        identifier: phoneNumber.replaceAll("-", ""),
        verificationCode: verificationCode!,
      },
    });
  };

  const onKeyDownVerifyNumber = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      onVerifyNumberCheck();
    }
  };

  const isAgeAgree = watch("isAgeAgree");
  const isServiceAgree = watch("isServiceAgree");
  const isPrivateAgree = watch("isPrivateAgree");
  const marketingOptIn = watch("marketingOptIn");
  const onChangeAgree = async (
    fieldName:
      | "isAgeAgree"
      | "isServiceAgree"
      | "isPrivateAgree"
      | "marketingOptIn",
    isAgree: boolean,
  ) => {
    setValue(fieldName, isAgree);
    await trigger(fieldName);
  };

  const onChangeAllAgree = async (checked: boolean) => {
    await onChangeAgree("isAgeAgree", checked);
    await onChangeAgree("isServiceAgree", checked);
    await onChangeAgree("isPrivateAgree", checked);
    await onChangeAgree("marketingOptIn", checked);
  };

  const signUpMutation = useAuthControllerSignUp({
    mutation: {
      onSuccess: async ({ accessToken }) => {
        const result = await signIn("credentials", {
          accessToken,
          redirect: false,
        });

        if (result?.status === 200) {
          router.push(MENU.SIGNUP_SUCCESS.link.url);
        }
      },
      onError: (e) => {
        const data = e.response?.data;
        if (data) {
          const { errorCode } = data;
          let errorMsg;
          switch (errorCode) {
            case 100006:
              errorMsg = "이미 가입된 이메일 입니다.";
              break;
            default:
              errorMsg = `[CODE: ${errorCode}] 회원가입에 실패하였습니다. 고객센터에 문의해주세요`;
          }
          openToast(TOAST_TYPE.ERROR, errorMsg);
        }
      },
    },
  });

  const onSubmit: SubmitHandler<SignUpForm> = async (data) => {
    const authType = socialType ? "SOCIAL" : "EMAIL";
    const snsType = socialType
      ? (socialType as SignUpRequestDtoSocialType)
      : null;

    signUpMutation.mutate({
      data: {
        // ...data,
        name: data.name,
        password: data.password,
        email: data.email,
        marketingOptIn: data.marketingOptIn,
        phoneNumber: data.phoneNumber.replaceAll("-", ""),
        authType: authType,
        ...(socialId ? { socialId } : {}),
        ...(snsType ? { socialType: snsType } : {}),
        verificationCode: data.verificationCode,
      },
    });
  };

  const onError: SubmitErrorHandler<SignUpForm> = async (error) => {
    console.error(error);
  };

  return (
    <SignUpTemplateContainer>
      <SignUpTemplateInnerContainer>
        <SignUpCardContainer>
          <SignUpTitleContainer>회원가입</SignUpTitleContainer>
          <form onSubmit={handleSubmit(onSubmit, onError)}>
            <SignUpFormContainer>
              <SignUpFormInputContainer>
                <SignUpFormTextField
                  type="text"
                  label={"이름(실명)"}
                  placeholder={"이름(실명)을 입력해 주세요."}
                  {...register("name")}
                  isError={!!errors.name}
                  message={errors?.name?.message}
                  maxLength={40}
                />

                <SignUpFormTextField
                  type="text"
                  label={"이메일"}
                  placeholder={"이메일을 입력해 주세요."}
                  {...register("email")}
                  // disabled={!!user}
                  isError={!!errors.email}
                  message={errors?.email?.message}
                  maxLength={50}
                />

                {!isProviderUser && (
                  <>
                    <SignUpFormTextField
                      type={isShowPassword ? "text" : "password"}
                      label={"비밀번호"}
                      placeholder={"특수문자, 숫자, 영문자 조합된 8 이상 문자"}
                      {...register("password")}
                      maxLength={50}
                      isError={!!errors.password}
                      message={errors?.password?.message}
                      endAdornment={
                        <a onClick={onShowPassword}>
                          <Icon
                            icon={
                              isShowPassword
                                ? ICON_SVG.LINE.SIZE_18.ICON_EYE
                                : ICON_SVG.LINE.SIZE_18.ICON_EYE_OFF
                            }
                          />
                        </a>
                      }
                    />

                    <SignUpFormTextField
                      type={isShowMatchPassword ? "text" : "password"}
                      label={"비밀번호 확인"}
                      placeholder={"동일한 비밀번호를 다시 입력해 주세요."}
                      {...register("matchNewPassword")}
                      maxLength={50}
                      isError={!!errors.matchNewPassword}
                      message={errors?.matchNewPassword?.message}
                      endAdornment={
                        <a onClick={onShowMatchPassword}>
                          <Icon
                            icon={
                              isShowMatchPassword
                                ? ICON_SVG.LINE.SIZE_18.ICON_EYE
                                : ICON_SVG.LINE.SIZE_18.ICON_EYE_OFF
                            }
                          />
                        </a>
                      }
                    />
                  </>
                )}

                <ButtonTextFieldContainer>
                  <SignUpFormTextField
                    type="text"
                    label={"휴대전화"}
                    placeholder={"휴대전화"}
                    isError={!!errors.phoneNumber}
                    message={errors?.phoneNumber?.message}
                    onChange={onChangePhoneNumber}
                    onKeyDown={onKeyDownPhoneNumber}
                    value={phoneNumber}
                    maxLength={13}
                    disabled={!isVerifyNumberDisabled}
                    endActionButton={
                      <FormButton
                        type="button"
                        styleType={BUTTON_TYPE.FILL}
                        // errors.phoneNumber.type !==
                        // PostKacAuthVerifyNumberSend400Code.E02) ||
                        disabled={
                          (!!errors.phoneNumber && !phoneNumber) ||
                          !isVerifyNumberDisabled
                        }
                        onClick={onSendVerifyNumber}
                      >
                        {verifyActionCount > 0 ? "재요청" : "인증번호 요청"}
                      </FormButton>
                    }
                  />
                </ButtonTextFieldContainer>

                <ButtonTextFieldContainer>
                  <SignUpFormTextField
                    type="text"
                    label={"인증번호"}
                    placeholder={"인증번호"}
                    isError={!!errors.verificationCode}
                    message={errors?.verificationCode?.message}
                    {...register("verificationCode")}
                    onKeyDown={onKeyDownVerifyNumber}
                    maxLength={6}
                    disabled={
                      isVerifyNumberDisabled ||
                      isSuccessVerify ||
                      sendVerifyNumberMutation.isPending
                    }
                    endAdornment={
                      !isSuccessVerify &&
                      !isVerifyNumberDisabled && (
                        <VerifyTimerSpan onEnd={onVerifyNumberEndTime} />
                      )
                    }
                    endActionButton={
                      <FormButton
                        type="button"
                        styleType={BUTTON_TYPE.FILL}
                        disabled={
                          verificationCode?.length < 4 ||
                          isVerifyNumberDisabled ||
                          (!!errors.verificationCode &&
                            errors.verificationCode.type !== "SUCCESS") ||
                          isSuccessVerify ||
                          verifyNumberCheckMutation.isPending ||
                          !verificationCode
                        }
                        onClick={onVerifyNumberCheck}
                      >
                        {isSuccessVerify ? "인증완료" : "인증번호 확인"}
                      </FormButton>
                    }
                  />
                </ButtonTextFieldContainer>
              </SignUpFormInputContainer>

              <AgreeContainer>
                <FormCheckBoxContainer className="all-agree">
                  <CheckBox
                    checked={
                      isAgeAgree &&
                      isServiceAgree &&
                      isPrivateAgree &&
                      marketingOptIn
                    }
                    onChange={(e) => onChangeAllAgree(e.target.checked)}
                  />
                  <span
                    onClick={() => {
                      onChangeAllAgree(
                        !(
                          isAgeAgree &&
                          isServiceAgree &&
                          isPrivateAgree &&
                          marketingOptIn
                        ),
                      );
                    }}
                  >
                    모두 동의
                  </span>
                </FormCheckBoxContainer>

                <Divider />

                <ChoiceAgreeContainer>
                  <FormCheckBoxContainer className="choice-agree">
                    <CheckBox
                      isError={!!errors.isAgeAgree}
                      checked={isAgeAgree}
                      onChange={(e) => {
                        onChangeAgree("isAgeAgree", !isAgeAgree);
                      }}
                    />
                    <span
                      onClick={() => onChangeAgree("isAgeAgree", !isAgeAgree)}
                    >
                      만 14세 이상입니다 (필수)
                    </span>
                  </FormCheckBoxContainer>
                  <FormCheckBoxContainer className="choice-agree">
                    <CheckBox
                      isError={!!errors.isServiceAgree}
                      checked={isServiceAgree}
                      onChange={(e) => {
                        onChangeAgree("isServiceAgree", !isServiceAgree);
                      }}
                    />
                    <span
                      onClick={() =>
                        onChangeAgree("isServiceAgree", !isServiceAgree)
                      }
                    >
                      이용약관 동의 (필수)
                    </span>
                    <Link
                      href={""}
                      target={"_blank"}
                      passHref
                    >
                      보기
                    </Link>
                  </FormCheckBoxContainer>
                  <FormCheckBoxContainer className="choice-agree">
                    <CheckBox
                      isError={!!errors.isPrivateAgree}
                      checked={isPrivateAgree}
                      onChange={(e) => {
                        onChangeAgree("isPrivateAgree", !isPrivateAgree);
                      }}
                    />
                    <span
                      onClick={() =>
                        onChangeAgree("isPrivateAgree", !isPrivateAgree)
                      }
                    >
                      개인정보 수집 및 이용 동의 (필수)
                    </span>
                    <Link
                      href={""}
                      target={"_blank"}
                      passHref
                    >
                      보기
                    </Link>
                  </FormCheckBoxContainer>
                  <FormCheckBoxContainer className="choice-agree">
                    <CheckBox
                      isError={!!errors.marketingOptIn}
                      checked={marketingOptIn}
                      onChange={(e) => {
                        onChangeAgree("marketingOptIn", !marketingOptIn);
                      }}
                    />
                    <span
                      onClick={() =>
                        onChangeAgree("marketingOptIn", !marketingOptIn)
                      }
                    >
                      마케팅 정보 수신 동의 (선택)
                    </span>
                    <Link
                      href={""}
                      target={"_blank"}
                      passHref
                    >
                      보기
                    </Link>
                  </FormCheckBoxContainer>
                </ChoiceAgreeContainer>
              </AgreeContainer>

              <SignUpButtonContainer>
                <SignUpButton
                  styleType={BUTTON_TYPE.FILL}
                  type={"submit"}
                  disabled={!isValid || signUpMutation.isPending}
                >
                  회원가입
                </SignUpButton>
              </SignUpButtonContainer>
            </SignUpFormContainer>
          </form>
          <RegisteredUserLoginContainer>
            이미 계정이 있으신가요?{" "}
            <Link
              href={MENU.LOGIN.link.url}
              passHref
            >
              로그인하기
            </Link>
          </RegisteredUserLoginContainer>
        </SignUpCardContainer>
      </SignUpTemplateInnerContainer>
    </SignUpTemplateContainer>
  );
};

const SignUpTemplateContainer = styled.div`
  width: 100%;
  height: 100%;
  min-height: 50vh;

  display: flex;
  align-items: center;
  justify-content: center;
`;

const SignUpTemplateInnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  padding: 96px 24px;
  width: 100%;
  max-width: ${MAX_WIDTH.INNER_CONTAINER.PC}px;

  ${({ theme }) => theme.media.mobile} {
    padding: 96px 16px;
  }
`;

const SignUpCardContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 365px;

  gap: 40px;

  ${({ theme }) => theme.media.mobile} {
    width: 100%;
    max-width: 343px;
  }
`;

const SignUpTitleContainer = styled.div`
  ${({ theme }) => theme.typography.h1.b};
  color: ${({ theme }) => theme.colors.text.high.black};

  text-align: center;
`;

const SignUpFormContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;
`;

const SignUpFormInputContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const SignUpFormTextField = styled(TextField)`
  gap: 8px;
  flex: 1 0 0;
  letter-spacing: -0.4px;
`;

const ButtonTextFieldContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

const FormButton = styled(Button)`
  display: flex;
  padding: 14px 16px;
  align-self: flex-end;
  justify-content: center;
  align-items: center;

  min-width: 117px;

  &.btn-${BUTTON_TYPE.FILL.toLowerCase()} {
    border-radius: 4px;
  }
`;

const VerifyTimerSpan = styled(TimerSpan)`
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 150% */
  letter-spacing: 0.5px;

  color: ${({ theme }) => theme.colors.state.error.default};
`;

const AgreeContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const ChoiceAgreeContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const FormCheckBoxContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;

  &.all-agree {
    span {
      cursor: pointer;
      font-size: 14px;
      font-style: normal;

      line-height: 100%; /* 14px */
      letter-spacing: 0.5px;
      font-weight: 700;
      color: ${({ theme }) => theme.colors.text.high.black};
    }
  }

  &.choice-agree {
    margin-top: 8px;

    &:first-of-type {
      margin-top: 0;
    }

    span {
      cursor: pointer;
      font-size: 14px;
      font-style: normal;

      line-height: 100%; /* 14px */
      letter-spacing: 0.5px;
      font-weight: 500;
      color: ${({ theme }) => theme.colors.text.medium.black};
    }
  }

  a {
    margin-left: auto;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 100%; /* 14px */
    letter-spacing: 0.5px;
    text-decoration-line: underline;
    color: ${({ theme }) => theme.colors.text.medium.black};
  }
`;

const FinalAgreeDescription = styled.div`
  display: flex;
  flex-direction: column;

  margin-left: 32px;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 145%; /* 20.3px */
  letter-spacing: 0.5px;
  color: ${({ theme }) => theme.colors.text.medium.black};
  white-space: pre-line;

  b {
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 145%; /* 20.3px */
    letter-spacing: 0.5px;
    color: ${({ theme }) => theme.colors.text.high.black};
  }
`;

const Divider = styled.div`
  width: 100%;
  height: 1px;
  background: ${({ theme }) => theme.colors.gray.gray90};
`;

const SignUpButtonContainer = styled.div``;

const SignUpButton = styled(Button)`
  width: 100%;

  &.btn-fill {
    border-radius: 4px;
  }
`;

const RegisteredUserLoginContainer = styled.div`
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 100%;
  letter-spacing: 0.5px;
  color: ${({ theme }) => theme.colors.text.medium.black};
  a {
    color: ${({ theme }) => theme.colors.text.high.black};
  }
`;

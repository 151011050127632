/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * KAC-API
 * K-Artist Class API
 * OpenAPI spec version: 0.0.1
 */

/**
 * Course Cycle의 현재 상태
 */
export type CycleResponseDtoStatus =
  (typeof CycleResponseDtoStatus)[keyof typeof CycleResponseDtoStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CycleResponseDtoStatus = {
  PLANNED: "PLANNED",
  ONGOING: "ONGOING",
  COMPLETED: "COMPLETED",
  CANCELED: "CANCELED",
} as const;

import { create } from "zustand";

interface ModalState {
  modals: Record<string, boolean>;
  openModal: (key: string) => void;
  closeModal: (key: string) => void;
  isModalOpen: (key: string) => boolean;
}

export const useModalStore = create<ModalState>((set, get) => ({
  modals: {},

  openModal: (key) => {
    set((state) => ({
      modals: { ...state.modals, [key]: true },
    }));
  },

  closeModal: (key) => {
    set((state) => ({
      modals: { ...state.modals, [key]: false },
    }));
  },

  isModalOpen: (key) => {
    return get().modals[key] || false;
  },

  resetModals: () => {
    set({ modals: {} });
  },
}));

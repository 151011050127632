import styled from "@emotion/styled";

import { Button } from "@shared/lib/components/common/atom/button";
import { Icon } from "@shared/lib/components/common/atom/icon";
import { Modal } from "@shared/lib/components/common/molecules/modal/index";
import { ICON_SVG } from "@shared/lib/constants/import/icon-svg";
import { BUTTON_TYPE } from "@shared/lib/styles/theme";

/**
 * 알랏과 모달은 다른개념이지만
 * 알림을 주는 모달이기에 알랏모달이라고 정함
 */
export const ALERT_MODAL_TYPE = {
  INFO: "INFO",
  WARNING: "WARNING",
  ERROR: "ERROR",
  SUCCESS: "SUCCESS",
} as const;
export type AlertModalType = keyof typeof ALERT_MODAL_TYPE;

export interface AlertModalProps {
  type: AlertModalType;
  isOpen: boolean;
  disabledOutsideClose?: boolean;
  onClose: () => void;
  title?: string;
  subTitle?: string;
  confirmText?: string;
  onConfirm?: () => void;
}

export const AlertModal = ({
  type,
  isOpen,
  disabledOutsideClose,
  onClose,
  title,
  subTitle,
  confirmText = "확인",
  onConfirm,
}: AlertModalProps) => {
  const confirm = () => {
    onClose();
    onConfirm && onConfirm();
  };

  return (
    <Modal
      disabledOutsideClose={disabledOutsideClose}
      isOpen={isOpen}
      onClose={onClose}
    >
      <AlertModalContainer>
        <IconContainer>
          {type === ALERT_MODAL_TYPE.INFO && (
            <Icon icon={ICON_SVG.GRAPHIC.MODAL.ICON_INFO} />
          )}
          {type === ALERT_MODAL_TYPE.WARNING && (
            <Icon icon={ICON_SVG.GRAPHIC.MODAL.ICON_WARNING} />
          )}
          {type === ALERT_MODAL_TYPE.SUCCESS && (
            <Icon icon={ICON_SVG.GRAPHIC.MODAL.ICON_CHECK} />
          )}
          {type === ALERT_MODAL_TYPE.ERROR && (
            <Icon icon={ICON_SVG.GRAPHIC.MODAL.ICON_ERROR} />
          )}
        </IconContainer>
        <TitleContainer>
          {title && <Title>{title}</Title>}

          {subTitle && <SubTitle>{subTitle}</SubTitle>}
        </TitleContainer>
        <ConfirmButtonContainer>
          <ConfirmButton
            styleType={BUTTON_TYPE.FILL}
            onClick={confirm}
          >
            {confirmText}
          </ConfirmButton>
        </ConfirmButtonContainer>
      </AlertModalContainer>
    </Modal>
  );
};

const AlertModalContainer = styled.div`
  min-width: 404px;
  padding: 32px;
  display: flex;
  flex-direction: column;
  gap: 16px;

  ${({ theme }) => theme.media.mobile} {
    width: 100%;
    max-width: 343px;
    min-width: auto;
  }
`;

const IconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 4px;
`;

//TODO: 컬러값이 디자인시스템에 없음 추가 될시 변경해야됨
const Title = styled.div`
  text-align: center;
  ${({ theme }) => theme.typography.h3.b};
  color: #000;
  white-space: pre-line;
`;

const SubTitle = styled.div`
  text-align: center;
  ${({ theme }) => theme.typography.body2.m};
  color: ${({ theme }) => theme.colors.text.medium.black};
  white-space: pre-line;
`;

const ConfirmButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ConfirmButton = styled(Button)`
  min-width: 180px;

  &.btn-${BUTTON_TYPE.FILL.toLowerCase()} {
    border-radius: 8px;
  }
`;

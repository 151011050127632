import { useRouter } from "next/router";
import styled from "@emotion/styled";

import { Button } from "@shared/lib/components/common/atom/button";
import { useMediaQueryMaxWidth } from "@shared/lib/hooks/use-media-query-max-width";
import { BUTTON_TYPE } from "@shared/lib/styles/theme";
import { MENU } from "@/constants/menu";
import { MAX_WIDTH } from "@/constants/style/layout";

export const StudentReviewSection = () => {
  const { isTabletSm } = useMediaQueryMaxWidth();

  const router = useRouter();
  const onApply = () => {
    router.push(MENU.TEACHER_APPLY.link.url);
  };

  return (
    <StyledStudentReviewSection>
      <StudentReviewSectionInnerContainer>
        <StudentReviewSectionTitleContainer>
          {`수강생들은 이런 이유로\n케아클을 선택합니다.`}
        </StudentReviewSectionTitleContainer>
        <StudentReviewRow>
          <StudentReviewItem>
            <img
              src={
                isTabletSm
                  ? `/images/teacher/support/student-m.png`
                  : `/images/teacher/support/student.png`
              }
              alt={"학생 후기"}
            />
          </StudentReviewItem>
          <StudentReviewItem>
            <img
              src={
                isTabletSm
                  ? `/images/teacher/support/student1-m.png`
                  : `/images/teacher/support/student1.png`
              }
              alt={"학생1 후기"}
            />
          </StudentReviewItem>
          <StudentReviewItem>
            <img
              src={
                isTabletSm
                  ? `/images/teacher/support/student2-m.png`
                  : `/images/teacher/support/student2.png`
              }
              alt={"학생2 후기"}
            />
          </StudentReviewItem>
        </StudentReviewRow>

        <StudentReviewSectionApplyButton
          styleType={BUTTON_TYPE.FILL}
          onClick={onApply}
        >
          강사 등록하기
        </StudentReviewSectionApplyButton>
      </StudentReviewSectionInnerContainer>
    </StyledStudentReviewSection>
  );
};

const StyledStudentReviewSection = styled.div`
  padding: 80px 0;
  display: flex;
  align-items: center;
  justify-content: center;

  background-color: ${({ theme }) => theme.colors.gray.gray99};
`;

const StudentReviewSectionInnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: 56px;

  padding: 0 16px;
  width: 100%;
  max-width: ${MAX_WIDTH.INNER_CONTAINER.PC}px;
`;

const StudentReviewSectionTitleContainer = styled.div`
  text-align: center;
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  line-height: 125%; /* 37.5px */
  letter-spacing: -0.4px;

  color: ${({ theme }) => theme.colors.text.high.black};

  ${({ theme }) => theme.media.tabletSm} {
    text-align: center;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 125%; /* 30px */
    letter-spacing: -0.4px;

    white-space: pre-line;
  }
`;

const StudentReviewRow = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;

  ${({ theme }) => theme.media.tablet} {
    flex-direction: column;
  }

  ${({ theme }) => theme.media.tabletSm} {
    width: 100%;
  }
`;

const StudentReviewItem = styled.div`
  border-radius: 8px;
  box-shadow: 4px 4px 24px 0 rgba(0, 0, 0, 0.15);

  img {
    width: 100%;
    max-width: 285px;
  }

  ${({ theme }) => theme.media.tabletSm} {
    width: 100%;

    img {
      max-width: 100%;
    }
  }
`;

const StudentReviewSectionApplyButton = styled(Button)`
  &.btn-${BUTTON_TYPE.FILL.toLowerCase()} {
    border-radius: 8px;
    min-width: 300px;
  }

  ${({ theme }) => theme.media.tabletSm} {
    width: 100%;
  }
`;

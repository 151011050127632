/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * KAC-API
 * K-Artist Class API
 * OpenAPI spec version: 0.0.1
 */
import { useMutation, useQuery } from "@tanstack/react-query";
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult,
} from "@tanstack/react-query";
import type {
  CourseControllerCourseParams,
  CourseControllerCoursesParams,
  CourseControllerGetCategoriesParams,
  CourseControllerGetSectionsGlobalParams,
  CourseControllerGetSectionsParams,
  CourseInDetailResponseDto,
  CreateCourseSessionsRequestDto,
  CreateScheduleRequestDto,
  LiveCourseResponseDto,
  PaginatedCoursesResponseDto,
  ScheduleResponseDto,
  SectionGlobalResponseDto,
  SectionResponseDto,
  UpdateCourseRequestDto,
  UpdateCourseSessionRequestDto,
  UpdateCycleRequestDto,
} from "../../../model";
import { axiosInstance } from "../../../config/axios-instance";
import type { ErrorType } from "../../../config/axios-instance";

type AwaitedInput<T> = PromiseLike<T> | T;

type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];

/**
 * 강의 정보를 수정한다. (mobileContent: null로 수정 가능)
 * @summary 강의 정보 수정
 */
export const courseControllerUpdateCourse = (
  id: number,
  updateCourseRequestDto: UpdateCourseRequestDto,
  options?: SecondParameter<typeof axiosInstance>,
) => {
  return axiosInstance<CourseInDetailResponseDto>(
    {
      url: `/course/${id}`,
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      data: updateCourseRequestDto,
    },
    options,
  );
};

export const getCourseControllerUpdateCourseMutationOptions = <
  TError = ErrorType<CourseInDetailResponseDto>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof courseControllerUpdateCourse>>,
    TError,
    { id: number; data: UpdateCourseRequestDto },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof courseControllerUpdateCourse>>,
  TError,
  { id: number; data: UpdateCourseRequestDto },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof courseControllerUpdateCourse>>,
    { id: number; data: UpdateCourseRequestDto }
  > = (props) => {
    const { id, data } = props ?? {};

    return courseControllerUpdateCourse(id, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type CourseControllerUpdateCourseMutationResult = NonNullable<
  Awaited<ReturnType<typeof courseControllerUpdateCourse>>
>;
export type CourseControllerUpdateCourseMutationBody = UpdateCourseRequestDto;
export type CourseControllerUpdateCourseMutationError =
  ErrorType<CourseInDetailResponseDto>;

/**
 * @summary 강의 정보 수정
 */
export const useCourseControllerUpdateCourse = <
  TError = ErrorType<CourseInDetailResponseDto>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof courseControllerUpdateCourse>>,
    TError,
    { id: number; data: UpdateCourseRequestDto },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof courseControllerUpdateCourse>>,
  TError,
  { id: number; data: UpdateCourseRequestDto },
  TContext
> => {
  const mutationOptions =
    getCourseControllerUpdateCourseMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary 강의 정보 상세 조회 (* 공개)
 */
export const courseControllerCourse = (
  id: number,
  params?: CourseControllerCourseParams,
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal,
) => {
  return axiosInstance<CourseInDetailResponseDto>(
    { url: `/course/${id}`, method: "GET", params, signal },
    options,
  );
};

export const getCourseControllerCourseQueryKey = (
  id: number,
  params?: CourseControllerCourseParams,
) => {
  return [`/course/${id}`, ...(params ? [params] : [])] as const;
};

export const getCourseControllerCourseQueryOptions = <
  TData = Awaited<ReturnType<typeof courseControllerCourse>>,
  TError = ErrorType<CourseInDetailResponseDto>,
>(
  id: number,
  params?: CourseControllerCourseParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof courseControllerCourse>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getCourseControllerCourseQueryKey(id, params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof courseControllerCourse>>
  > = ({ signal }) =>
    courseControllerCourse(id, params, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!id,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof courseControllerCourse>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type CourseControllerCourseQueryResult = NonNullable<
  Awaited<ReturnType<typeof courseControllerCourse>>
>;
export type CourseControllerCourseQueryError =
  ErrorType<CourseInDetailResponseDto>;

/**
 * @summary 강의 정보 상세 조회 (* 공개)
 */
export const useCourseControllerCourse = <
  TData = Awaited<ReturnType<typeof courseControllerCourse>>,
  TError = ErrorType<CourseInDetailResponseDto>,
>(
  id: number,
  params?: CourseControllerCourseParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof courseControllerCourse>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getCourseControllerCourseQueryOptions(
    id,
    params,
    options,
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * 검색 조건을 이용해 강의 정보를 조회한다.
 * @summary 강의 정보 조회 (* 공개)
 */
export const courseControllerCourses = (
  params?: CourseControllerCoursesParams,
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal,
) => {
  return axiosInstance<PaginatedCoursesResponseDto>(
    { url: `/course`, method: "GET", params, signal },
    options,
  );
};

export const getCourseControllerCoursesQueryKey = (
  params?: CourseControllerCoursesParams,
) => {
  return [`/course`, ...(params ? [params] : [])] as const;
};

export const getCourseControllerCoursesQueryOptions = <
  TData = Awaited<ReturnType<typeof courseControllerCourses>>,
  TError = ErrorType<PaginatedCoursesResponseDto>,
>(
  params?: CourseControllerCoursesParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof courseControllerCourses>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getCourseControllerCoursesQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof courseControllerCourses>>
  > = ({ signal }) => courseControllerCourses(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof courseControllerCourses>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type CourseControllerCoursesQueryResult = NonNullable<
  Awaited<ReturnType<typeof courseControllerCourses>>
>;
export type CourseControllerCoursesQueryError =
  ErrorType<PaginatedCoursesResponseDto>;

/**
 * @summary 강의 정보 조회 (* 공개)
 */
export const useCourseControllerCourses = <
  TData = Awaited<ReturnType<typeof courseControllerCourses>>,
  TError = ErrorType<PaginatedCoursesResponseDto>,
>(
  params?: CourseControllerCoursesParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof courseControllerCourses>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getCourseControllerCoursesQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary 강의 섹션 (1차 분류) 조회
 */
export const courseControllerGetSections = (
  params?: CourseControllerGetSectionsParams,
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal,
) => {
  return axiosInstance<SectionResponseDto[]>(
    { url: `/course/section`, method: "GET", params, signal },
    options,
  );
};

export const getCourseControllerGetSectionsQueryKey = (
  params?: CourseControllerGetSectionsParams,
) => {
  return [`/course/section`, ...(params ? [params] : [])] as const;
};

export const getCourseControllerGetSectionsQueryOptions = <
  TData = Awaited<ReturnType<typeof courseControllerGetSections>>,
  TError = ErrorType<SectionResponseDto[]>,
>(
  params?: CourseControllerGetSectionsParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof courseControllerGetSections>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getCourseControllerGetSectionsQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof courseControllerGetSections>>
  > = ({ signal }) =>
    courseControllerGetSections(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof courseControllerGetSections>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type CourseControllerGetSectionsQueryResult = NonNullable<
  Awaited<ReturnType<typeof courseControllerGetSections>>
>;
export type CourseControllerGetSectionsQueryError = ErrorType<
  SectionResponseDto[]
>;

/**
 * @summary 강의 섹션 (1차 분류) 조회
 */
export const useCourseControllerGetSections = <
  TData = Awaited<ReturnType<typeof courseControllerGetSections>>,
  TError = ErrorType<SectionResponseDto[]>,
>(
  params?: CourseControllerGetSectionsParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof courseControllerGetSections>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getCourseControllerGetSectionsQueryOptions(
    params,
    options,
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary 강의 섹션 (1차 분류) 조회 - GLOBAL 용
 */
export const courseControllerGetSectionsGlobal = (
  lang: "ko" | "en",
  params?: CourseControllerGetSectionsGlobalParams,
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal,
) => {
  return axiosInstance<SectionGlobalResponseDto[]>(
    { url: `/course/${lang}/section`, method: "GET", params, signal },
    options,
  );
};

export const getCourseControllerGetSectionsGlobalQueryKey = (
  lang: "ko" | "en",
  params?: CourseControllerGetSectionsGlobalParams,
) => {
  return [`/course/${lang}/section`, ...(params ? [params] : [])] as const;
};

export const getCourseControllerGetSectionsGlobalQueryOptions = <
  TData = Awaited<ReturnType<typeof courseControllerGetSectionsGlobal>>,
  TError = ErrorType<SectionGlobalResponseDto[]>,
>(
  lang: "ko" | "en",
  params?: CourseControllerGetSectionsGlobalParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof courseControllerGetSectionsGlobal>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getCourseControllerGetSectionsGlobalQueryKey(lang, params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof courseControllerGetSectionsGlobal>>
  > = ({ signal }) =>
    courseControllerGetSectionsGlobal(lang, params, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!lang,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof courseControllerGetSectionsGlobal>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type CourseControllerGetSectionsGlobalQueryResult = NonNullable<
  Awaited<ReturnType<typeof courseControllerGetSectionsGlobal>>
>;
export type CourseControllerGetSectionsGlobalQueryError = ErrorType<
  SectionGlobalResponseDto[]
>;

/**
 * @summary 강의 섹션 (1차 분류) 조회 - GLOBAL 용
 */
export const useCourseControllerGetSectionsGlobal = <
  TData = Awaited<ReturnType<typeof courseControllerGetSectionsGlobal>>,
  TError = ErrorType<SectionGlobalResponseDto[]>,
>(
  lang: "ko" | "en",
  params?: CourseControllerGetSectionsGlobalParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof courseControllerGetSectionsGlobal>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getCourseControllerGetSectionsGlobalQueryOptions(
    lang,
    params,
    options,
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary 강의 카테고리(2차 분류) 조회 (+ 1차 분류(섹션) 병합)
 */
export const courseControllerGetCategories = (
  params?: CourseControllerGetCategoriesParams,
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal,
) => {
  return axiosInstance<unknown>(
    { url: `/course/category`, method: "GET", params, signal },
    options,
  );
};

export const getCourseControllerGetCategoriesQueryKey = (
  params?: CourseControllerGetCategoriesParams,
) => {
  return [`/course/category`, ...(params ? [params] : [])] as const;
};

export const getCourseControllerGetCategoriesQueryOptions = <
  TData = Awaited<ReturnType<typeof courseControllerGetCategories>>,
  TError = ErrorType<unknown>,
>(
  params?: CourseControllerGetCategoriesParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof courseControllerGetCategories>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getCourseControllerGetCategoriesQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof courseControllerGetCategories>>
  > = ({ signal }) =>
    courseControllerGetCategories(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof courseControllerGetCategories>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type CourseControllerGetCategoriesQueryResult = NonNullable<
  Awaited<ReturnType<typeof courseControllerGetCategories>>
>;
export type CourseControllerGetCategoriesQueryError = ErrorType<unknown>;

/**
 * @summary 강의 카테고리(2차 분류) 조회 (+ 1차 분류(섹션) 병합)
 */
export const useCourseControllerGetCategories = <
  TData = Awaited<ReturnType<typeof courseControllerGetCategories>>,
  TError = ErrorType<unknown>,
>(
  params?: CourseControllerGetCategoriesParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof courseControllerGetCategories>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getCourseControllerGetCategoriesQueryOptions(
    params,
    options,
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * 강의 수정, (없으면) 강의 사이클 등록, 사이클에 바인딩된 세션 정보 등록
 * @summary 세션 정보 등록 + 강의 수정
 */
export const courseControllerCreateSessions = (
  id: number,
  createCourseSessionsRequestDto: CreateCourseSessionsRequestDto,
  options?: SecondParameter<typeof axiosInstance>,
) => {
  return axiosInstance<string>(
    {
      url: `/course/${id}/session`,
      method: "POST",
      headers: { "Content-Type": "application/json" },
      data: createCourseSessionsRequestDto,
    },
    options,
  );
};

export const getCourseControllerCreateSessionsMutationOptions = <
  TError = ErrorType<string>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof courseControllerCreateSessions>>,
    TError,
    { id: number; data: CreateCourseSessionsRequestDto },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof courseControllerCreateSessions>>,
  TError,
  { id: number; data: CreateCourseSessionsRequestDto },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof courseControllerCreateSessions>>,
    { id: number; data: CreateCourseSessionsRequestDto }
  > = (props) => {
    const { id, data } = props ?? {};

    return courseControllerCreateSessions(id, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type CourseControllerCreateSessionsMutationResult = NonNullable<
  Awaited<ReturnType<typeof courseControllerCreateSessions>>
>;
export type CourseControllerCreateSessionsMutationBody =
  CreateCourseSessionsRequestDto;
export type CourseControllerCreateSessionsMutationError = ErrorType<string>;

/**
 * @summary 세션 정보 등록 + 강의 수정
 */
export const useCourseControllerCreateSessions = <
  TError = ErrorType<string>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof courseControllerCreateSessions>>,
    TError,
    { id: number; data: CreateCourseSessionsRequestDto },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof courseControllerCreateSessions>>,
  TError,
  { id: number; data: CreateCourseSessionsRequestDto },
  TContext
> => {
  const mutationOptions =
    getCourseControllerCreateSessionsMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * 강의에 속한 세션 정보 only
 * @summary 세션 정보 bulk 업데이트
 */
export const courseControllerUpdateSessions = (
  id: number,
  updateCourseSessionRequestDto: UpdateCourseSessionRequestDto[],
  options?: SecondParameter<typeof axiosInstance>,
) => {
  return axiosInstance<void>(
    {
      url: `/course/${id}/session`,
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      data: updateCourseSessionRequestDto,
    },
    options,
  );
};

export const getCourseControllerUpdateSessionsMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof courseControllerUpdateSessions>>,
    TError,
    { id: number; data: UpdateCourseSessionRequestDto[] },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof courseControllerUpdateSessions>>,
  TError,
  { id: number; data: UpdateCourseSessionRequestDto[] },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof courseControllerUpdateSessions>>,
    { id: number; data: UpdateCourseSessionRequestDto[] }
  > = (props) => {
    const { id, data } = props ?? {};

    return courseControllerUpdateSessions(id, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type CourseControllerUpdateSessionsMutationResult = NonNullable<
  Awaited<ReturnType<typeof courseControllerUpdateSessions>>
>;
export type CourseControllerUpdateSessionsMutationBody =
  UpdateCourseSessionRequestDto[];
export type CourseControllerUpdateSessionsMutationError = ErrorType<unknown>;

/**
 * @summary 세션 정보 bulk 업데이트
 */
export const useCourseControllerUpdateSessions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof courseControllerUpdateSessions>>,
    TError,
    { id: number; data: UpdateCourseSessionRequestDto[] },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof courseControllerUpdateSessions>>,
  TError,
  { id: number; data: UpdateCourseSessionRequestDto[] },
  TContext
> => {
  const mutationOptions =
    getCourseControllerUpdateSessionsMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary 강의 스케쥴 bulk 등록
 */
export const courseControllerCreateSchedules = (
  courseId: number,
  createScheduleRequestDto: CreateScheduleRequestDto[],
  options?: SecondParameter<typeof axiosInstance>,
) => {
  return axiosInstance<string>(
    {
      url: `/course/${courseId}/schedule`,
      method: "POST",
      headers: { "Content-Type": "application/json" },
      data: createScheduleRequestDto,
    },
    options,
  );
};

export const getCourseControllerCreateSchedulesMutationOptions = <
  TError = ErrorType<string>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof courseControllerCreateSchedules>>,
    TError,
    { courseId: number; data: CreateScheduleRequestDto[] },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof courseControllerCreateSchedules>>,
  TError,
  { courseId: number; data: CreateScheduleRequestDto[] },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof courseControllerCreateSchedules>>,
    { courseId: number; data: CreateScheduleRequestDto[] }
  > = (props) => {
    const { courseId, data } = props ?? {};

    return courseControllerCreateSchedules(courseId, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type CourseControllerCreateSchedulesMutationResult = NonNullable<
  Awaited<ReturnType<typeof courseControllerCreateSchedules>>
>;
export type CourseControllerCreateSchedulesMutationBody =
  CreateScheduleRequestDto[];
export type CourseControllerCreateSchedulesMutationError = ErrorType<string>;

/**
 * @summary 강의 스케쥴 bulk 등록
 */
export const useCourseControllerCreateSchedules = <
  TError = ErrorType<string>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof courseControllerCreateSchedules>>,
    TError,
    { courseId: number; data: CreateScheduleRequestDto[] },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof courseControllerCreateSchedules>>,
  TError,
  { courseId: number; data: CreateScheduleRequestDto[] },
  TContext
> => {
  const mutationOptions =
    getCourseControllerCreateSchedulesMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary 강의 스케쥴 > 라이브 수업 입장 (강사)
 */
export const courseControllerEnterLiveClass = (
  courseId: number,
  scheduleId: number,
  options?: SecondParameter<typeof axiosInstance>,
) => {
  return axiosInstance<LiveCourseResponseDto>(
    {
      url: `/course/${courseId}/schedule/${scheduleId}/enter-live-class/instructor`,
      method: "POST",
    },
    options,
  );
};

export const getCourseControllerEnterLiveClassMutationOptions = <
  TError = ErrorType<LiveCourseResponseDto>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof courseControllerEnterLiveClass>>,
    TError,
    { courseId: number; scheduleId: number },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof courseControllerEnterLiveClass>>,
  TError,
  { courseId: number; scheduleId: number },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof courseControllerEnterLiveClass>>,
    { courseId: number; scheduleId: number }
  > = (props) => {
    const { courseId, scheduleId } = props ?? {};

    return courseControllerEnterLiveClass(courseId, scheduleId, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type CourseControllerEnterLiveClassMutationResult = NonNullable<
  Awaited<ReturnType<typeof courseControllerEnterLiveClass>>
>;

export type CourseControllerEnterLiveClassMutationError =
  ErrorType<LiveCourseResponseDto>;

/**
 * @summary 강의 스케쥴 > 라이브 수업 입장 (강사)
 */
export const useCourseControllerEnterLiveClass = <
  TError = ErrorType<LiveCourseResponseDto>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof courseControllerEnterLiveClass>>,
    TError,
    { courseId: number; scheduleId: number },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof courseControllerEnterLiveClass>>,
  TError,
  { courseId: number; scheduleId: number },
  TContext
> => {
  const mutationOptions =
    getCourseControllerEnterLiveClassMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary 강의 스케쥴 > 라이브 수업 입장 (수강생)
 */
export const courseControllerEnterLiveClassByStudent = (
  courseId: number,
  scheduleId: number,
  options?: SecondParameter<typeof axiosInstance>,
) => {
  return axiosInstance<LiveCourseResponseDto>(
    {
      url: `/course/${courseId}/schedule/${scheduleId}/enter-live-class/student`,
      method: "POST",
    },
    options,
  );
};

export const getCourseControllerEnterLiveClassByStudentMutationOptions = <
  TError = ErrorType<LiveCourseResponseDto>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof courseControllerEnterLiveClassByStudent>>,
    TError,
    { courseId: number; scheduleId: number },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof courseControllerEnterLiveClassByStudent>>,
  TError,
  { courseId: number; scheduleId: number },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof courseControllerEnterLiveClassByStudent>>,
    { courseId: number; scheduleId: number }
  > = (props) => {
    const { courseId, scheduleId } = props ?? {};

    return courseControllerEnterLiveClassByStudent(
      courseId,
      scheduleId,
      requestOptions,
    );
  };

  return { mutationFn, ...mutationOptions };
};

export type CourseControllerEnterLiveClassByStudentMutationResult = NonNullable<
  Awaited<ReturnType<typeof courseControllerEnterLiveClassByStudent>>
>;

export type CourseControllerEnterLiveClassByStudentMutationError =
  ErrorType<LiveCourseResponseDto>;

/**
 * @summary 강의 스케쥴 > 라이브 수업 입장 (수강생)
 */
export const useCourseControllerEnterLiveClassByStudent = <
  TError = ErrorType<LiveCourseResponseDto>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof courseControllerEnterLiveClassByStudent>>,
    TError,
    { courseId: number; scheduleId: number },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof courseControllerEnterLiveClassByStudent>>,
  TError,
  { courseId: number; scheduleId: number },
  TContext
> => {
  const mutationOptions =
    getCourseControllerEnterLiveClassByStudentMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary 강의 사이클 > 스케쥴 전체 수정
 */
export const courseControllerUpdateCycle = (
  courseId: number,
  cycleId: number,
  updateCycleRequestDto: UpdateCycleRequestDto,
  options?: SecondParameter<typeof axiosInstance>,
) => {
  return axiosInstance<string>(
    {
      url: `/course/${courseId}/cycle/${cycleId}`,
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      data: updateCycleRequestDto,
    },
    options,
  );
};

export const getCourseControllerUpdateCycleMutationOptions = <
  TError = ErrorType<string>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof courseControllerUpdateCycle>>,
    TError,
    { courseId: number; cycleId: number; data: UpdateCycleRequestDto },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof courseControllerUpdateCycle>>,
  TError,
  { courseId: number; cycleId: number; data: UpdateCycleRequestDto },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof courseControllerUpdateCycle>>,
    { courseId: number; cycleId: number; data: UpdateCycleRequestDto }
  > = (props) => {
    const { courseId, cycleId, data } = props ?? {};

    return courseControllerUpdateCycle(courseId, cycleId, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type CourseControllerUpdateCycleMutationResult = NonNullable<
  Awaited<ReturnType<typeof courseControllerUpdateCycle>>
>;
export type CourseControllerUpdateCycleMutationBody = UpdateCycleRequestDto;
export type CourseControllerUpdateCycleMutationError = ErrorType<string>;

/**
 * @summary 강의 사이클 > 스케쥴 전체 수정
 */
export const useCourseControllerUpdateCycle = <
  TError = ErrorType<string>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof courseControllerUpdateCycle>>,
    TError,
    { courseId: number; cycleId: number; data: UpdateCycleRequestDto },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof courseControllerUpdateCycle>>,
  TError,
  { courseId: number; cycleId: number; data: UpdateCycleRequestDto },
  TContext
> => {
  const mutationOptions =
    getCourseControllerUpdateCycleMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary 강의 사이클 > 스케쥴 전체 삭제
 */
export const courseControllerDeleteCycle = (
  courseId: number,
  cycleId: number,
  options?: SecondParameter<typeof axiosInstance>,
) => {
  return axiosInstance<string>(
    { url: `/course/${courseId}/cycle/${cycleId}`, method: "DELETE" },
    options,
  );
};

export const getCourseControllerDeleteCycleMutationOptions = <
  TError = ErrorType<string>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof courseControllerDeleteCycle>>,
    TError,
    { courseId: number; cycleId: number },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof courseControllerDeleteCycle>>,
  TError,
  { courseId: number; cycleId: number },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof courseControllerDeleteCycle>>,
    { courseId: number; cycleId: number }
  > = (props) => {
    const { courseId, cycleId } = props ?? {};

    return courseControllerDeleteCycle(courseId, cycleId, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type CourseControllerDeleteCycleMutationResult = NonNullable<
  Awaited<ReturnType<typeof courseControllerDeleteCycle>>
>;

export type CourseControllerDeleteCycleMutationError = ErrorType<string>;

/**
 * @summary 강의 사이클 > 스케쥴 전체 삭제
 */
export const useCourseControllerDeleteCycle = <
  TError = ErrorType<string>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof courseControllerDeleteCycle>>,
    TError,
    { courseId: number; cycleId: number },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof courseControllerDeleteCycle>>,
  TError,
  { courseId: number; cycleId: number },
  TContext
> => {
  const mutationOptions =
    getCourseControllerDeleteCycleMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * 스케쥴 리스트를 조회한다.
 * @summary 스케쥴 리스트 조회 (with 페이지네이션)
 */
export const courseControllerGetScheduleById = (
  scheduleId: number,
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal,
) => {
  return axiosInstance<ScheduleResponseDto>(
    { url: `/course/schedule/${scheduleId}`, method: "GET", signal },
    options,
  );
};

export const getCourseControllerGetScheduleByIdQueryKey = (
  scheduleId: number,
) => {
  return [`/course/schedule/${scheduleId}`] as const;
};

export const getCourseControllerGetScheduleByIdQueryOptions = <
  TData = Awaited<ReturnType<typeof courseControllerGetScheduleById>>,
  TError = ErrorType<ScheduleResponseDto>,
>(
  scheduleId: number,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof courseControllerGetScheduleById>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getCourseControllerGetScheduleByIdQueryKey(scheduleId);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof courseControllerGetScheduleById>>
  > = ({ signal }) =>
    courseControllerGetScheduleById(scheduleId, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!scheduleId,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof courseControllerGetScheduleById>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type CourseControllerGetScheduleByIdQueryResult = NonNullable<
  Awaited<ReturnType<typeof courseControllerGetScheduleById>>
>;
export type CourseControllerGetScheduleByIdQueryError =
  ErrorType<ScheduleResponseDto>;

/**
 * @summary 스케쥴 리스트 조회 (with 페이지네이션)
 */
export const useCourseControllerGetScheduleById = <
  TData = Awaited<ReturnType<typeof courseControllerGetScheduleById>>,
  TError = ErrorType<ScheduleResponseDto>,
>(
  scheduleId: number,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof courseControllerGetScheduleById>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getCourseControllerGetScheduleByIdQueryOptions(
    scheduleId,
    options,
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * 미팅 url 포함한 정보를 조회한다.(사용자 역할이 "수강생"만 있으면 startUrl은 제외)
 * @summary 미팅 정보 조회
 */
export const courseControllerGetMeetingInfo = (
  scheduleId: number,
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal,
) => {
  return axiosInstance<void>(
    { url: `/course/schedule/${scheduleId}/meeting`, method: "GET", signal },
    options,
  );
};

export const getCourseControllerGetMeetingInfoQueryKey = (
  scheduleId: number,
) => {
  return [`/course/schedule/${scheduleId}/meeting`] as const;
};

export const getCourseControllerGetMeetingInfoQueryOptions = <
  TData = Awaited<ReturnType<typeof courseControllerGetMeetingInfo>>,
  TError = ErrorType<unknown>,
>(
  scheduleId: number,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof courseControllerGetMeetingInfo>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getCourseControllerGetMeetingInfoQueryKey(scheduleId);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof courseControllerGetMeetingInfo>>
  > = ({ signal }) =>
    courseControllerGetMeetingInfo(scheduleId, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!scheduleId,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof courseControllerGetMeetingInfo>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type CourseControllerGetMeetingInfoQueryResult = NonNullable<
  Awaited<ReturnType<typeof courseControllerGetMeetingInfo>>
>;
export type CourseControllerGetMeetingInfoQueryError = ErrorType<unknown>;

/**
 * @summary 미팅 정보 조회
 */
export const useCourseControllerGetMeetingInfo = <
  TData = Awaited<ReturnType<typeof courseControllerGetMeetingInfo>>,
  TError = ErrorType<unknown>,
>(
  scheduleId: number,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof courseControllerGetMeetingInfo>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getCourseControllerGetMeetingInfoQueryOptions(
    scheduleId,
    options,
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

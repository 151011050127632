import { AccordionList } from "@/components";
import { useAccessToken } from "@/hooks/auth/use-access-token";
import {
  InquiryType,
  useInquiryTypeStore,
  useModalStore,
  useScheduleStore,
} from "@/stores";
import styled from "@emotion/styled";
import { useCourseControllerCourse } from "@shared/generated/api/fn/kac/course/course";
import {
  CourseInDetailResponseDto,
  CourseTypeEnum,
} from "@shared/generated/api/model";
import { getRemaining } from "@shared/lib/utils/classData";
import { formatSingleClassDate } from "@shared/lib/utils/date/formatSchedule";
import { openToast, TOAST_TYPE } from "@shared/lib/utils/toast";
import { useRouter } from "next/router";
import { useState } from "react";

export const ScheduleSelection = () => {
  const { accessToken } = useAccessToken();
  const [openIndex, setOpenIndex] = useState<number | null>(null);

  const setSchedule = useScheduleStore((state) => state.setScheduleId);
  const clearSchedule = useScheduleStore((state) => state.clearScheduleId);

  const toggleItem = (index: number, cycleId: number) => {
    if (openIndex === index) {
      setOpenIndex(null);
      clearSchedule();
    } else {
      setOpenIndex(index);
      setSchedule(cycleId);
    }
  };

  const { setInquiryType } = useInquiryTypeStore();
  const { openModal } = useModalStore();
  const router = useRouter();
  const { id } = router.query;

  const handleScheduleInquiry = () => {
    if (accessToken) {
      setInquiryType(InquiryType.Schedule);
      openModal("inquiryModal");
    } else {
      openToast(TOAST_TYPE.ERROR, "로그인이 필요합니다.");
    }
  };

  const { data: classData, isLoading } = useCourseControllerCourse(
    Number(id),
    undefined,
  );
  const { cycles, type, totalSessions } =
    classData as CourseInDetailResponseDto;
  return (
    <>
      <Title>스케줄 선택</Title>
      <SubTitle>기간을 선택하고 일정을 확인하세요.</SubTitle>
      {type === CourseTypeEnum.SINGLE ? (
        <CardWrapper>
          {cycles.map((cycle, index) => {
            const { date, day, time } = formatSingleClassDate(
              new Date(cycle.startsAt),
            );
            return (
              <ScheduleCard
                key={index}
                onClick={() =>
                  !cycle.enrollmentClosed && toggleItem(index, cycle.id)
                }
                isOpen={!cycle.enrollmentClosed && openIndex === index}
                disabled={cycle.enrollmentClosed}
              >
                <ScheduleText
                  isOpen={!cycle.enrollmentClosed && openIndex === index}
                  disabled={cycle.enrollmentClosed}
                >
                  <div>{date}</div>
                  <div>{day}</div>
                  <div>{time}</div>
                </ScheduleText>
                <RemainText>
                  {cycle.enrollmentClosed || !cycle.selling
                    ? "마감"
                    : getRemaining(
                        cycle.maxEnrollments,
                        cycle.cycleEnrollments.length,
                      )}
                </RemainText>
              </ScheduleCard>
            );
          })}
          <AddScheduleCard onClick={handleScheduleInquiry}>
            스케줄
            <br />
            협의하기
          </AddScheduleCard>
        </CardWrapper>
      ) : (
        <ListWrapper>
          <AccordionList
            cycles={cycles}
            totalSessions={totalSessions || 0}
          />
        </ListWrapper>
      )}
    </>
  );
};

const Title = styled.div`
  ${({ theme }) => theme.typography.h3.b};
  color: ${({ theme }) => theme.colors.text.high.white};
`;

const SubTitle = styled.div`
  ${({ theme }) => theme.typography.body2.m};
  padding-top: 4px;
  color: ${({ theme }) => theme.colors.text.medium.white};
`;

const CardWrapper = styled.div`
  cursor: pointer;
  width: 100%;
  padding-top: 24px;
  display: grid;
  gap: 8px;
  grid-template-columns: repeat(7, 1fr);
  @media (max-width: 1180px) {
    grid-template-columns: repeat(5, 1fr);
  }
  ${({ theme }) => theme.media.tablet} {
    grid-template-columns: repeat(4, 1fr);
  }
  @media (max-width: 890px) {
    grid-template-columns: repeat(3, 1fr);
  }
  ${({ theme }) => theme.media.tabletSm} {
    grid-template-columns: repeat(6, 1fr);
  }
  @media (max-width: 670px) {
    grid-template-columns: repeat(5, 1fr);
  }
  @media (max-width: 575px) {
    grid-template-columns: repeat(4, 1fr);
  }
  @media (max-width: 460px) {
    grid-template-columns: repeat(3, 1fr);
  }
`;

const ScheduleCard = styled.div<{ isOpen: boolean; disabled: boolean }>`
  padding: 16px;
  min-height: 130px;
  border-radius: 10px;
  border: ${({ disabled, isOpen, theme }) =>
    disabled
      ? `1px solid ${theme.colors.gray.gray20}`
      : isOpen
        ? `2px solid ${theme.colors.icon.active.white}`
        : `1px solid ${theme.colors.icon.inactive.white}`};
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 8px;
`;

const ScheduleText = styled.div<{ isOpen: boolean; disabled: boolean }>`
  ${({ theme }) => theme.typography.body2.b};
  color: ${({ disabled, isOpen, theme }) =>
    disabled
      ? theme.colors.gray.gray20
      : isOpen
        ? theme.colors.icon.active.white
        : theme.colors.icon.inactive.white};
`;

const RemainText = styled.div`
  ${({ theme }) => theme.typography.body2.r};
  color: ${({ theme }) => theme.colors.state.error.default};
`;

const AddScheduleCard = styled.div`
  padding: 16px;
  min-height: 130px;
  border-radius: 10px;
  background: ${({ theme }) => theme.colors.brand.secondary.default};
  ${({ theme }) => theme.typography.body2.b};
  color: ${({ theme }) => theme.colors.text.high.white};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  cursor: pointer;
`;

const ListWrapper = styled.div`
  padding-top: 24px;
`;

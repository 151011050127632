import { Swiper, SwiperSlide } from "swiper/react";
import { Swiper as SwiperType } from "swiper/types";
import { Navigation, Pagination, Autoplay } from "swiper/modules";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import styled from "@emotion/styled";
import { useRef, useState } from "react";
import KeyboardArrowLeftRoundedIcon from "@mui/icons-material/KeyboardArrowLeftRounded";
import KeyboardArrowRightRoundedIcon from "@mui/icons-material/KeyboardArrowRightRounded";
import PauseRoundedIcon from "@mui/icons-material/PauseRounded";
import PlayArrowRoundedIcon from "@mui/icons-material/PlayArrowRounded";
import { AcademyContentsDto } from "@shared/generated/api/model";
import { useMediaQueryMaxWidth } from "@shared/lib/hooks/use-media-query-max-width";
import { useRouter } from "next/router";

export const SlideBanner = ({
  academyInfo,
}: {
  academyInfo: AcademyContentsDto[];
}) => {
  const swiperRef = useRef<SwiperType | null>(null);
  const [isPlaying, setIsPlaying] = useState(true);
  const { isTabletSm } = useMediaQueryMaxWidth();
  const router = useRouter();

  const toggleAutoplay = () => {
    if (swiperRef.current) {
      if (isPlaying) {
        swiperRef.current.autoplay.stop();
      } else {
        swiperRef.current.autoplay.start();
      }
      setIsPlaying(!isPlaying);
    }
  };

  return (
    <Swiper
      modules={[Navigation, Pagination, Autoplay]}
      spaceBetween={10}
      slidesPerView={1}
      navigation={{
        prevEl: ".prev-button",
        nextEl: ".next-button",
      }}
      pagination={{
        el: ".swiper-pagination",
        type: "custom",
        renderCustom: (swiper, current, total) => `${current} / ${total}`,
      }}
      autoplay={{ delay: 3000 }}
      loop
      onSwiper={(swiper) => {
        swiperRef.current = swiper;
      }}
    >
      {academyInfo.map((banner) => (
        <SwiperSlide
          key={banner.academyId}
          onClick={() =>
            banner.academyId && router.push(`/academy/${banner.academyId}`)
          }
        >
          <StyledImage
            src={isTabletSm ? banner.thumbnailImageMo : banner.thumbnailImagePc}
            alt={"Academy Thumbnail"}
          />
        </SwiperSlide>
      ))}

      {academyInfo.length > 1 && (
        <ControllerContainer>
          <CustomPagination className="swiper-pagination" />
          <NavigationWrapper>
            <ButtonWrapper className="prev-button">
              <KeyboardArrowLeftRoundedIcon />
            </ButtonWrapper>
            <ButtonWrapper onClick={toggleAutoplay}>
              {isPlaying ? <PauseRoundedIcon /> : <PlayArrowRoundedIcon />}
            </ButtonWrapper>
            <ButtonWrapper className="next-button">
              <KeyboardArrowRightRoundedIcon />
            </ButtonWrapper>
          </NavigationWrapper>
        </ControllerContainer>
      )}
    </Swiper>
  );
};

const StyledImage = styled.img`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  object-fit: cover;
  border-radius: 8px;
  cursor: pointer;
`;

const ControllerContainer = styled.div`
  width: 100%;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-direction: row;
  gap: 9px;
  padding: 24px;
  right: 0;
  bottom: 0;
  z-index: 10;
  .swiper-pagination {
    width: auto !important;
    position: static !important;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${({ theme }) => theme.colors.brand.secondary.default};
    border-radius: 36px;
    padding: 4px 12px;
  }
`;

const CustomPagination = styled.div`
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.colors.gray.gray100};
  font-size: 14px;
  font-weight: 700;
  height: 28px;
`;

const NavigationWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.brand.secondary.default};
  border-radius: 36px;
  padding: 6px;
  height: 28px;
  width: 80px;
  ${({ theme }) => theme.media.tabletSm} {
    display: none;
  }
`;

const ButtonWrapper = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 22px;
  height: 100%;
  color: ${({ theme }) => theme.colors.icon.inactive.white};
`;

import { Box, Paper, SxProps, Typography } from "@mui/material";
import PlayArrowRoundedIcon from "@mui/icons-material/PlayArrowRounded";
import FavoriteIcon from "@mui/icons-material/Favorite";

interface NoClassPlaceholderProps {
  sx?: SxProps;
  placeholder: string;
}

export const NoClassPlaceholder = ({
  placeholder,
  sx = {},
}: NoClassPlaceholderProps) => {
  return (
    <Box
      sx={{
        borderRadius: "8px",
        display: "flex",
        flexDirection: "column",
        width: "343px",
        height: "360px",
        alignItems: "center",
        justifyContent: "center",
        gap: 2,
        ...sx,
      }}
    >
      <Box sx={{ display: "flex", position: "relative" }}>
        {/* 메인 컨테이너 - 더 둥근 모서리 */}
        <Box>
          <Paper
            sx={{
              width: "74px",
              height: "13px",
              borderRadius: "8px 8px 0px 0px",
              bgcolor: "#00000061",
            }}
          />
          <Paper
            sx={{
              mt: "4px",
              width: "74px",
              height: "52px",
              borderRadius: "0px 0px 8px 8px",
              bgcolor: "#00000061",
            }}
          />
        </Box>

        <Box
          sx={{
            position: "absolute",
            top: "41.63px",
            left: "42.13px",
            width: "48.38px",
            height: "48.38px",
            borderRadius: "50%",
            background:
              "linear-gradient(225deg, rgba(217, 217, 217, 0.8) 13.31%, rgba(255, 255, 255, 0.8) 87.73%)",
            backdropFilter: "blur(8px)",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {placeholder === "구매한 강의가 없습니다." ? (
            <PlayArrowRoundedIcon
              sx={{
                color: "white",
                fontSize: "32px",
              }}
            />
          ) : (
            <FavoriteIcon
              sx={{
                color: "white",
                fontSize: "32px",
              }}
            />
          )}
        </Box>
      </Box>
      <Typography
        sx={{
          color: "#00000099",
          fontWeight: 500,
          letterSpacing: "-0.4px",
          fontSize: "14px",
          paddingTop: "8px",
        }}
      >
        {placeholder}
      </Typography>
    </Box>
  );
};

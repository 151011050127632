/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * KAC-API
 * K-Artist Class API
 * OpenAPI spec version: 0.0.1
 */

export type NoticeControllerReadNoticesWithMoreQueryOptionsTargetTypes =
  (typeof NoticeControllerReadNoticesWithMoreQueryOptionsTargetTypes)[keyof typeof NoticeControllerReadNoticesWithMoreQueryOptionsTargetTypes];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const NoticeControllerReadNoticesWithMoreQueryOptionsTargetTypes = {
  STUDENT: "STUDENT",
  INSTRUCTOR: "INSTRUCTOR",
  ACADEMY: "ACADEMY",
} as const;

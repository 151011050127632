import { useState } from "react";
import classNames from "classnames";
import styled from "@emotion/styled";

import {
  CONFIRM_BUTTON_TYPE,
  CONFIRM_MODAL_TYPE,
  ConfirmModal,
} from "@shared/lib/components/common/molecules/modal/confirm-modal";
import { EditMarketingAgree } from "@/components/domain/my/profile/edit-marketing-agree";
import { EditPassword } from "@/components/domain/my/profile/edit-password";
import { EditPhoneNumber } from "@/components/domain/my/profile/edit-phone-number";
import { ProfileInfo } from "@/components/domain/my/profile/profile-info";
import { RequestWithdrawal } from "@/components/domain/my/profile/request-withdrawal";
import { useProfile } from "@/hooks/auth/use-profile";
import { AuthType } from "@shared/generated/api/model";

const EDIT_TYPE = {
  PASSWORD: "PASSWORD",
  PHONE_NUMBER: "PHONE_NUMBER",
  MARKETING_AGREE: "MARKETING_AGREE",
  WITHDRAWAL: "WITHDRAWAL",
} as const;
type EditType = keyof typeof EDIT_TYPE;

export const MyProfileTemplate = () => {
  const { data: profile } = useProfile();

  const [editType, setEditType] = useState<EditType | null>();
  const [isOpenWithdrawalConfirmModal, setIsOpenWithdrawalConfirmModal] =
    useState(false);

  const onEditCancel = () => {
    setEditType(null);
  };

  const onActiveEditPassword = () => {
    if (profile?.authType === AuthType.EMAIL) {
      setEditType(EDIT_TYPE.PASSWORD);
    }
  };

  const onActiveEditPhoneNumber = () => {
    setEditType(EDIT_TYPE.PHONE_NUMBER);
  };

  const onActiveEditMarketingAgree = () => {
    setEditType(EDIT_TYPE.MARKETING_AGREE);
  };

  const onOpenWithdrawalConfirmModal = () => {
    setIsOpenWithdrawalConfirmModal(true);
  };

  const onCloseWithdrawalConfirmModal = () => {
    setIsOpenWithdrawalConfirmModal(false);
  };

  const onConfirmWithdrawal = () => {
    setEditType(EDIT_TYPE.WITHDRAWAL);
  };

  return (
    <MyProfileTemplateContainer>
      <TitleContainer
        className={classNames({
          "display-none": !editType,
        })}
      >
        {!editType && `내 정보 수정`}
        {editType === EDIT_TYPE.PASSWORD && `비밀번호 수정`}
        {editType === EDIT_TYPE.PHONE_NUMBER && `휴대전화 번호 수정`}
        {editType === EDIT_TYPE.MARKETING_AGREE && `마케팅 수신 동의 수정`}
        {editType === EDIT_TYPE.WITHDRAWAL && `회원 탈퇴`}
      </TitleContainer>
      {!editType && (
        <ProfileInfo
          onActiveEditPassword={onActiveEditPassword}
          onActiveEditPhoneNumber={onActiveEditPhoneNumber}
          onActiveEditMarketingAgree={onActiveEditMarketingAgree}
          onOpenWithdrawalConfirmModal={onOpenWithdrawalConfirmModal}
        />
      )}

      {editType === EDIT_TYPE.PASSWORD && (
        <EditPassword onEditCancel={onEditCancel} />
      )}

      {editType === EDIT_TYPE.PHONE_NUMBER && (
        <EditPhoneNumber onEditCancel={onEditCancel} />
      )}

      {editType === EDIT_TYPE.MARKETING_AGREE && (
        <EditMarketingAgree onEditCancel={onEditCancel} />
      )}

      {editType === EDIT_TYPE.WITHDRAWAL && (
        <RequestWithdrawal onEditCancel={onEditCancel} />
      )}

      {isOpenWithdrawalConfirmModal && (
        <ConfirmModal
          type={CONFIRM_MODAL_TYPE.ERROR}
          confirmButtonType={CONFIRM_BUTTON_TYPE.TEXT}
          isOpen={isOpenWithdrawalConfirmModal}
          title={"정말 회원 탈퇴하시겠습니까?"}
          subTitle={
            "회원 탈퇴 시 모든 개인정보와 수강/결제내역 및 포인트가 삭제됩\n니다. 수강기간 또는 포인트가 남은 경우\n탈퇴 시 환불되지 않습니다."
          }
          confirmText={"다시 생각해 보겠습니다."}
          cancelText={"정말 탈퇴하겠습니다."}
          onClose={onCloseWithdrawalConfirmModal}
          onConfirm={onCloseWithdrawalConfirmModal}
          onCancel={onConfirmWithdrawal}
        />
      )}
    </MyProfileTemplateContainer>
  );
};

const MyProfileTemplateContainer = styled.div`
  width: 100%;
  height: 100%;
  min-height: 50vh;

  display: flex;
  flex-direction: column;
  gap: 80px;

  ${({ theme }) => theme.media.tabletSm} {
    padding: 0 16px;
    gap: 40px;

    min-height: auto;
  }
`;

const TitleContainer = styled.div`
  ${({ theme }) => theme.typography.h1.b};
  color: ${({ theme }) => theme.colors.text.high.black};

  ${({ theme }) => theme.media.tabletSm} {
    &.display-none {
      display: none;
    }
  }
`;

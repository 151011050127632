import { forwardRef, ReactNode, useState } from "react";
import { Popover } from "react-tiny-popover";
import classNames from "classnames";
import styled from "@emotion/styled";

import { Icon } from "@shared/lib/components/common/atom/icon";
import { ICON_SVG } from "@shared/lib/constants/import/icon-svg";
import { colors } from "@shared/lib/styles/theme";

interface SelectOption {
  label: string;
  value: string;
}
interface MultiSelectProps {
  label?: string;
  options: SelectOption[];
  onChange?: (value: string[]) => void;
  value?: string[];
  isError?: boolean;
  isRequired?: boolean;
  message?: string | ReactNode;
  children?: ReactNode;
  placeholder?: string;
  className?: string;
}

export const MultiSelect = forwardRef<MultiSelectProps, MultiSelectProps>(
  (props, selectRef) => {
    const {
      children,
      label,
      isError,
      isRequired,
      className,
      message,
      placeholder,
      options,
      value,
      onChange,
    } = props;

    const [isPopoverOpen, setIsPopoverOpen] = useState(false);

    const onOpenPopover = () => {
      setIsPopoverOpen(true);
    };

    const onClosePopover = () => {
      setIsPopoverOpen(false);
    };

    const onChangeSelect = (newValue: string) => {
      onChange && onChange(value ? [...value, newValue] : [newValue]);
    };

    const onValueItemRemove = (removeValueItem: string) => {
      if (value && onChange) {
        onChange([
          ...value.filter((valueItem) => valueItem !== removeValueItem),
        ]);
      }
    };

    return (
      <MultiSelectContainer>
        {label && (
          <MultiSelectLabel>
            {label}
            {isRequired && <span>*</span>}
          </MultiSelectLabel>
        )}

        <Popover
          containerStyle={{
            zIndex: `${10}`,
          }}
          isOpen={isPopoverOpen}
          positions={["bottom", "top"]}
          align={"center"}
          padding={8}
          onClickOutside={onClosePopover}
          content={({ childRect }) => (
            <MultiSelectPopoverContainer
              style={{
                width: childRect.width,
              }}
            >
              {options.map((option, index) => {
                const isActive = value?.includes(option.value);
                const optionValue = option.value;
                return (
                  <Option
                    className={classNames({
                      active: isActive,
                    })}
                    onClick={() =>
                      isActive
                        ? onValueItemRemove(optionValue)
                        : onChangeSelect(optionValue)
                    }
                    key={`option-${index}`}
                  >
                    <span>{option.label}</span>
                    {isActive && (
                      <OptionActiveIcon
                        icon={ICON_SVG.LINE.SIZE_18.ICON_CHECK}
                      />
                    )}
                  </Option>
                );
              })}
            </MultiSelectPopoverContainer>
          )}
        >
          <MultiSelectInput
            className={classNames({
              ["is-error"]: isError,
              ["is-select"]: isPopoverOpen,
            })}
            onClick={isPopoverOpen ? onClosePopover : onOpenPopover}
          >
            {(!value || value.length === 0) && !!placeholder && (
              <span className="placeholder">{placeholder}</span>
            )}

            {!!value && value.length > 0 && (
              <ValueContainer>
                {value.map((valueItem, index) => {
                  const result = options.filter(
                    (option) => option.value === valueItem,
                  )[0];
                  return (
                    <ValueItem
                      key={`value-item-${index}`}
                      onClick={(e) => {
                        e.stopPropagation();
                        onValueItemRemove(valueItem);
                      }}
                    >
                      <span>{result?.label}</span>
                      <ValueCloseIcon icon={ICON_SVG.LINE.SIZE_18.ICON_CLOSE} />
                    </ValueItem>
                  );
                })}
              </ValueContainer>
            )}

            <MultiSelectInputIconContainer>
              <MultiSelectInputIcon
                icon={ICON_SVG.LINE.SIZE_18.ICON_ARROW_DOWN}
                stroke={colors.icon.disabled.black}
              />
            </MultiSelectInputIconContainer>
          </MultiSelectInput>
        </Popover>

        {message && (
          <Message
            className={classNames({
              ["is-error"]: isError,
            })}
          >
            {message}
          </Message>
        )}
      </MultiSelectContainer>
    );
  },
);

const MultiSelectContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const MultiSelectLabel = styled.div`
  display: flex;
  align-items: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 171.429% */
  letter-spacing: 0.5px;

  color: ${({ theme }) => theme.colors.text.high.black};

  span {
    ${({ theme }) => theme.typography.body2.m};
    color: ${({ theme }) => theme.colors.state.error.default};
  }
`;

const Message = styled.div`
  margin-top: 4px;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 100%; /* 12px */
  letter-spacing: 0.5px;

  color: ${({ theme }) => theme.colors.icon.inactive.black};

  &.is-error {
    color: ${({ theme }) => theme.colors.state.error.default};
  }
`;

const MultiSelectInput = styled.div`
  cursor: pointer;
  margin-top: 4px;

  border-radius: 4px;
  border: 1px solid ${({ theme }) => theme.colors.gray.gray80};
  background: ${({ theme }) => theme.colors.environment.background.default};

  display: flex;
  justify-content: space-between;
  align-items: center;

  padding: 8px 16px 8px 8px;
  width: 100%;
  min-height: 46px;
  height: 100%;

  &.is-error {
    border-color: ${({ theme }) => theme.colors.state.error.default};
  }

  &.is-select {
    border-color: ${({ theme }) => theme.colors.brand.primary.default};
  }

  .placeholder {
    width: 100%;
    ${({ theme }) => theme.typography.body1.m};
    color: ${({ theme }) => theme.colors.text.disabled.black};
  }
`;

const MultiSelectInputIconContainer = styled.div`
  flex-shrink: 0;
  display: flex;
  align-items: center;
  width: 18px;
`;

const MultiSelectInputIcon = styled(Icon)``;

const ValueContainer = styled.div`
  width: 100%;
  height: 100%;

  display: flex;
  gap: 8px;
  flex-wrap: wrap;
  align-items: center;
`;

const ValueItem = styled.div`
  cursor: pointer;
  padding: 4px 8px;
  display: flex;
  align-items: center;
  gap: 8px;

  border-radius: 4px;
  background: ${({ theme }) => theme.colors.gray.gray95};

  span {
    ${({ theme }) => theme.typography.body1.m};
    color: ${({ theme }) => theme.colors.text.high.black};
  }
`;

const ValueCloseIcon = styled(Icon)`
  path {
    stroke: ${({ theme }) => theme.colors.icon.disabled.black};
  }
`;

const MultiSelectPopoverContainer = styled.div`
  padding: 0 8px;
  height: 100%;
  max-height: 288px;

  border-radius: 4px;
  border: 1px solid ${({ theme }) => theme.colors.gray.gray80};
  background: #fff;
  box-shadow: 4px 4px 24px 0 rgba(0, 0, 0, 0.15);

  overflow: hidden;
  overflow-y: auto;
`;

const Option = styled.div`
  cursor: pointer;
  display: flex;
  padding: 4px 12px;
  justify-content: space-between;
  align-items: center;
  flex: 1 0 0;
  align-self: stretch;

  min-height: 44px;

  &:hover {
    border-radius: 2px;
    background-color: ${({ theme }) => theme.colors.gray.gray95};
  }

  &.active {
    border-radius: 2px;
    background: rgba(32, 101, 236, 0.08);
  }
`;

const OptionActiveIcon = styled(Icon)`
  path {
    stroke: ${({ theme }) => theme.colors.sub.blue.default};
  }
`;

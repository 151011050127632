import cryptoJs from "crypto-js";

export const encoding = (plaintext: string) => {
  //base64 encrypt
  const wordArray = cryptoJs.enc.Utf8.parse(plaintext);
  const encoded = cryptoJs.enc.Base64.stringify(wordArray);
  return encoded;
};

export const decoding = (encoded: string) => {
  //base64 decrypt
  const parsedWordArray = cryptoJs.enc.Base64.parse(encoded);
  const decoded = parsedWordArray.toString(cryptoJs.enc.Utf8);
  return decoded;
};

import { useState } from "react";
import classNames from "classnames";
import styled from "@emotion/styled";

import { Icon } from "@shared/lib/components/common/atom/icon";
import { ICON_SVG } from "@shared/lib/constants/import/icon-svg";
import { colors } from "@shared/lib/styles/theme";
import { MAX_WIDTH } from "@/constants/style/layout";

const QNA_LIST = [
  {
    question: "Q.  케아클 강사로 활동하고 싶어요! 강사 등록비가 필요한가요?",
    answer:
      "케아클에는 별도의 강사 등록비가 없습니다. 5분 이면 완성 가능한 강사 등록으로 케아클의 모든 기능과 지원을 무료로 이용해 보세요.",
  },
  {
    question: "Q. 강의가 판매됐어요! 수익금은 어떻게 받을 수 있나요?",
    answer:
      "매월 수강 현황 내역을 종합하여 계약 조건에 따라 정산 될 강사료를 책정합니다. 책정 된 강사료와 책정에 활용 된 정산 내역은 익월 말일 강사님의 계좌와 이메일로 전달됩니다.",
  },
  {
    question: "Q. 수업은 어디에서, 어떻게 진행하나요?",
    answer:
      "케아클의 모든 수업은 ZOOM을 통해 비대면으로 진행됩니다. 원활한 수업 진행을 위해 인터넷 환경이 안정적이고 조용한 장소에서 수업을 진행해주시길 바랍니다. 또한 선생님께는 수업 시작 10분 전 입장과 수업 준비를 권장드립니다.",
  },
  {
    question: "Q. 다른 장르로 강사지원이 가능한가요?",
    answer:
      "현재 진행하지 않는 신규 장르로 강사지원을 진행하셔도 괜찮습니다. 동일하게 연락드립니다. 다만 내부 상황상 강의 개설이 조금 늦춰질 수 있습니다.",
  },
];

export const QnaSection = () => {
  const [qnaIsOpenList, setQnaIsOpenList] = useState(
    Array.from({ length: QNA_LIST.length }, () => false),
  );

  const onClickQnaItem = (index: number) => {
    setQnaIsOpenList([
      ...qnaIsOpenList.map((isOpen, i) => (index === i ? !isOpen : isOpen)),
    ]);
  };

  return (
    <StyledQnaSection>
      <QnaSectionInnerContainer>
        <QnaSectionTitleContainer>자주 묻는 질문</QnaSectionTitleContainer>
        <QnaSectionContentContainer>
          {QNA_LIST.map((qna, index) => {
            const isOpen = qnaIsOpenList[index];

            return (
              <QnaSectionItem
                key={`qna-list-${index}`}
                onClick={() => onClickQnaItem(index)}
              >
                <QnaSectionItemQuestion>
                  <span>{qna.question}</span>
                  <Icon
                    icon={
                      isOpen
                        ? ICON_SVG.LINE.SIZE_24.ICON_ARROW_UP
                        : ICON_SVG.LINE.SIZE_24.ICON_ARROW_DOWN
                    }
                    stroke={colors.icon.inactive.black}
                  />
                </QnaSectionItemQuestion>

                <QnaSectionItemDivider
                  className={classNames({
                    active: isOpen,
                  })}
                />
                <QnaSectionItemAnswer
                  className={classNames({
                    active: isOpen,
                  })}
                >
                  {qna.answer}
                </QnaSectionItemAnswer>
              </QnaSectionItem>
            );
          })}
        </QnaSectionContentContainer>
      </QnaSectionInnerContainer>
    </StyledQnaSection>
  );
};

const StyledQnaSection = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 80px 0;
  width: 100%;
`;

const QnaSectionInnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: 56px;

  padding: 0 16px;
  width: 100%;
  max-width: ${MAX_WIDTH.INNER_CONTAINER.PC}px;
`;

const QnaSectionTitleContainer = styled.div`
  text-align: center;
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  line-height: 125%; /* 37.5px */
  letter-spacing: -0.4px;

  color: ${({ theme }) => theme.colors.text.high.black};

  ${({ theme }) => theme.media.tabletSm} {
    text-align: center;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 125%; /* 30px */
    letter-spacing: -0.4px;
  }
`;

const QnaSectionContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;

  width: 100%;
`;

const QnaSectionItem = styled.div`
  cursor: pointer;
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 24px;

  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.colors.gray.gray80};
`;

const QnaSectionItemQuestion = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 30px;

  span {
    ${({ theme }) => theme.typography.h3.b};
    color: ${({ theme }) => theme.colors.text.high.black};
  }

  svg {
    flex-shrink: 0;
    width: 24px;
  }
`;

const QnaSectionItemDivider = styled.div`
  display: none;
  width: 100%;
  height: 1px;
  background: #d2d2d2;

  &.active {
    display: block;
  }
`;

const QnaSectionItemAnswer = styled.div`
  display: none;
  ${({ theme }) => theme.typography.body1.m};
  color: ${({ theme }) => theme.colors.text.high.black};

  &.active {
    display: flex;
  }
`;

import { Swiper, SwiperSlide } from "swiper/react";
import { Swiper as SwiperType } from "swiper/types";
import { Navigation, Pagination, Autoplay } from "swiper/modules";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import styled from "@emotion/styled";
import { useRef, useState } from "react";
import KeyboardArrowLeftRoundedIcon from "@mui/icons-material/KeyboardArrowLeftRounded";
import KeyboardArrowRightRoundedIcon from "@mui/icons-material/KeyboardArrowRightRounded";
import PauseRoundedIcon from "@mui/icons-material/PauseRounded";
import PlayArrowRoundedIcon from "@mui/icons-material/PlayArrowRounded";
import { MAX_WIDTH } from "@/constants/style/layout";
import { BannerDto } from "@shared/generated/api/model";
import { useMediaQueryMaxWidth } from "@shared/lib/hooks/use-media-query-max-width";
import { useRouter } from "next/router";

export const MainBanner = ({ bannerData }: { bannerData: BannerDto[] }) => {
  const swiperRef = useRef<SwiperType | null>(null);
  const [isPlaying, setIsPlaying] = useState(true);
  const { isTabletSm } = useMediaQueryMaxWidth();
  const router = useRouter();

  const toggleAutoplay = () => {
    if (swiperRef.current) {
      if (isPlaying) {
        swiperRef.current.autoplay.stop();
      } else {
        swiperRef.current.autoplay.start();
      }
      setIsPlaying(!isPlaying);
    }
  };

  return (
    <SwiperContainer>
      <Swiper
        modules={[Navigation, Pagination, Autoplay]}
        spaceBetween={10}
        slidesPerView={1}
        navigation={{
          prevEl: ".prev-button",
          nextEl: ".next-button",
        }}
        pagination={{
          el: ".custom-pagination",
          // type: "custom",
        }}
        autoplay={{ delay: 3000 }}
        loop
        onSwiper={(swiper) => {
          swiperRef.current = swiper;
        }}
      >
        {bannerData.map((banner) => (
          <SwiperSlide
            key={banner.orderNum}
            onClick={() =>
              router.push(
                banner?.academyId
                  ? `/academy/${banner.academyId}`
                  : `/class/live/${banner.router}/${banner.courseId}`,
              )
            }
          >
            <StyledImageContainer
              imageUrl={isTabletSm ? banner.mobileFilePath : banner.pcFilePath}
            >
              <ContentContainer>
                <BannerTitle
                  dangerouslySetInnerHTML={{ __html: banner.title || "" }}
                />
                <BannerSummary
                  dangerouslySetInnerHTML={{ __html: banner.description || "" }}
                />
              </ContentContainer>
            </StyledImageContainer>
          </SwiperSlide>
        ))}
        <ControllerContainer>
          <CustomPagination className="custom-pagination" />
          <NavigationWrapper>
            <ButtonWrapper className="prev-button">
              <KeyboardArrowLeftRoundedIcon />
            </ButtonWrapper>
            <ButtonWrapper onClick={toggleAutoplay}>
              {isPlaying ? <PauseRoundedIcon /> : <PlayArrowRoundedIcon />}
            </ButtonWrapper>
            <ButtonWrapper className="next-button">
              <KeyboardArrowRightRoundedIcon />
            </ButtonWrapper>
          </NavigationWrapper>
        </ControllerContainer>
      </Swiper>
    </SwiperContainer>
  );
};

const SwiperContainer = styled.div`
  width: 100%;
  cursor: pointer;
`;

const StyledImageContainer = styled.div<{ imageUrl: string }>`
  height: 340px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: url(${(props) => props.imageUrl});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
`;

const ContentContainer = styled.div`
  width: 100%;
  max-width: ${MAX_WIDTH.INNER_CONTAINER.PC}px;
  height: 100%;
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  z-index: 10;
`;

const BannerTitle = styled.div`
  color: #fff;
  font-size: 30px;
  letter-spacing: -0.3px;
  font-weight: 700;
  line-height: 125%;
`;

const BannerSummary = styled.div`
  ${({ theme }) => theme.typography.body2.b};
  color: ${({ theme }) => theme.colors.gray.gray90};
  padding-bottom: 26px;
`;

const ControllerContainer = styled.div`
  width: 100%;
  max-width: ${MAX_WIDTH.INNER_CONTAINER.PC}px;
  padding: 0 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: absolute;
  bottom: 40px;
  left: 50%;
  transform: translateX(-50%);
  gap: 8px;
  z-index: 10;

  ${({ theme }) => theme.media.tabletSm} {
    position: static;
    top: auto;
    left: auto;
    transform: none;
  }
  .swiper-pagination {
    width: 100%;
    max-width: 80px;
    position: static !important;
    display: flex;
    justify-content: center;
    align-items: end;
    background-color: ${({ theme }) => theme.colors.brand.secondary.default};
    border-radius: 36px;
    padding: 4px 12px;
  }

  .custom-pagination {
    width: 100%;
    display: flex;
    align-items: end;
    gap: 8px;
    ${({ theme }) => theme.media.tabletSm} {
      justify-content: center;
    }
  }

  .custom-pagination .swiper-pagination-bullet {
    width: 12px;
    height: 12px;
    background: ${({ theme }) => theme.colors.icon.inactive.white};
    border-radius: 100%;
    opacity: 1;
    transition: background 0.3s ease;
    margin: 0 !important;
  }

  .custom-pagination .swiper-pagination-bullet-active {
    width: 40px;
    height: 12px;
    background: #fff;
    border-radius: 12px;
  }
`;

const CustomPagination = styled.div`
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.colors.gray.gray100};
  font-size: 14px;
  font-weight: 700;
  height: 28px;
  width: 100%;
`;

const NavigationWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* background-color: ${({ theme }) =>
    theme.colors.brand.secondary.default}; */
  background-color: ${({ theme }) => theme.colors.icon.inactive.white};
  border-radius: 36px;
  padding: 6px;
  height: 28px;
  width: 80px;
  ${({ theme }) => theme.media.tabletSm} {
    display: none;
  }
`;

const ButtonWrapper = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 22px;
  height: 100%;
  color: ${({ theme }) => theme.colors.icon.inactive.black};
`;

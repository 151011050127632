import { ContentCard } from "@/components/common";
import styled from "@emotion/styled";
import { priceToLocaleString } from "@shared/lib/utils/price";

interface PointProps {
  earnedPoint: number;
  usedPoints: number;
}

export const PointContent = ({ earnedPoint, usedPoints }: PointProps) => {
  return (
    <ContentCard>
      <PointContainer>
        <PointText>포인트 사용</PointText>
        <InfoWrapper>
          <InfoText>적립 포인트</InfoText>
          <InfoText>
            {earnedPoint ? priceToLocaleString(earnedPoint) : 0}P
          </InfoText>
        </InfoWrapper>
        <InfoWrapper>
          <InfoText>사용 포인트</InfoText>
          <InfoText>
            {usedPoints ? priceToLocaleString(usedPoints) : 0}P
          </InfoText>
        </InfoWrapper>
      </PointContainer>
    </ContentCard>
  );
};

const PointContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const PointText = styled.div`
  ${({ theme }) => theme.typography.h3.b};
  color: ${({ theme }) => theme.colors.text.high.white};
  padding-bottom: 16px;
`;

const InfoWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const InfoText = styled.div`
  ${({ theme }) => theme.typography.body2.m};
  color: ${({ theme }) => theme.colors.text.high.white};
`;

import { SectionGlobalResponseDto } from "@shared/generated/api/model";

export const findIdByRouter = (
  items: SectionGlobalResponseDto[] | undefined,
  targetRouter: string,
): number | undefined | null => {
  if (items && targetRouter) {
    const normalizedTargetRouter = targetRouter.toLowerCase();
    const foundItem = items.find(
      (item) => item.router.toLowerCase() === normalizedTargetRouter,
    );
    return foundItem?.id;
  } else return null;
};

import styled from "@emotion/styled";
import { ReactNode } from "react";

export const IntroductionSection = ({ children }: { children: ReactNode }) => {
  return (
    <IntroductionContainer>
      <IntroductionTitle>강의 소개</IntroductionTitle>
      {children}
    </IntroductionContainer>
  );
};

const IntroductionContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  img {
    width: 100%;
  }
`;

const IntroductionTitle = styled.div`
  ${({ theme }) => theme.typography.h3.b}
  color: ${({ theme }) => theme.colors.text.high.white}
`;

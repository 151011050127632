import styled from "@emotion/styled";
import {
  loadPaymentWidget,
  PaymentWidgetInstance,
} from "@tosspayments/payment-widget-sdk";
import { useRouter } from "next/router";
import { useEffect, useRef } from "react";

const generateRandomString = (): string => {
  return (
    Math.random().toString(36).substring(2, 15) +
    Math.random().toString(36).substring(2, 15)
  );
};

// TODO: clientKey는 개발자센터의 결제위젯 연동 키 > 클라이언트 키로 바꾸세요.
// TODO: 구매자의 고유 아이디를 불러와서 customerKey로 설정하세요. 이메일・전화번호와 같이 유추가 가능한 값은 안전하지 않습니다.
// @docs https://docs.tosspayments.com/sdk/v2/js#토스페이먼츠-초기화
const clientKey = process.env.NEXT_PUBLIC_TOSS_PAYMENTS_CLIENT_KEY;
const customerKey = generateRandomString();

interface CheckoutProps {
  orderId: string;
  customerData: {
    name: string;
    phoneNumber: string;
    email: string;
  };
  classTitle: string;
  finalPrice: number;
}

export const Checkout = ({
  orderId,
  customerData,
  classTitle,
  finalPrice,
}: CheckoutProps) => {
  const router = useRouter();
  const { courseId, cycleId } = router.query;
  const paymentWidgetRef = useRef<PaymentWidgetInstance | null>(null);
  const paymentMethodsWidgetRef = useRef<ReturnType<
    PaymentWidgetInstance["renderPaymentMethods"]
  > | null>(null);

  useEffect(() => {
    const clientKey = process.env.NEXT_PUBLIC_TOSS_PAYMENTS_CLIENT_KEY ?? "";
    const customerKey = generateRandomString();

    (async () => {
      const paymentWidget = await loadPaymentWidget(clientKey, customerKey);

      const paymentMethodsWidget = paymentWidget.renderPaymentMethods(
        "#payment-widget",
        finalPrice,
      );

      paymentWidgetRef.current = paymentWidget;
      paymentMethodsWidgetRef.current = paymentMethodsWidget;
    })();
  }, []);

  useEffect(() => {
    const paymentMethodsWidget = paymentMethodsWidgetRef.current;

    if (paymentMethodsWidget === null) {
      return;
    }

    paymentMethodsWidget.updateAmount(
      finalPrice,
      // paymentMethodsWidget.UPDATE_REASON.COUPON,
    );
  }, [finalPrice]);

  const handleTossPayments = async () => {
    const paymentWidget = paymentWidgetRef.current;

    try {
      await paymentWidget?.requestPayment({
        orderId: orderId,
        orderName: classTitle,
        customerName: customerData.name,
        customerEmail: customerData.email,
        successUrl: `${process.env.NEXT_PUBLIC_HOST}/payment/success?courseId=${courseId}&cycleId=${cycleId}`,
        failUrl: `${process.env.NEXT_PUBLIC_HOST}/payment/fail?courseId=${courseId}&cycleId=${cycleId}`,
      });
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <CheckoutWrapper>
      <CheckoutBox>
        {/* 결제 UI */}
        <div id="payment-widget" />
        {/* 이용약관 UI */}
        <div id="agreement" />

        {/* 결제하기 버튼 */}
        <ButtonWrapper>
          <TossButton
            className="button"
            // ------ '결제하기' 버튼 누르면 결제창 띄우기 ------
            // @docs https://docs.tosspayments.com/sdk/v2/js#widgetsrequestpayment
            onClick={handleTossPayments}
          >
            결제하기
          </TossButton>
        </ButtonWrapper>
      </CheckoutBox>
    </CheckoutWrapper>
  );
};

const CheckoutWrapper = styled.div`
  width: 100%;
  min-width: 400px;
  max-width: 800px;
  margin: 0 auto;
  ${({ theme }) => theme.media.tabletSm} {
    min-width: 300px;
  }
`;

const CheckoutBox = styled.div``;

const ButtonWrapper = styled.div`
  padding: 24px 0 0;
`;

const TossButton = styled.button`
  color: #f9fafb;
  background-color: #3182f6;
  font-size: 15px;
  font-weight: 600;
  line-height: 18px;
  white-space: nowrap;
  text-align: center;
  cursor: pointer;
  border: 0 solid transparent;
  user-select: none;
  transition:
    background 0.2s ease,
    color 0.1s ease;
  text-decoration: none;
  border-radius: 8px;
  padding: 14px 16px;
  width: 100%;
`;

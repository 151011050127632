import { MouseEvent, ReactNode, useEffect, useState } from "react";
import { createPortal } from "react-dom";
import { useKey } from "react-use";
import classNames from "classnames";
import styled from "@emotion/styled";

import { useLockBodyScroll } from "@shared/lib/hooks/use-lock-body-scroll";
import { Z_INDEX_VALUE } from "@/constants/style/layout";

interface ModalProps {
  isOpen: boolean;
  disabledOutsideClose?: boolean;
  /**
   * ESC 눌렸을 때 모달 닫기 끄기
   */
  disabledEscapeClose?: boolean;
  onClose: () => void;
  className?: string;

  children?: ReactNode;
}

export const Modal = ({
  isOpen = false,
  disabledOutsideClose = false,
  disabledEscapeClose = false,
  onClose,
  className,
  children,
}: ModalProps) => {
  useLockBodyScroll(isOpen);
  useKey(
    "Escape",
    () => {
      if (disabledEscapeClose || !isOpen || disabledOutsideClose) {
        return;
      }

      onClose();
    },
    undefined,
    [disabledEscapeClose, isOpen, disabledEscapeClose],
  );

  const [portalElement, setPortalElement] = useState<Element | null>(null);

  useEffect(() => {
    setPortalElement(document.getElementById("portal"));
  }, []);

  const onClickOutSide = (e: MouseEvent<HTMLDivElement>) => {
    if (disabledOutsideClose) {
      return;
    }

    onClose();
  };

  return (
    portalElement &&
    createPortal(
      <ModalOverlay
        className={classNames({
          ["is-open"]: isOpen,
        })}
        onClick={onClickOutSide}
      >
        <ModalContainer>
          <ModalContentArea className={classNames({}, className)}>
            {children}
          </ModalContentArea>
        </ModalContainer>
      </ModalOverlay>,
      portalElement,
    )
  );
};

const ModalOverlay = styled.div`
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: ${Z_INDEX_VALUE.MODAL_OVERLAY};

  background-color: rgba(0, 0, 0, 0.5);

  &.is-open {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

const ModalContainer = styled.div`
  padding: 12px;

  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: 100%;

  overflow: auto;

  animation-duration: 400ms;
  animation-iteration-count: 1;
  animation-fill-mode: both;
  animation-name: FadeIn;

  @keyframes FadeIn {
    0% {
      opacity: 0;
    }
    1% {
      opacity: 0;
      transform: translateY(100%);
    }
    100% {
      opacity: 1;
    }
  }
`;

const ModalContentArea = styled.div`
  outline: none;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;

  border-radius: 16px;
  background: ${({ theme }) => theme.colors.environment.background.default};
`;

import styled from "@emotion/styled";
import { useRouter } from "next/router";

const Categories = [
  {
    id: 3,
    imageUrl: "/images/main/music.svg",
    title: "음악 입문",
    path: "class/live/music",
  },
  {
    id: 7,
    imageUrl: "/images/main/album.svg",
    title: "음원 발매",
    path: "class/live/album",
  },
  {
    id: 1,
    imageUrl: "/images/main/audiobook.svg",
    title: "오디오북 출간",
    path: "class/live/audiobook",
  },
  {
    id: 5,
    imageUrl: "/images/main/voiceactor.svg",
    title: "성우",
    path: "class/live/voiceactor",
  },
];

export const LiveClassCategory = () => {
  const router = useRouter();

  return (
    <LiveClassCategoryContainer>
      <CategoryWrapper>
        <Title>케아클 라이브 강의</Title>
        <CategorySection>
          {Categories.map((category) => (
            <CategoryItem
              key={category.id}
              onClick={() => router.push(category.path)}
            >
              <CategoryImage
                src={category.imageUrl}
                alt="Category"
              />
              <CategoryName>{category.title}</CategoryName>
            </CategoryItem>
          ))}
        </CategorySection>
      </CategoryWrapper>
    </LiveClassCategoryContainer>
  );
};

const LiveClassCategoryContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  ${({ theme }) => theme.media.tabletSm} {
    padding-bottom: 40px;
  }
`;

const CategoryWrapper = styled.div`
  display: flex;

  flex-direction: column;
  align-items: center;
  gap: 24px;
  padding: 0 16px;
`;

const Title = styled.div`
  ${({ theme }) => theme.typography.h1.b}
  color: ${({ theme }) => theme.colors.text.high.white};
`;

const CategorySection = styled.div`
  display: flex;
  gap: 16px;
`;

const CategoryItem = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  cursor: pointer;
  gap: 8px;
`;

const CategoryImage = styled.img`
  width: 100%;
  object-fit: cover;
`;

const CategoryName = styled.div`
  ${({ theme }) => theme.typography.body2.b}
  color: ${({ theme }) => theme.colors.text.high.white};
  text-align: center;
`;

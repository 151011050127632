import classNames from "classnames";
import styled from "@emotion/styled";

import { Button } from "@shared/lib/components/common/atom/button";
import { Icon } from "@shared/lib/components/common/atom/icon";
import { Modal } from "@shared/lib/components/common/molecules/modal/index";
import { ICON_SVG } from "@shared/lib/constants/import/icon-svg";
import { BUTTON_TYPE } from "@shared/lib/styles/theme";

export const CONFIRM_MODAL_TYPE = {
  INFO: "INFO",
  WARNING: "WARNING",
  ERROR: "ERROR",
  SUCCESS: "SUCCESS",
} as const;
export type ConfirmModalType = keyof typeof CONFIRM_MODAL_TYPE;

export const CONFIRM_BUTTON_TYPE = {
  TEXT: "TEXT",
  BUTTON: "BUTTON",
} as const;
export type ConfirmButtonType = keyof typeof CONFIRM_BUTTON_TYPE;

export interface ConfirmModalProps {
  type: ConfirmModalType;
  confirmButtonType: ConfirmButtonType;
  isOpen: boolean;
  onClose: () => void;
  title?: string;
  subTitle?: string;
  confirmText?: string;
  cancelText?: string;
  onConfirm?: () => void;
  onCancel?: () => void;
}

/**
 * confirmButtonType === TEXT 인 경우 사용자 버튼클릭 유도에 따라 onConfirm의 함수가 모달 닫기로 정의 될 때 가 있음
 * ex) 탈퇴 요청 모달
 * @param type
 * @param confirmButtonType
 * @param isOpen
 * @param onClose
 * @param title
 * @param subTitle
 * @param confirmText
 * @param cancelText
 * @param onConfirm
 * @param onCancel
 * @constructor
 */
export const ConfirmModal = ({
  type,
  confirmButtonType,
  isOpen,
  onClose,
  title,
  subTitle,
  confirmText = "확인",
  cancelText = "취소",
  onConfirm,
  onCancel,
}: ConfirmModalProps) => {
  const confirm = () => {
    onClose();
    onConfirm && onConfirm();
  };

  const cancel = () => {
    onCancel ? onCancel() : onClose();
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
    >
      <ConfirmModalContainer>
        <IconContainer>
          {type === CONFIRM_MODAL_TYPE.INFO && (
            <Icon icon={ICON_SVG.GRAPHIC.MODAL.ICON_INFO} />
          )}
          {type === CONFIRM_MODAL_TYPE.WARNING && (
            <Icon icon={ICON_SVG.GRAPHIC.MODAL.ICON_WARNING} />
          )}
          {type === CONFIRM_MODAL_TYPE.SUCCESS && (
            <Icon icon={ICON_SVG.GRAPHIC.MODAL.ICON_CHECK} />
          )}
          {type === CONFIRM_MODAL_TYPE.ERROR && (
            <Icon icon={ICON_SVG.GRAPHIC.MODAL.ICON_ERROR} />
          )}
        </IconContainer>
        <TitleContainer>
          {title && <Title>{title}</Title>}

          {subTitle && <SubTitle>{subTitle}</SubTitle>}
        </TitleContainer>
        <ConfirmButtonContainer
          className={classNames({
            [`confirm-button-type-${CONFIRM_BUTTON_TYPE.TEXT.toLowerCase()}`]:
              confirmButtonType === CONFIRM_BUTTON_TYPE.TEXT,
          })}
        >
          <ConfirmButton
            styleType={BUTTON_TYPE.FILL}
            onClick={confirm}
          >
            {confirmText}
          </ConfirmButton>
          <CancelButton
            styleType={BUTTON_TYPE.OUTLINE}
            onClick={cancel}
            className={classNames({
              [`confirm-button-type-${CONFIRM_BUTTON_TYPE.TEXT.toLowerCase()}`]:
                confirmButtonType === CONFIRM_BUTTON_TYPE.TEXT,
            })}
          >
            {cancelText}
          </CancelButton>
        </ConfirmButtonContainer>
      </ConfirmModalContainer>
    </Modal>
  );
};

const ConfirmModalContainer = styled.div`
  min-width: 404px;
  padding: 32px;
  display: flex;
  flex-direction: column;
  gap: 16px;

  ${({ theme }) => theme.media.mobile} {
    width: 100%;
    max-width: 343px;
    min-width: auto;
  }
`;

const IconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 4px;
`;

//TODO: 컬러값이 디자인시스템에 없음 추가 될시 변경해야됨
const Title = styled.div`
  text-align: center;
  ${({ theme }) => theme.typography.h3.b};
  color: #000;
`;

const SubTitle = styled.div`
  text-align: center;
  ${({ theme }) => theme.typography.body2.m};
  color: ${({ theme }) => theme.colors.text.medium.black};
  white-space: pre-line;
`;

const ConfirmButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  &.confirm-button-type-${CONFIRM_BUTTON_TYPE.TEXT.toLowerCase()} {
    gap: 16px;
    flex-direction: column;
  }
`;

const ConfirmButton = styled(Button)`
  min-width: 180px;

  &.btn-${BUTTON_TYPE.FILL.toLowerCase()} {
    border-radius: 8px;
  }
`;

const CancelButton = styled(Button)`
  min-width: 180px;

  &.btn-${BUTTON_TYPE.OUTLINE.toLowerCase()} {
    &.confirm-button-type-${CONFIRM_BUTTON_TYPE.TEXT.toLowerCase()} {
      border: 0;
      ${({ theme }) => theme.typography.body1.b};
      color: ${({ theme }) => theme.colors.text.medium.black};
      padding: 0;

      &:hover {
        background-color: transparent;
      }
    }

    &.confirm-button-type-${CONFIRM_BUTTON_TYPE.BUTTON.toLowerCase()} {
      border-radius: 8px;
    }
  }
`;

import { ChangeEvent, useMemo, useState } from "react";
import { SubmitErrorHandler, SubmitHandler, useForm } from "react-hook-form";
import { useRouter } from "next/router";
import { getFormattedPhoneNumber } from "teachers-room/src/utils/string";
import * as yup from "yup";
import styled from "@emotion/styled";
import { yupResolver } from "@hookform/resolvers/yup";

import { Button } from "@shared/lib/components/common/atom/button";
import { FileUploadButton } from "@shared/lib/components/common/atom/button/file-upload-button";
import { CheckBox } from "@shared/lib/components/common/atom/check-box";
import { MultiSelect } from "@shared/lib/components/common/atom/select/multi-select";
import { TextField } from "@shared/lib/components/common/atom/text-feild";
import { MultiLineTextField } from "@shared/lib/components/common/atom/text-feild/multi-line-text-field";
import {
  ALERT_MODAL_TYPE,
  AlertModal,
  AlertModalType,
} from "@shared/lib/components/common/molecules/modal/alert-modal";
import { IMAGES_ACCEPT } from "@shared/lib/constants/file";
import { BUTTON_TYPE } from "@shared/lib/styles/theme";
import { MAX_WIDTH } from "@/constants/style/layout";
import { useAccessToken } from "@/hooks/auth/use-access-token";
import { useProfile } from "@/hooks/auth/use-profile";

import { useGenreControllerGenres } from "@shared/generated/api/fn/kac/genre/genre";
import { KacApiCommonErrorDto } from "@shared/lib/types/api";
import { useInstructorControllerApplyForInstructor } from "@shared/generated/api/fn/kac/instructor/instructor";
import { fileControllerGetSignedUrl } from "@shared/generated/api/fn/kac/file/file";
import { FileControllerGetSignedUrlParams } from "@shared/generated/api/model";
import { uploadFileToGCP } from "@/components/common/features/cloud-storage/upload-file";

const ARTIST_NAME_MAX_LENGTH = 30;
const INTRODUCE_WITH_CAREER_MAX_LENGTH = 5000;

interface ApplyForm {
  artistName: string;
  genreIds: string[];
  summary: string;
  description: string;
  experience: string;
  isPrivateAgree: boolean;
}

export const TeacherApplyTemplate = () => {
  const { data: profile } = useProfile();
  const { accessToken } = useAccessToken();
  const router = useRouter();
  const [profileImageFile, setProfileImageFile] = useState<{
    fileUrl?: string;
    filePath?: string;
  }>();

  const [isAlertModalOpen, setIsAlertModalOpen] = useState(false);

  const [alertModalTexts, setAlertModalTexts] = useState<{
    type: AlertModalType;
    title?: string;
    subTitle?: string;
  }>({
    type: ALERT_MODAL_TYPE.ERROR,
    title: "",
    subTitle: "",
  });

  const {
    data: teacherClassCategory,
    isLoading: isLoadingGetTeacherClassCategory,
  } = useGenreControllerGenres({
    request: {
      accessToken,
    },
  });

  // console.log("here genres", teacherClassCategory);

  const teacherClassCategoryOptions = useMemo(
    () =>
      teacherClassCategory?.map((category) => ({
        label: category.name || "",
        value: String(category.id) || "",
      })),
    [teacherClassCategory],
  );

  const insertImage = (fileName: string) => {
    const baseUrl = process.env.NEXT_PUBLIC_STORAGE_HOST;
    const fileUrl = `${baseUrl}/users/image/${fileName}`;
    setProfileImageFile((prev) => ({ ...prev, fileUrl }));
  };

  const onChangeProfileImgFile = async (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (!file) return;
    if (file && accessToken) {
      try {
        const params: FileControllerGetSignedUrlParams = {
          scope: "public",
          originalFilename: file.name,
          resourceType: "users",
          fileType: "image",
        };
        // 1. 백엔드에서 presigned URL 요청
        const { url, filePath } = await fileControllerGetSignedUrl(params, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });
        console.log(url, filePath);

        if (filePath) setProfileImageFile((prev) => ({ ...prev, filePath }));
        // 2. presigned URL을 사용하여 GCP에 이미지 업로드
        const status = await uploadFileToGCP(url as string, file);

        const pathArr = filePath.split("/");
        const fileName = pathArr[pathArr.length - 1];
        if (status === 200) insertImage(fileName);
      } catch (error) {
        console.error("Error uploading image:", error);
      }
    }
  };

  const teacherApplyMutation = useInstructorControllerApplyForInstructor({
    request: {
      accessToken,
    },
    mutation: {
      onSuccess: () => {
        setAlertModalTexts({
          type: ALERT_MODAL_TYPE.SUCCESS,
          title: "강사 지원이 완료되었습니다!",
          subTitle: "빠른 시일내에 전담 매니저가 연락드리겠습니다.",
        });
        setIsAlertModalOpen(true);
      },
      onError: (error) => {
        const { errorCode, message } = error.response
          ?.data as KacApiCommonErrorDto;
        console.log(errorCode, message);
        if (errorCode === 100006) {
          setAlertModalTexts({
            type: ALERT_MODAL_TYPE.ERROR,
            title: "이미 강사로 지원하셨습니다.",
            subTitle: "빠른 시일 내에 전담 매니저가 연락드리겠습니다.",
          });
          setIsAlertModalOpen(true);
        } else {
          setAlertModalTexts({
            type: ALERT_MODAL_TYPE.ERROR,
            title: "데이터 저장에 실패하였습니다.",
          });
          setIsAlertModalOpen(true);
        }
      },
    },
  });

  const schema = yup.object({
    artistName: yup.string().required("강사명을 입력해 주세요."),
    genreIds: yup
      .array(yup.string().required())
      .min(1, "강의 장르를 선택해주세요.")
      .required("진행할 강의 장르를 선택해주세요.(복수 선택 가능)"),
    summary: yup.string().required("강사 한줄 성명을 입력해 주세요."),
    description: yup.string().required("강사 소개를 입력해 주세요."),
    experience: yup.string().required("강의 경력을 입력해주세요."),
    isPrivateAgree: yup.boolean().required().isTrue(),
  });

  const {
    handleSubmit,
    watch,
    setValue,
    getValues,
    setError,
    register,
    trigger,
    clearErrors,
    formState: { errors, isValid },
  } = useForm<ApplyForm>({
    resolver: yupResolver<ApplyForm>(schema),
    mode: "onChange",
  });

  const artistName = watch("artistName");
  const summary = watch("summary");
  const genreIds = watch("genreIds");
  const description = watch("description");
  const experience = watch("experience");
  const isPrivateAgree = watch("isPrivateAgree");

  const onChangeGenreIds = async (genreIds: string[]) => {
    console.log(genreIds);
    setValue("genreIds", genreIds);
    await trigger("genreIds");
  };

  const onChangeIsPrivateAgree = async (isAgree: boolean) => {
    setValue("isPrivateAgree", isAgree);
    await trigger("isPrivateAgree");
  };

  const onSubmit: SubmitHandler<ApplyForm> = async (data) => {
    const numberIdsArr = data.genreIds.map((id) => Number(id));
    teacherApplyMutation.mutate({
      data: {
        artistName: data.artistName,
        profileImage: profileImageFile?.filePath,
        genreIds: numberIdsArr,
        username: profile?.name || "",
        summary: data.summary,
        description: data.description,
        experience: data.experience,
      },
    });
  };

  const onError: SubmitErrorHandler<ApplyForm> = async (error) => {
    console.error(error);
  };

  const onAlertModalClose = () => {
    if (!teacherApplyMutation.isError && teacherApplyMutation.isSuccess) {
      router.push("/");
    }

    setIsAlertModalOpen(false);
  };

  return (
    <TeacherApplyTemplateContainer>
      <TeacherApplyTemplateInnerContainer>
        <TeacherApplyCardContainer>
          <TeacherApplyTitleContainer>강사지원</TeacherApplyTitleContainer>
          <TeacherProfileImageContainer>
            <TeacherProfileImage>
              <img
                src={
                  profileImageFile?.fileUrl
                    ? profileImageFile?.fileUrl
                    : "/images/avatar86.svg"
                }
                alt={"강사 프로필 이미지"}
              />
            </TeacherProfileImage>
            <FileUploadButton
              accept={IMAGES_ACCEPT.toString()}
              onChange={onChangeProfileImgFile}
            >
              이미지 업로드
            </FileUploadButton>
          </TeacherProfileImageContainer>

          <form onSubmit={handleSubmit(onSubmit, onError)}>
            <TeacherInfoContainer>
              <TeacherInfo>
                <TeacherInfoTitle>기본 정보</TeacherInfoTitle>
                <TeacherApplyFormInputContainer>
                  <TeacherApplyFormTextField
                    type="text"
                    label={"이름(실명)"}
                    value={profile?.name || ""}
                    readOnly
                    isRequired
                  />
                  <TeacherApplyFormTextField
                    type="text"
                    label={"이메일"}
                    value={profile?.email}
                    readOnly
                    isRequired
                  />
                  <TeacherApplyFormTextField
                    type="text"
                    label={"휴대폰 번호"}
                    value={getFormattedPhoneNumber(profile?.phoneNumber || "")}
                    readOnly
                    isRequired
                  />
                </TeacherApplyFormInputContainer>
              </TeacherInfo>
              <TeacherInfo>
                <TeacherInfoTitle>강사 정보</TeacherInfoTitle>
                <TeacherApplyFormInputContainer>
                  <TeacherApplyFormTextField
                    type="text"
                    label={"강사명"}
                    isRequired
                    isLengthLabel
                    maxLength={ARTIST_NAME_MAX_LENGTH}
                    {...register("artistName")}
                    isError={!!errors.artistName}
                    value={artistName}
                    placeholder={"강사명을 입력해 주세요."}
                  />
                </TeacherApplyFormInputContainer>
                <TeacherApplyFormInputContainer>
                  <TeacherApplyFormTextField
                    type="text"
                    label={"강사 한줄 설명"}
                    isRequired
                    isLengthLabel
                    maxLength={ARTIST_NAME_MAX_LENGTH}
                    {...register("summary")}
                    isError={!!errors.summary}
                    value={summary}
                    placeholder={"강사 한줄 설명을 입력해 주세요."}
                  />
                </TeacherApplyFormInputContainer>

                <TeacherApplyFormMultiSelect
                  label={"강의 장르"}
                  isRequired
                  isError={!!errors.genreIds}
                  message={errors.genreIds?.message}
                  placeholder={
                    "진행할 강의 장르를 선택해 주세요. (복수 선택 가능)"
                  }
                  options={teacherClassCategoryOptions || []}
                  onChange={onChangeGenreIds}
                  value={genreIds}
                />

                <TeacherApplyFormMultiTextField
                  label={"강사 소개"}
                  isRequired
                  isLengthLabel
                  maxLength={INTRODUCE_WITH_CAREER_MAX_LENGTH}
                  {...register("description")}
                  value={description}
                  isError={!!errors.description}
                  message={errors.description?.message}
                  placeholder={
                    "강사님에 대해 소개해 주세요. (주요 경력 및 활동, 작업물 등)"
                  }
                />

                <TeacherApplyFormMultiTextField
                  label={"강의 경력"}
                  isRequired
                  isLengthLabel
                  maxLength={INTRODUCE_WITH_CAREER_MAX_LENGTH}
                  {...register("experience")}
                  value={experience}
                  isError={!!errors.experience}
                  message={errors.experience?.message}
                  placeholder={`그 동안 어떤 강의를 진행하셨는지 자세히 소개해 주세요.\n(강의 주제, 강의 경력, 주로 사용하는 강의 도구 등)`}
                />
              </TeacherInfo>
            </TeacherInfoContainer>

            <AgreeContainer>
              <AgreeCheckBoxContainer>
                <CheckBox
                  id={"private-checkbox"}
                  isError={!!errors.isPrivateAgree}
                  onChange={() => onChangeIsPrivateAgree(!isPrivateAgree)}
                  checked={isPrivateAgree}
                />
                <span onClick={() => onChangeIsPrivateAgree(!isPrivateAgree)}>
                  개인정보 수집 및 이용 동의 (필수)
                </span>
              </AgreeCheckBoxContainer>

              <AgreeDivider />

              <AgreeDescription>
                <span>
                  {`[K-ARTIST CLASS 강사 지원을 위한 개인정보 활용 동의]\nK-ARTIST CLASS는 귀하의 개인정보를 수집/이용하기 위해 아래와 같이 ‘개인정보보호법 제15조에 따라 동의를 구합니다.\n\n- 개인정보 수집/이용 항목: 이름, 이메일 주소, 생년월일, 성별, 휴대폰 번호\n- 개인정보 수집/이용 목적: K-ARTIST CLASS 강사 지원\n- 개인정보 보유 및 이용 기간: K-ARTIST CLASS에서 강의가 서비스되는 동안 보유 및 이용하며, 개인정보 수집 및 이용 목적이 달성되면 수집된 개인정보는 파기됩니다.\n\n*개인정보 수집 동의를 거부할 권리가 있으며, 거부할 경우 강사로 지원하실 수 없습니다. 기재해 주신 개인정보는 K-ARTIST CLASS 내부에서 강사 지원을 위한 용도로만 활용됩니다.\n*상기 사항에 대하여 동의를 하지 않을 경우 K-ARTIST CLASS 강사 지원이 불가함을 알려드립니다.\n`}
                </span>
              </AgreeDescription>
            </AgreeContainer>

            <ApplyButtonContainer>
              <ApplyButton
                styleType={BUTTON_TYPE.FILL}
                type={"submit"}
                disabled={
                  !isValid ||
                  teacherApplyMutation.isPending ||
                  // fileUploadMutation.isPending ||
                  isLoadingGetTeacherClassCategory
                }
              >
                강사 지원하기
              </ApplyButton>
            </ApplyButtonContainer>
          </form>

          {isAlertModalOpen && (
            <AlertModal
              {...alertModalTexts}
              isOpen={isAlertModalOpen}
              onClose={onAlertModalClose}
            />
          )}
        </TeacherApplyCardContainer>
      </TeacherApplyTemplateInnerContainer>
    </TeacherApplyTemplateContainer>
  );
};

const TeacherApplyTemplateContainer = styled.div`
  width: 100%;
  height: 100%;
  min-height: 50vh;

  display: flex;
  align-items: center;
  justify-content: center;
`;

const TeacherApplyTemplateInnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  padding: 96px 24px;
  width: 100%;
  max-width: ${MAX_WIDTH.INNER_CONTAINER.PC}px;

  ${({ theme }) => theme.media.mobile} {
    padding: 96px 16px;
  }
`;

const TeacherApplyTitleContainer = styled.div`
  ${({ theme }) => theme.typography.h1.b};
  color: ${({ theme }) => theme.colors.text.high.black};

  text-align: center;
`;

const TeacherApplyCardContainer = styled.div`
  display: flex;
  width: 100%;
  max-width: 520px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 48px;

  form {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
`;

const TeacherProfileImageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 8px;
`;

const TeacherProfileImage = styled.div`
  width: 86px;
  height: 86px;
  border-radius: 50%;
  overflow: hidden;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

const TeacherInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 32px;
`;

const TeacherInfo = styled.div`
  display: flex;
  padding: 24px;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;

  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.colors.gray.gray80};
`;

const TeacherInfoTitle = styled.div`
  ${({ theme }) => theme.typography.h2.b};
  color: ${({ theme }) => theme.colors.text.high.black};
`;

const TeacherApplyFormInputContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
`;

const TeacherApplyFormTextField = styled(TextField)`
  gap: 4px;
  flex: 1 0 0;
`;

const TeacherApplyFormMultiTextField = styled(MultiLineTextField)`
  flex: 1 0 0;

  &::placeholder {
    white-space: pre-line;
  }
`;

const TeacherApplyFormMultiSelect = styled(MultiSelect)`
  flex: 1 0 0;
`;

const AgreeContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: center;
  justify-content: center;
`;

const AgreeCheckBoxContainer = styled.div`
  margin-top: 32px;

  width: 100%;
  display: flex;
  align-items: center;
  gap: 4px;

  span {
    ${({ theme }) => theme.typography.body2.m};
    color: ${({ theme }) => theme.colors.text.high.black};
  }
`;

const AgreeDivider = styled.div`
  width: 100%;
  height: 1px;
  background: #e6e6e6;
`;

const AgreeDescription = styled.div`
  padding-left: 28px;
  span {
    white-space: pre-line;
    ${({ theme }) => theme.typography.body2.r};
    color: ${({ theme }) => theme.colors.text.medium.black};
  }
`;

const ApplyButtonContainer = styled.div`
  margin-top: 72px;
`;

const ApplyButton = styled(Button)`
  width: 100%;

  &.btn-fill {
    border-radius: 4px;
  }
`;

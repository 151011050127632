/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * KAC-API
 * K-Artist Class API
 * OpenAPI spec version: 0.0.1
 */

/**
 * 강의 타입 - 원데이:SINGLE, 다회차:MULTI
 */
export type CourseContentsDtoCourseType =
  (typeof CourseContentsDtoCourseType)[keyof typeof CourseContentsDtoCourseType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CourseContentsDtoCourseType = {
  SINGLE: "SINGLE",
  MULTI: "MULTI",
  SUBSCRIPTION: "SUBSCRIPTION",
} as const;

import styled from "@emotion/styled";
import { ChangeEvent } from "react";

interface TextAreaProps {
  placeholder?: string;
  value: string;
  maxLength: number;
  onChange: (e: ChangeEvent<HTMLTextAreaElement>) => void;
}

export const CustomTextArea = ({
  placeholder,
  value,
  maxLength,
  onChange,
}: TextAreaProps) => {
  return (
    <>
      <StyledTextarea
        placeholder={placeholder}
        value={value}
        maxLength={maxLength}
        onChange={onChange}
      />
      <LengthText>
        {value.length}/{maxLength}
      </LengthText>
    </>
  );
};

const StyledTextarea = styled.textarea`
  ${({ theme }) => theme.typography.body1.m};
  height: 200px;
  width: 100%;
  border-radius: 4px;
  color: ${({ theme }) => theme.colors.brand.white.default};
  border: 1px solid ${({ theme }) => theme.colors.text.disabled.white};
  padding: 10px;
  transition: border-color 0.3s ease;
  background-color: transparent;
  box-sizing: border-box;
  resize: none;
  overflow-y: auto;
  word-wrap: break-word;
  white-space: pre-wrap;

  &:focus {
    outline: none;
    border: 1px solid ${({ theme }) => theme.colors.brand.white.default};
  }

  &::placeholder {
    color: ${({ theme }) => theme.colors.text.disabled.white};
  }
`;

const LengthText = styled.div`
  ${({ theme }) => theme.typography.body3.m};
  color: ${({ theme }) => theme.colors.text.medium.white};
  text-align: right;
  padding-bottom: 8px;
`;

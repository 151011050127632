import { useMemo } from "react";
import { useRouter } from "next/router";
import { Theme } from "@emotion/react";
import styled from "@emotion/styled";

import { Icon } from "@shared/lib/components/common/atom/icon";
import { ICON_SVG } from "@shared/lib/constants/import/icon-svg";
import { decoding } from "@shared/lib/utils/crypto";
import { MAX_WIDTH } from "@/constants/style/layout";
import { RegistedUserConnectQuery } from "@/types/auth";

import { SignUpRequestDtoSocialType } from "@shared/generated/api/model";

export const ConnectUserTemplate = () => {
  const router = useRouter();
  const { user } = router.query;
  const userDecoded: RegistedUserConnectQuery | undefined = useMemo(() => {
    if (user) {
      return JSON.parse(decoding(user as string));
    }
  }, [user]);

  const providers = [
    {
      type: SignUpRequestDtoSocialType.NAVER,
      icon: ICON_SVG.GRAPHIC.LOGIN.ICON_NAVER,
      label: "네이버로 시작하기",
      themeKey: "naver" as const,
    },
    {
      type: SignUpRequestDtoSocialType.GOOGLE,
      icon: ICON_SVG.GRAPHIC.LOGIN.ICON_GOOGLE,
      label: "구글로 시작하기",
      themeKey: "google" as const,
    },
    {
      type: SignUpRequestDtoSocialType.FACEBOOK,
      icon: ICON_SVG.GRAPHIC.LOGIN.ICON_FACEBOOK,
      label: "페이스북으로 시작하기",
      themeKey: "facebook" as const,
    },
    {
      type: SignUpRequestDtoSocialType.APPLE,
      icon: ICON_SVG.GRAPHIC.LOGIN.ICON_APPLE,
      label: "애플로 시작하기",
      themeKey: "apple" as const,
    },
    {
      type: SignUpRequestDtoSocialType.KAKAO,
      icon: ICON_SVG.GRAPHIC.LOGIN.ICON_KAKAO,
      label: "카카오톡으로 시작하기",
      themeKey: "kakao" as const,
    },
  ];

  const onSnsLogin = async (type: string) => {
    const baseUrl = process.env.NEXT_PUBLIC_BACKEND_HOST;
    window.location.href = `${baseUrl}/auth/${type}`;
  };

  const getProviderTypeText = (providerType?: SignUpRequestDtoSocialType) => {
    if (providerType === SignUpRequestDtoSocialType.KAKAO) return "카카오 연동";
    if (providerType === SignUpRequestDtoSocialType.APPLE) return "애플 연동";
    if (providerType === SignUpRequestDtoSocialType.FACEBOOK)
      return "페이스북 연동";
    if (providerType === SignUpRequestDtoSocialType.GOOGLE) return "구글 연동";
    if (providerType === SignUpRequestDtoSocialType.NAVER) return "네이버 연동";
  };

  return (
    <ConnectUserTemplateContainer>
      <ConnectUserTemplateInnerContainer>
        <ConnectUserCardContainer>
          <TitleContainer>
            <Title>이미 가입한 계정이 있어요</Title>
            <SubTitle>내 정보가 맞다면 이 계정으로 로그인하세요</SubTitle>
          </TitleContainer>
          <ConnectUserInfoContainer>
            <ConnectUserInfoCard>
              <Avatar>
                <Icon icon={ICON_SVG.GRAPHIC.AVATAR.ICON_USER} />
              </Avatar>
              <UserInfo>
                <UserInfoName>{userDecoded?.name}</UserInfoName>
                <UserInfoDescription>
                  <p>{userDecoded?.email}</p>
                  <p>{userDecoded?.phone}</p>
                  <p>{getProviderTypeText(userDecoded?.providerType)}</p>
                </UserInfoDescription>
              </UserInfo>
            </ConnectUserInfoCard>
            {providers.map(
              ({ type, icon, label, themeKey }) =>
                userDecoded?.providerType === type && (
                  <SnsLoginButton
                    key={type}
                    themeKey={themeKey}
                    onClick={() => onSnsLogin(type)}
                  >
                    <Icon icon={icon} /> {label}
                  </SnsLoginButton>
                ),
            )}
          </ConnectUserInfoContainer>
        </ConnectUserCardContainer>
      </ConnectUserTemplateInnerContainer>
    </ConnectUserTemplateContainer>
  );
};

const ConnectUserTemplateContainer = styled.div`
  width: 100%;
  height: 100%;
  min-height: 50vh;

  display: flex;
  align-items: center;
  justify-content: center;
`;

const ConnectUserTemplateInnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  width: 100%;
  max-width: ${MAX_WIDTH.INNER_CONTAINER.PC}px;

  padding: 96px 24px;

  ${({ theme }) => theme.media.mobile} {
    padding: 96px 16px;
  }
`;

const ConnectUserCardContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;
  align-items: center;
  width: 100%;
`;

const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
`;

const Title = styled.div`
  ${({ theme }) => theme.typography.h1.b};
  color: ${({ theme }) => theme.colors.text.high.black};
`;

const SubTitle = styled.div`
  ${({ theme }) => theme.typography.body2.b};
  color: ${({ theme }) => theme.colors.text.medium.black};
`;

const ConnectUserInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;

  width: 100%;
  max-width: 365px;
`;

const ConnectUserInfoCard = styled.div`
  width: 100%;

  border-radius: 4px;
  background: ${({ theme }) => theme.colors.gray.gray95};

  display: flex;
  padding: 24px;
  align-items: flex-start;
  gap: 16px;
`;

const Avatar = styled.div``;

const UserInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

const UserInfoName = styled.div`
  ${({ theme }) => theme.typography.h2.b};

  color: ${({ theme }) => theme.colors.text.high.black};
`;

const UserInfoDescription = styled.div`
  p {
    margin: 0;
    padding: 0;
    ${({ theme }) => theme.typography.body1.m};
    color: ${({ theme }) => theme.colors.text.medium.black};
  }
`;

const SnsLoginButton = styled.button<{
  themeKey: keyof Theme["colors"]["sns"];
}>`
  width: 100%;

  border-radius: 4px;
  border: 1px solid;

  display: flex;
  height: 48px;
  padding: 0 16px;
  justify-content: center;
  align-items: center;
  gap: 24px;
  align-self: stretch;

  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px; /* 128.571% */
  letter-spacing: -0.4px;

  color: ${({ theme, themeKey }) => theme.colors.sns[themeKey].text};
  border-color: ${({ theme, themeKey }) =>
    themeKey === "google"
      ? theme.colors.gray.gray80
      : theme.colors.sns[themeKey].bg};
  background-color: ${({ theme, themeKey }) => theme.colors.sns[themeKey].bg};
`;

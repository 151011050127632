import styled from "@emotion/styled";

import { BenefitsSection } from "@/components/domain/teacher/support/benefits-section";
import { ClassCategorySection } from "@/components/domain/teacher/support/class-category-section";
import { ClassProgressSection } from "@/components/domain/teacher/support/class-progress-section";
import { ProcedureSection } from "@/components/domain/teacher/support/procedure-section";
import { QnaSection } from "@/components/domain/teacher/support/qna-section";
import { StudentReviewSection } from "@/components/domain/teacher/support/student-review-section";
import { TeacherApplySection } from "@/components/domain/teacher/support/teacher-apply-section";
import { TeacherReviewSection } from "@/components/domain/teacher/support/teacher-review-section";
import { WithSection } from "@/components/domain/teacher/support/with-section";

export const TeacherSupportTemplate = () => (
  <TeacherSupportTemplateContainer>
    {/*모집 섹션*/}
    <WithSection />

    {/*혜택 섹션*/}
    <BenefitsSection />

    {/*과정 섹션*/}
    <ProcedureSection />

    {/*선생님 후기*/}
    <TeacherReviewSection />

    {/*학생 후기*/}
    <StudentReviewSection />

    {/*수업 유형*/}
    <ClassCategorySection />

    {/*수업 과정*/}
    <ClassProgressSection />

    {/*자주 묻는 질문*/}
    <QnaSection />

    {/*강사 등록하기 */}
    <TeacherApplySection />
  </TeacherSupportTemplateContainer>
);

const TeacherSupportTemplateContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

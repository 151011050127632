import { ReactNode } from "react";
import styled from "@emotion/styled";

interface LoginCardProps {
  children: ReactNode;
}

export const LoginCard = ({ children }: LoginCardProps) => (
  <LoginCardContainer>{children}</LoginCardContainer>
);

const LoginCardContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 365px;

  gap: 40px;

  ${({ theme }) => theme.media.mobile} {
    width: 100%;
    max-width: 343px;
  }
`;

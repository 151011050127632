import { useEffect } from "react";
import { useRouter } from "next/router";

import { MENU } from "@/constants/menu";
import { useSession } from "@/hooks/auth/use-session";

/**
 * 토큰 로테이션 시 에러가 발생한 경우 클라이언트 사이드에서 감지하여
 * 로그인 페이지 등으로 이동시키는 컴포넌트
 */
export const RefreshAccessTokenErrorObserver = () => {
  const router = useRouter();

  /**
   * refresh access token error 검사
   */
  const { data: session } = useSession();

  useEffect(() => {
    if (!session?.error) {
      return;
    }

    if (router.asPath.startsWith(MENU.LOGIN.link.url)) {
      return;
    }

    router.push(MENU.LOGIN.link.url, {
      query: {
        callbackUrl: router.asPath,
      },
    });
  }, [session, router]);

  return null;
};

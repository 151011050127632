/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * KAC-API
 * K-Artist Class API
 * OpenAPI spec version: 0.0.1
 */
import { useMutation, useQuery } from "@tanstack/react-query";
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult,
} from "@tanstack/react-query";
import type {
  CourseWishlistResponseDto,
  CreateWithdrawRequestDto,
  InquiriesListResponseDto,
  PaginatedCoursesResponseDto,
  PaginatedUserPointLogsResponseDto,
  StudentScheduleResponseDto,
  UpdateUserRequestWithNewPasswordDto,
  UserControllerCourseWishlistParams,
  UserControllerCoursesParams,
  UserControllerInquiriesParams,
  UserControllerPointHistoryParams,
  UserControllerSchedulesParams,
  UserPointResponseDto,
  UserResponseDto,
  VerifyPasswordReqDto,
} from "../../../model";
import { axiosInstance } from "../../../config/axios-instance";
import type { ErrorType } from "../../../config/axios-instance";

type AwaitedInput<T> = PromiseLike<T> | T;

type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];

/**
 * @summary 유저 정보 조회(with token)
 */
export const userControllerGetProfile = (
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal,
) => {
  return axiosInstance<UserResponseDto>(
    { url: `/user/profile`, method: "GET", signal },
    options,
  );
};

export const getUserControllerGetProfileQueryKey = () => {
  return [`/user/profile`] as const;
};

export const getUserControllerGetProfileQueryOptions = <
  TData = Awaited<ReturnType<typeof userControllerGetProfile>>,
  TError = ErrorType<UserResponseDto>,
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof userControllerGetProfile>>,
    TError,
    TData
  >;
  request?: SecondParameter<typeof axiosInstance>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getUserControllerGetProfileQueryKey();

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof userControllerGetProfile>>
  > = ({ signal }) => userControllerGetProfile(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof userControllerGetProfile>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type UserControllerGetProfileQueryResult = NonNullable<
  Awaited<ReturnType<typeof userControllerGetProfile>>
>;
export type UserControllerGetProfileQueryError = ErrorType<UserResponseDto>;

/**
 * @summary 유저 정보 조회(with token)
 */
export const useUserControllerGetProfile = <
  TData = Awaited<ReturnType<typeof userControllerGetProfile>>,
  TError = ErrorType<UserResponseDto>,
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof userControllerGetProfile>>,
    TError,
    TData
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getUserControllerGetProfileQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary 유저 비밀번호 재설정
 */
export const userControllerVerifyPassword = (
  verifyPasswordReqDto: VerifyPasswordReqDto,
  options?: SecondParameter<typeof axiosInstance>,
) => {
  return axiosInstance<boolean>(
    {
      url: `/user/reset-password`,
      method: "POST",
      headers: { "Content-Type": "application/json" },
      data: verifyPasswordReqDto,
    },
    options,
  );
};

export const getUserControllerVerifyPasswordMutationOptions = <
  TError = ErrorType<boolean>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof userControllerVerifyPassword>>,
    TError,
    { data: VerifyPasswordReqDto },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof userControllerVerifyPassword>>,
  TError,
  { data: VerifyPasswordReqDto },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof userControllerVerifyPassword>>,
    { data: VerifyPasswordReqDto }
  > = (props) => {
    const { data } = props ?? {};

    return userControllerVerifyPassword(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type UserControllerVerifyPasswordMutationResult = NonNullable<
  Awaited<ReturnType<typeof userControllerVerifyPassword>>
>;
export type UserControllerVerifyPasswordMutationBody = VerifyPasswordReqDto;
export type UserControllerVerifyPasswordMutationError = ErrorType<boolean>;

/**
 * @summary 유저 비밀번호 재설정
 */
export const useUserControllerVerifyPassword = <
  TError = ErrorType<boolean>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof userControllerVerifyPassword>>,
    TError,
    { data: VerifyPasswordReqDto },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof userControllerVerifyPassword>>,
  TError,
  { data: VerifyPasswordReqDto },
  TContext
> => {
  const mutationOptions =
    getUserControllerVerifyPasswordMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary 수강생의 내 클래스 조회
 */
export const userControllerCourses = (
  params?: UserControllerCoursesParams,
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal,
) => {
  return axiosInstance<PaginatedCoursesResponseDto>(
    { url: `/user/me/course`, method: "GET", params, signal },
    options,
  );
};

export const getUserControllerCoursesQueryKey = (
  params?: UserControllerCoursesParams,
) => {
  return [`/user/me/course`, ...(params ? [params] : [])] as const;
};

export const getUserControllerCoursesQueryOptions = <
  TData = Awaited<ReturnType<typeof userControllerCourses>>,
  TError = ErrorType<PaginatedCoursesResponseDto>,
>(
  params?: UserControllerCoursesParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof userControllerCourses>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getUserControllerCoursesQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof userControllerCourses>>
  > = ({ signal }) => userControllerCourses(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof userControllerCourses>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type UserControllerCoursesQueryResult = NonNullable<
  Awaited<ReturnType<typeof userControllerCourses>>
>;
export type UserControllerCoursesQueryError =
  ErrorType<PaginatedCoursesResponseDto>;

/**
 * @summary 수강생의 내 클래스 조회
 */
export const useUserControllerCourses = <
  TData = Awaited<ReturnType<typeof userControllerCourses>>,
  TError = ErrorType<PaginatedCoursesResponseDto>,
>(
  params?: UserControllerCoursesParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof userControllerCourses>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getUserControllerCoursesQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary 수강생의 찜한 클래스 조회
 */
export const userControllerCourseWishlist = (
  params?: UserControllerCourseWishlistParams,
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal,
) => {
  return axiosInstance<PaginatedCoursesResponseDto>(
    { url: `/user/me/course/wishlist`, method: "GET", params, signal },
    options,
  );
};

export const getUserControllerCourseWishlistQueryKey = (
  params?: UserControllerCourseWishlistParams,
) => {
  return [`/user/me/course/wishlist`, ...(params ? [params] : [])] as const;
};

export const getUserControllerCourseWishlistQueryOptions = <
  TData = Awaited<ReturnType<typeof userControllerCourseWishlist>>,
  TError = ErrorType<PaginatedCoursesResponseDto>,
>(
  params?: UserControllerCourseWishlistParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof userControllerCourseWishlist>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getUserControllerCourseWishlistQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof userControllerCourseWishlist>>
  > = ({ signal }) =>
    userControllerCourseWishlist(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof userControllerCourseWishlist>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type UserControllerCourseWishlistQueryResult = NonNullable<
  Awaited<ReturnType<typeof userControllerCourseWishlist>>
>;
export type UserControllerCourseWishlistQueryError =
  ErrorType<PaginatedCoursesResponseDto>;

/**
 * @summary 수강생의 찜한 클래스 조회
 */
export const useUserControllerCourseWishlist = <
  TData = Awaited<ReturnType<typeof userControllerCourseWishlist>>,
  TError = ErrorType<PaginatedCoursesResponseDto>,
>(
  params?: UserControllerCourseWishlistParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof userControllerCourseWishlist>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getUserControllerCourseWishlistQueryOptions(
    params,
    options,
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary 수강생 > 클래스 찜하기
 */
export const userControllerAddToWishlist = (
  courseId: number,
  options?: SecondParameter<typeof axiosInstance>,
) => {
  return axiosInstance<void>(
    { url: `/user/me/course/wishlist/${courseId}`, method: "POST" },
    options,
  );
};

export const getUserControllerAddToWishlistMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof userControllerAddToWishlist>>,
    TError,
    { courseId: number },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof userControllerAddToWishlist>>,
  TError,
  { courseId: number },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof userControllerAddToWishlist>>,
    { courseId: number }
  > = (props) => {
    const { courseId } = props ?? {};

    return userControllerAddToWishlist(courseId, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type UserControllerAddToWishlistMutationResult = NonNullable<
  Awaited<ReturnType<typeof userControllerAddToWishlist>>
>;

export type UserControllerAddToWishlistMutationError = ErrorType<unknown>;

/**
 * @summary 수강생 > 클래스 찜하기
 */
export const useUserControllerAddToWishlist = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof userControllerAddToWishlist>>,
    TError,
    { courseId: number },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof userControllerAddToWishlist>>,
  TError,
  { courseId: number },
  TContext
> => {
  const mutationOptions =
    getUserControllerAddToWishlistMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary 수강생 > 클래스 찜하기 삭제
 */
export const userControllerRemoveFromWishlist = (
  courseId: number,
  options?: SecondParameter<typeof axiosInstance>,
) => {
  return axiosInstance<void>(
    { url: `/user/me/course/wishlist/${courseId}`, method: "DELETE" },
    options,
  );
};

export const getUserControllerRemoveFromWishlistMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof userControllerRemoveFromWishlist>>,
    TError,
    { courseId: number },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof userControllerRemoveFromWishlist>>,
  TError,
  { courseId: number },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof userControllerRemoveFromWishlist>>,
    { courseId: number }
  > = (props) => {
    const { courseId } = props ?? {};

    return userControllerRemoveFromWishlist(courseId, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type UserControllerRemoveFromWishlistMutationResult = NonNullable<
  Awaited<ReturnType<typeof userControllerRemoveFromWishlist>>
>;

export type UserControllerRemoveFromWishlistMutationError = ErrorType<unknown>;

/**
 * @summary 수강생 > 클래스 찜하기 삭제
 */
export const useUserControllerRemoveFromWishlist = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof userControllerRemoveFromWishlist>>,
    TError,
    { courseId: number },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof userControllerRemoveFromWishlist>>,
  TError,
  { courseId: number },
  TContext
> => {
  const mutationOptions =
    getUserControllerRemoveFromWishlistMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary 수강생 > 클래스 찜하기 여부 조회
 */
export const userControllerGetCourseWishlistByCourseId = (
  courseId: number,
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal,
) => {
  return axiosInstance<CourseWishlistResponseDto>(
    { url: `/user/me/course/wishlist/${courseId}`, method: "GET", signal },
    options,
  );
};

export const getUserControllerGetCourseWishlistByCourseIdQueryKey = (
  courseId: number,
) => {
  return [`/user/me/course/wishlist/${courseId}`] as const;
};

export const getUserControllerGetCourseWishlistByCourseIdQueryOptions = <
  TData = Awaited<ReturnType<typeof userControllerGetCourseWishlistByCourseId>>,
  TError = ErrorType<CourseWishlistResponseDto>,
>(
  courseId: number,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof userControllerGetCourseWishlistByCourseId>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getUserControllerGetCourseWishlistByCourseIdQueryKey(courseId);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof userControllerGetCourseWishlistByCourseId>>
  > = ({ signal }) =>
    userControllerGetCourseWishlistByCourseId(courseId, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!courseId,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof userControllerGetCourseWishlistByCourseId>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type UserControllerGetCourseWishlistByCourseIdQueryResult = NonNullable<
  Awaited<ReturnType<typeof userControllerGetCourseWishlistByCourseId>>
>;
export type UserControllerGetCourseWishlistByCourseIdQueryError =
  ErrorType<CourseWishlistResponseDto>;

/**
 * @summary 수강생 > 클래스 찜하기 여부 조회
 */
export const useUserControllerGetCourseWishlistByCourseId = <
  TData = Awaited<ReturnType<typeof userControllerGetCourseWishlistByCourseId>>,
  TError = ErrorType<CourseWishlistResponseDto>,
>(
  courseId: number,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof userControllerGetCourseWishlistByCourseId>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getUserControllerGetCourseWishlistByCourseIdQueryOptions(
    courseId,
    options,
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary 수강생의 내 스케쥴 조회
 */
export const userControllerSchedules = (
  params?: UserControllerSchedulesParams,
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal,
) => {
  return axiosInstance<StudentScheduleResponseDto[]>(
    { url: `/user/me/schedule`, method: "GET", params, signal },
    options,
  );
};

export const getUserControllerSchedulesQueryKey = (
  params?: UserControllerSchedulesParams,
) => {
  return [`/user/me/schedule`, ...(params ? [params] : [])] as const;
};

export const getUserControllerSchedulesQueryOptions = <
  TData = Awaited<ReturnType<typeof userControllerSchedules>>,
  TError = ErrorType<StudentScheduleResponseDto[]>,
>(
  params?: UserControllerSchedulesParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof userControllerSchedules>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getUserControllerSchedulesQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof userControllerSchedules>>
  > = ({ signal }) => userControllerSchedules(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof userControllerSchedules>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type UserControllerSchedulesQueryResult = NonNullable<
  Awaited<ReturnType<typeof userControllerSchedules>>
>;
export type UserControllerSchedulesQueryError = ErrorType<
  StudentScheduleResponseDto[]
>;

/**
 * @summary 수강생의 내 스케쥴 조회
 */
export const useUserControllerSchedules = <
  TData = Awaited<ReturnType<typeof userControllerSchedules>>,
  TError = ErrorType<StudentScheduleResponseDto[]>,
>(
  params?: UserControllerSchedulesParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof userControllerSchedules>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getUserControllerSchedulesQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary 내 포인트 조회
 */
export const userControllerPointInfo = (
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal,
) => {
  return axiosInstance<UserPointResponseDto>(
    { url: `/user/me/point`, method: "GET", signal },
    options,
  );
};

export const getUserControllerPointInfoQueryKey = () => {
  return [`/user/me/point`] as const;
};

export const getUserControllerPointInfoQueryOptions = <
  TData = Awaited<ReturnType<typeof userControllerPointInfo>>,
  TError = ErrorType<UserPointResponseDto>,
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof userControllerPointInfo>>,
    TError,
    TData
  >;
  request?: SecondParameter<typeof axiosInstance>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getUserControllerPointInfoQueryKey();

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof userControllerPointInfo>>
  > = ({ signal }) => userControllerPointInfo(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof userControllerPointInfo>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type UserControllerPointInfoQueryResult = NonNullable<
  Awaited<ReturnType<typeof userControllerPointInfo>>
>;
export type UserControllerPointInfoQueryError = ErrorType<UserPointResponseDto>;

/**
 * @summary 내 포인트 조회
 */
export const useUserControllerPointInfo = <
  TData = Awaited<ReturnType<typeof userControllerPointInfo>>,
  TError = ErrorType<UserPointResponseDto>,
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof userControllerPointInfo>>,
    TError,
    TData
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getUserControllerPointInfoQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary 내 포인트 증감 내역 조회(with 페이지네이션)
 */
export const userControllerPointHistory = (
  params?: UserControllerPointHistoryParams,
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal,
) => {
  return axiosInstance<PaginatedUserPointLogsResponseDto>(
    { url: `/user/me/point/history`, method: "GET", params, signal },
    options,
  );
};

export const getUserControllerPointHistoryQueryKey = (
  params?: UserControllerPointHistoryParams,
) => {
  return [`/user/me/point/history`, ...(params ? [params] : [])] as const;
};

export const getUserControllerPointHistoryQueryOptions = <
  TData = Awaited<ReturnType<typeof userControllerPointHistory>>,
  TError = ErrorType<PaginatedUserPointLogsResponseDto>,
>(
  params?: UserControllerPointHistoryParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof userControllerPointHistory>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getUserControllerPointHistoryQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof userControllerPointHistory>>
  > = ({ signal }) =>
    userControllerPointHistory(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof userControllerPointHistory>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type UserControllerPointHistoryQueryResult = NonNullable<
  Awaited<ReturnType<typeof userControllerPointHistory>>
>;
export type UserControllerPointHistoryQueryError =
  ErrorType<PaginatedUserPointLogsResponseDto>;

/**
 * @summary 내 포인트 증감 내역 조회(with 페이지네이션)
 */
export const useUserControllerPointHistory = <
  TData = Awaited<ReturnType<typeof userControllerPointHistory>>,
  TError = ErrorType<PaginatedUserPointLogsResponseDto>,
>(
  params?: UserControllerPointHistoryParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof userControllerPointHistory>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getUserControllerPointHistoryQueryOptions(
    params,
    options,
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary 내 문의 내역 조회(with 페이지네이션)
 */
export const userControllerInquiries = (
  params?: UserControllerInquiriesParams,
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal,
) => {
  return axiosInstance<InquiriesListResponseDto>(
    { url: `/user/me/inquiry`, method: "GET", params, signal },
    options,
  );
};

export const getUserControllerInquiriesQueryKey = (
  params?: UserControllerInquiriesParams,
) => {
  return [`/user/me/inquiry`, ...(params ? [params] : [])] as const;
};

export const getUserControllerInquiriesQueryOptions = <
  TData = Awaited<ReturnType<typeof userControllerInquiries>>,
  TError = ErrorType<InquiriesListResponseDto>,
>(
  params?: UserControllerInquiriesParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof userControllerInquiries>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getUserControllerInquiriesQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof userControllerInquiries>>
  > = ({ signal }) => userControllerInquiries(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof userControllerInquiries>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type UserControllerInquiriesQueryResult = NonNullable<
  Awaited<ReturnType<typeof userControllerInquiries>>
>;
export type UserControllerInquiriesQueryError =
  ErrorType<InquiriesListResponseDto>;

/**
 * @summary 내 문의 내역 조회(with 페이지네이션)
 */
export const useUserControllerInquiries = <
  TData = Awaited<ReturnType<typeof userControllerInquiries>>,
  TError = ErrorType<InquiriesListResponseDto>,
>(
  params?: UserControllerInquiriesParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof userControllerInquiries>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getUserControllerInquiriesQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary 유저 정보 수정
 */
export const userControllerModifyUserInfo = (
  updateUserRequestWithNewPasswordDto: UpdateUserRequestWithNewPasswordDto,
  options?: SecondParameter<typeof axiosInstance>,
) => {
  return axiosInstance<UserResponseDto>(
    {
      url: `/user`,
      method: "PATCH",
      headers: { "Content-Type": "application/json" },
      data: updateUserRequestWithNewPasswordDto,
    },
    options,
  );
};

export const getUserControllerModifyUserInfoMutationOptions = <
  TError = ErrorType<UserResponseDto>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof userControllerModifyUserInfo>>,
    TError,
    { data: UpdateUserRequestWithNewPasswordDto },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof userControllerModifyUserInfo>>,
  TError,
  { data: UpdateUserRequestWithNewPasswordDto },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof userControllerModifyUserInfo>>,
    { data: UpdateUserRequestWithNewPasswordDto }
  > = (props) => {
    const { data } = props ?? {};

    return userControllerModifyUserInfo(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type UserControllerModifyUserInfoMutationResult = NonNullable<
  Awaited<ReturnType<typeof userControllerModifyUserInfo>>
>;
export type UserControllerModifyUserInfoMutationBody =
  UpdateUserRequestWithNewPasswordDto;
export type UserControllerModifyUserInfoMutationError =
  ErrorType<UserResponseDto>;

/**
 * @summary 유저 정보 수정
 */
export const useUserControllerModifyUserInfo = <
  TError = ErrorType<UserResponseDto>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof userControllerModifyUserInfo>>,
    TError,
    { data: UpdateUserRequestWithNewPasswordDto },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof userControllerModifyUserInfo>>,
  TError,
  { data: UpdateUserRequestWithNewPasswordDto },
  TContext
> => {
  const mutationOptions =
    getUserControllerModifyUserInfoMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary 회원 탈퇴
 */
export const userControllerWithdrawUser = (
  createWithdrawRequestDto: CreateWithdrawRequestDto,
  options?: SecondParameter<typeof axiosInstance>,
) => {
  return axiosInstance<void>(
    {
      url: `/user/withdraw`,
      method: "POST",
      headers: { "Content-Type": "application/json" },
      data: createWithdrawRequestDto,
    },
    options,
  );
};

export const getUserControllerWithdrawUserMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof userControllerWithdrawUser>>,
    TError,
    { data: CreateWithdrawRequestDto },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof userControllerWithdrawUser>>,
  TError,
  { data: CreateWithdrawRequestDto },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof userControllerWithdrawUser>>,
    { data: CreateWithdrawRequestDto }
  > = (props) => {
    const { data } = props ?? {};

    return userControllerWithdrawUser(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type UserControllerWithdrawUserMutationResult = NonNullable<
  Awaited<ReturnType<typeof userControllerWithdrawUser>>
>;
export type UserControllerWithdrawUserMutationBody = CreateWithdrawRequestDto;
export type UserControllerWithdrawUserMutationError = ErrorType<unknown>;

/**
 * @summary 회원 탈퇴
 */
export const useUserControllerWithdrawUser = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof userControllerWithdrawUser>>,
    TError,
    { data: CreateWithdrawRequestDto },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof userControllerWithdrawUser>>,
  TError,
  { data: CreateWithdrawRequestDto },
  TContext
> => {
  const mutationOptions = getUserControllerWithdrawUserMutationOptions(options);

  return useMutation(mutationOptions);
};

import styled from "@emotion/styled";
import {
  CourseInDetailResponseDto,
  CycleResponseDto,
} from "@shared/generated/api/model";
import { Icon } from "@shared/lib/components/common/atom/icon";
import { ICON_SVG } from "@shared/lib/constants/import/icon-svg";
import CalendarIcon from "@shared/lib/assets/images/icons/line/16/line-calender.svg";
import CheckCalendarIcon from "@shared/lib/assets/images/icons/line/16/line-calender-check.svg";
import { convertCourseType } from "@shared/lib/utils/classData";
import { useRouter } from "next/router";
import {
  discountedTotalPrice,
  priceToLocaleString,
} from "@shared/lib/utils/price";
import { formatDate } from "@shared/lib/utils/date/formatSchedule";

interface OrderProps {
  courseInfo: CourseInDetailResponseDto;
  cycleInfo: CycleResponseDto;
}

export const CourseContent = ({ courseInfo, cycleInfo }: OrderProps) => {
  const router = useRouter();
  const { id, title, type, price, discountRate } = courseInfo;
  const { startsAt } = cycleInfo;

  return (
    <CourseContainer>
      <TitleWrapper>
        <CourseTitle>{title}</CourseTitle>
        <DetailLink>
          {/* TODO: 강의 카테고리 정해져야 이동 구현 가능 */}
          {/* <DetailLink onClick={() => router.push(`/id`)}> */}
          상세보기 <Icon icon={ICON_SVG.LINE.SIZE_16.ICON_ARROW_RIGHT} />
        </DetailLink>
      </TitleWrapper>
      <InfoWrapper>
        <InfoSection>
          <IconTitle>
            <CalendarIcon />
            강의 유형
          </IconTitle>
          <ContentText isType={true}>
            LIVE {convertCourseType(type)}
          </ContentText>
        </InfoSection>
        <InfoSection>
          <IconTitle>
            <CheckCalendarIcon />
            선택 스케줄
          </IconTitle>
          <ContentText>
            {formatDate(new Date(startsAt), { withTime: true })}
          </ContentText>
        </InfoSection>
      </InfoWrapper>
      {price && (
        <PriceWrapper>
          <CancelledText>{`${priceToLocaleString(price)}원`}</CancelledText>
          <PriceText>{`${discountedTotalPrice(price, discountRate)}원`}</PriceText>
        </PriceWrapper>
      )}
    </CourseContainer>
  );
};

const CourseContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

const CourseTitle = styled.div`
  ${({ theme }) => theme.typography.h3.m}
`;

const DetailLink = styled.div`
  ${({ theme }) => theme.typography.body2.b}
  color: ${({ theme }) => theme.colors.text.medium.black};
  display: flex;
  height: 100%;
  align-items: center;
  gap: 2px;
`;

const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const InfoSection = styled.div`
  display: flex;
  gap: 8px;
`;

const IconTitle = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  width: 90px;
  ${({ theme }) => theme.typography.body2.m};
  color: ${({ theme }) => theme.colors.icon.inactive.black};
  img {
    path {
      stroke: ${({ theme }) => theme.colors.icon.inactive.black};
    }
  }
`;

const ContentText = styled.div<{ isType?: boolean | false }>`
  ${({ theme }) => theme.typography.body2.m};
  color: ${({ theme, isType }) =>
    isType ? theme.colors.sub.orange.default : theme.colors.icon.active.black};
`;

const PriceWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const CancelledText = styled.s`
  ${({ theme }) => theme.typography.body1.m};
  color: ${({ theme }) => theme.colors.text.medium.black};
  display: flex;
`;

const PriceText = styled.div`
  ${({ theme }) => theme.typography.h3.b};
  color: ${({ theme }) => theme.colors.text.high.black};
`;

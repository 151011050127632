import axios from "axios";

export const uploadFileToGCP = async (presignedUrl: string, file: File) => {
  const { status } = await axios.put(presignedUrl, file, {
    headers: {
      "Content-Type": file.type,
    },
  });

  if (status !== 200) {
    throw new Error("Failed to upload image");
  }
  return status;
};

import { forwardRef, InputHTMLAttributes, useMemo } from "react";
import classNames from "classnames";
import styled from "@emotion/styled";

import { Icon } from "@shared/lib/components/common/atom/icon";
import { ICON_SVG } from "@shared/lib/constants/import/icon-svg";

interface CheckBoxProps extends InputHTMLAttributes<HTMLInputElement> {
  isError?: boolean;
}
export const CheckBox = forwardRef<HTMLInputElement, CheckBoxProps>(
  (props, checkboxRef) => {
    const { children, checked, disabled, className, isError, ...inputProps } =
      props;

    const icon = useMemo(() => {
      if (isError) {
        return ICON_SVG.GRAPHIC.CHECK_BOX.ICON_OUTLINE_ERROR;
      }

      if (checked) {
        return ICON_SVG.GRAPHIC.CHECK_BOX.ICON_OUTLINE_CHECKED;
      }

      return ICON_SVG.GRAPHIC.CHECK_BOX.ICON_OUTLINE_DEFAULT;
    }, [checked, isError]);

    return (
      <CheckBoxContainer
        className={classNames(
          {
            error: isError,
            checked: checked,
          },
          className,
        )}
      >
        <HiddenCheckBox
          {...inputProps}
          ref={checkboxRef}
          checked={checked}
          disabled={disabled}
          type={"checkbox"}
        />

        <Icon icon={icon} />
      </CheckBoxContainer>
    );
  },
);

const CheckBoxContainer = styled.div`
  cursor: pointer;
  vertical-align: middle;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;

  width: 24px;
  height: 24px;

  svg {
    width: 100%;
    height: 100%;
  }
`;
const HiddenCheckBox = styled.input`
  cursor: pointer;
  opacity: 0;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 1;
`;

// import { useEffect, useState } from "react";
import styled from "@emotion/styled";

// import { Translation } from "@/components/domain/demo/translation";

// import { usePostKacZoomMeeting } from "@shared/generated/api/fn/kac/zoom/zoom";

export const DemoTemplate = () => {
  // const name = "1차 스케줄";
  // const password = "1234";
  // const createZoomMeetingMutation = usePostKacZoomMeeting();

  // const [meetingId, setMeetingId] = useState<string>();

  // useEffect(() => {
  //   const initZoom = async () => {
  //     const ZoomEmbed = await (
  //       await import("@zoom/meetingsdk/embedded")
  //     ).default;

  //     const client = ZoomEmbed.createClient();
  //     const meetingSDKElement = document.getElementById("meetingSDKElement")!;

  //     await client.init({
  //       language: "ko-KO",
  //       zoomAppRoot: meetingSDKElement,
  //     });

  //     createZoomMeetingMutation.mutate(
  //       {
  //         data: {
  //           name,
  //           password,
  //         },
  //       },
  //       {
  //         onSuccess: async (data) => {
  //           const meetingId = data.meetingNumber;
  //           setMeetingId(meetingId);
  //           await client.join({
  //             signature: data.signature || "",
  //             sdkKey: "2joEL2YKR0WAhDzx42Hjqw",
  //             meetingNumber: meetingId || "",
  //             password,
  //             userName: "케아클 강사",
  //             userEmail: "admin@kacmediagroup.com",
  //           });
  //         },
  //       },
  //     );
  //   };

  //   initZoom();
  // }, []);

  return (
    <DemoTemplateContainer>
      <div id="meetingSDKElement" />

      {/* {meetingId && <Translation meetingId={meetingId} />} */}
    </DemoTemplateContainer>
  );
};

const DemoTemplateContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  min-height: 50vh;
`;

import {
  BaseModal,
  ClassContent,
  CustomerInfo,
  PointForm,
  PriceContent,
  PurchaseForm,
  PurchaseType,
  Checkout,
  SuccessContent,
} from "@/components";
import { useAccessToken } from "@/hooks/auth/use-access-token";
import { useProfile } from "@/hooks/auth/use-profile";
import { useModalStore } from "@/stores";
import styled from "@emotion/styled";
import { useCourseControllerCourse } from "@shared/generated/api/fn/kac/course/course";
import {
  usePurchaseControllerCompletePurchase,
  usePurchaseControllerInitiatePurchase,
} from "@shared/generated/api/fn/kac/purchase/purchase";
import { CourseInDetailResponseDto } from "@shared/generated/api/model";
import { calculateDiscountedPrice } from "@shared/lib/utils/price";
import { openToast, TOAST_TYPE } from "@shared/lib/utils/toast";
import { useRouter } from "next/router";
import { ChangeEvent, FocusEvent, useEffect, useState } from "react";

export const PaymentTemplate = () => {
  const router = useRouter();
  const { courseId, cycleId } = router.query;
  const [usedPoints, setUsedPoints] = useState<number | null>(null);
  const [finalPrice, setFinalPrice] = useState<number>(0);
  const [checked, setChecked] = useState<boolean>(false);
  const [pointError, setPointError] = useState<string | null>(null);
  const [currentPoints, setCurrentPoints] = useState(0);
  const [orderId, setOrderId] = useState("");
  // const [hasClicked, setHasClicked] = useState(false);
  const [selectedPurchaseType, setSelectedPurchaseType] = useState<
    string | null
  >(null);
  const [customerData, setCustomerData] = useState({
    name: "",
    phoneNumber: "",
    email: "",
  });
  const [purchaseData, setPurchaseData] = useState();

  const { openModal } = useModalStore();
  const { accessToken, isLoading: isTokenLoading } = useAccessToken();
  const { data: profile } = useProfile();
  const { data: classData } = useCourseControllerCourse(Number(courseId));
  const { price, discountRate } =
    (classData as CourseInDetailResponseDto) || {};
  console.log(classData);

  useEffect(() => {
    if (profile) {
      setCustomerData({
        name: profile.name || "",
        phoneNumber: profile.phoneNumber || "",
        email: profile.email || "",
      });
      setCurrentPoints(profile.pointInfo?.currentPoints || 0);
    }
  }, [profile]);

  useEffect(() => {
    // 로딩이 완료되었고, accessToken이 없는 경우 리다이렉트
    if (!isTokenLoading && !accessToken) {
      router.push("/");
    }
  }, [accessToken, isTokenLoading, router]);

  const handleChange =
    (field: keyof typeof customerData) =>
    (event: ChangeEvent<HTMLInputElement>) => {
      setCustomerData((prev) => ({
        ...prev,
        [field]: event.target.value,
      }));
    };

  const onChangeUsedPoints = (event: ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value;
    const numberValue = Number(newValue);

    // 기본 입력값 업데이트
    setUsedPoints(numberValue);

    // 값이 비어 있는 경우
    if (newValue === "0" || newValue === "") {
      setPointError("1,000 포인트 이상 부터 사용 가능합니다.");
      return;
    }

    // 할인된 금액보다 큰 경우
    if (numberValue > calculateDiscountedPrice(price || 0, discountRate)) {
      setPointError(`사용 포인트가 강의 가격보다 큽니다.`);
      return;
    }

    // 최소값보다 작은 경우
    if (numberValue < 1000) {
      setPointError(`1,000 포인트 이상 부터 사용 가능합니다.`);
      return;
    }

    // 사용 가능한 포인트를 초과한 경우
    if (numberValue > currentPoints) {
      setPointError(`사용 포인트가 보유 포인트보다 큽니다.`);
      return;
    }

    // 모든 조건을 통과한 경우
    setPointError(null);
  };

  const onBlurUsedPoints = (event: FocusEvent<HTMLInputElement>) => {
    const newValue = event.target.value;
    const numberValue = Number(newValue);

    if (numberValue < 1000 || numberValue > currentPoints || newValue === "") {
      setUsedPoints(null);
      setPointError(
        newValue === "" || newValue === "0"
          ? "값을 입력하세요."
          : `값은 ${numberValue < 1000 ? "최소 1000 이상" : `최대 ${currentPoints} 이하`}이어야 합니다.`,
      );
    }
  };

  const handleFinalPriceChange = (newPrice: number) => {
    setFinalPrice(newPrice);
  };

  const handlePurchaseType = (type: string) => {
    console.log(type);
    setSelectedPurchaseType((cur) => (cur === type ? null : type));
  };

  const handleCheckedChange = (value: boolean) => {
    setChecked(value);
  };

  const postSuccessPurchase = usePurchaseControllerCompletePurchase({
    request: { accessToken },
    mutation: {
      onSuccess: (data) => {
        setPurchaseData(data as any);
      },
      onError: (error) => {
        // setHasClicked(false);
        console.error(error);
      },
    },
  });

  const postPurchase = usePurchaseControllerInitiatePurchase({
    request: { accessToken },
    mutation: {
      onSuccess: (data: any) => {
        console.log(data);
        const { purchaseId, allPointPay, paymentInfo } = data;
        paymentInfo && setOrderId(paymentInfo.orderId);
        allPointPay
          ? postSuccessPurchase.mutate({ purchaseId: purchaseId })
          : openModal("tossPaymentsModal");
      },
      onError: (error) => {
        // setHasClicked(false);
        console.error(error);
        const { errorCode } = error.response?.data as any;
        if (errorCode === 100023) {
          openToast(TOAST_TYPE.ERROR, "이미 마감된 강의입니다.");
          router.push("/");
        } else if (errorCode === 100025) {
          openToast(TOAST_TYPE.ERROR, "이미 구매한 강의입니다.");
          // setHasClicked(true);
          router.push("/");
        } else if (errorCode === 100026) {
          openToast(TOAST_TYPE.ERROR, "이미 완료된 결제입니다.");
          router.push("/");
        }
      },
    },
  });

  const handlePurchase = () => {
    // setHasClicked(true);
    const data = {
      courseId: Number(courseId),
      cycleId: Number(cycleId),
      amount: price ?? 0,
      discountRate,
      usedPoints: usedPoints || 0,
      paidAmount: finalPrice,
      ordererEmail: customerData.email,
      ordererName: customerData.name,
      ordererPhoneNumber: customerData.phoneNumber,
    };
    console.log(data);

    if (accessToken) {
      postPurchase.mutate({ data });
    }
  };

  return (
    <PaymentPageContainer>
      {purchaseData ? (
        <PaymentContentWrapper>
          <SuccessContent PurchaseData={purchaseData} />
        </PaymentContentWrapper>
      ) : (
        <>
          <BaseModal
            modalKey="tossPaymentsModal"
            backgroundColor="#fff"
          >
            <Checkout
              orderId={orderId}
              customerData={customerData}
              classTitle={classData?.title || ""}
              finalPrice={finalPrice}
            />
          </BaseModal>
          <PaymentContentWrapper>
            <PaymentTitle>결제하기</PaymentTitle>
            <CardWrapper>
              {classData && <ClassContent classData={classData} />}
              <CustomerInfo
                customerData={customerData}
                onChange={handleChange}
              />
              <PointForm
                currentPoints={currentPoints || 0}
                value={usedPoints}
                error={pointError}
                onChange={onChangeUsedPoints}
                onBlur={onBlurUsedPoints}
                min={1000}
                max={currentPoints}
              />
              {classData && (
                <PriceContent
                  usedPoints={usedPoints || 0}
                  price={price || 0}
                  discountRate={discountRate || 0}
                  onFinalPriceChange={handleFinalPriceChange}
                />
              )}
              <PurchaseType
                selectedPurchaseType={selectedPurchaseType}
                handlePurchaseType={handlePurchaseType}
              />
              <PurchaseForm
                handlePurchase={handlePurchase}
                disabled={
                  !!pointError ||
                  !checked ||
                  selectedPurchaseType === null ||
                  (selectedPurchaseType === "point" &&
                    usedPoints ===
                      calculateDiscountedPrice(price || 0, discountRate)) ||
                  postPurchase.isPending
                }
                checked={checked}
                onCheckedChange={handleCheckedChange}
              />
            </CardWrapper>
          </PaymentContentWrapper>
        </>
      )}
    </PaymentPageContainer>
  );
};

const PaymentPageContainer = styled.div`
  width: 100%;
  padding: 48px 0 120px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  background: ${({ theme }) => theme.colors.brand.primary.default};
`;

const PaymentContentWrapper = styled.div`
  width: 100%;
  max-width: 720px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  ${({ theme }) => theme.media.tabletSm} {
    padding: 0 16px;
  }
`;

const CardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
`;

const PaymentTitle = styled.div`
  ${({ theme }) => theme.typography.h1.b};
  color: ${({ theme }) => theme.colors.text.high.white};
  padding-bottom: 40px;
`;

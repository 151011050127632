import { useEffect, useState } from "react";
import { loadTossPayments } from "@tosspayments/tosspayments-sdk";

//TODO: 토스트페이먼츠 예제를 가져온 소스이니 개발할때 변경해야됨

// ------  SDK 초기화 ------
// TODO: clientKey는 개발자센터의 API 개별 연동 키 > 결제창 연동에 사용하려할 MID > 클라이언트 키로 바꾸세요.
// TODO: server.js 의 secretKey 또한 결제위젯 연동 키가 아닌 API 개별 연동 키의 시크릿 키로 변경해야 합니다.
// TODO: 구매자의 고유 아이디를 불러와서 customerKey로 설정하세요. 이메일・전화번호와 같이 유추가 가능한 값은 안전하지 않습니다.
// @docs https://docs.tosspayments.com/reference/widget-sdk#sdk-설치-및-초기화
const clientKey = "test_ck_N5OWRapdA8dbwLJy01BVo1zEqZKL";
const customerKey = generateRandomString();

const amount = {
  currency: "KRW",
  value: 50_000,
};

export function TossPayment() {
  const [payment, setPayment] = useState<any>(null);

  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState<any>(null);

  function selectPaymentMethod(method: string) {
    setSelectedPaymentMethod(method);
  }

  useEffect(() => {
    async function fetchPayment() {
      try {
        const tossPayments = await loadTossPayments(clientKey);

        // 회원 결제
        const payment = tossPayments.payment({
          customerKey: customerKey || "",
        });
        // 비회원 결제
        // const payment = tossPayments.payment({customerKey: TossPayments.ANONYMOUS});

        setPayment(payment);
      } catch (error) {
        console.error("Error fetching payment:", error);
      }
    }

    fetchPayment();
  }, [clientKey, customerKey]);

  // ------ '결제하기' 버튼 누르면 결제창 띄우기 ------
  // @docs https://docs.tosspayments.com/reference/widget-sdk#requestpayment결제-정보
  async function requestPayment() {
    // 결제를 요청하기 전에 orderId, amount를 서버에 저장하세요.
    // 결제 과정에서 악의적으로 결제 금액이 바뀌는 것을 확인하는 용도입니다.
    if (selectedPaymentMethod === "CARD") {
      await payment.requestPayment({
        method: "CARD",
        amount,
        orderId: generateRandomString(),
        orderName: "토스 티셔츠 외 2건",
        successUrl: window.location.origin + "/success",
        failUrl: window.location.origin + "/fail",
        customerEmail: "customer123@gmail.com",
        customerName: "김토스",
        customerMobilePhone: "01012341234",
        card: {
          useEscrow: false,
          flowMode: "DEFAULT",
          useCardPoint: false,
          useAppCardOnly: false,
        },
      });
    } else if (selectedPaymentMethod === "TRANSFER") {
      await payment.requestPayment({
        method: "TRANSFER",
        amount,
        orderId: generateRandomString(),
        orderName: "토스 티셔츠 외 2건",
        successUrl: window.location.origin + "/success",
        failUrl: window.location.origin + "/fail",
        customerEmail: "customer123@gmail.com",
        customerName: "김토스",
        customerMobilePhone: "01012341234",
        transfer: {
          cashReceipt: {
            type: "소득공제",
          },
          useEscrow: false,
        },
      });
    } else if (selectedPaymentMethod === "VIRTUAL_ACCOUNT") {
      await payment.requestPayment({
        method: "VIRTUAL_ACCOUNT",
        amount,
        orderId: generateRandomString(),
        orderName: "토스 티셔츠 외 2건",
        successUrl: window.location.origin + "/success",
        failUrl: window.location.origin + "/fail",
        customerEmail: "customer123@gmail.com",
        customerName: "김토스",
        customerMobilePhone: "01012341234",
        virtualAccount: {
          cashReceipt: {
            type: "소득공제",
          },
          useEscrow: false,
          validHours: 24,
        },
      });
    } else if (selectedPaymentMethod === "MOBILE_PHONE") {
      await payment.requestPayment({
        method: "MOBILE_PHONE",
        amount,
        orderId: generateRandomString(),
        orderName: "토스 티셔츠 외 2건",
        successUrl: window.location.origin + "/success",
        failUrl: window.location.origin + "/fail",
        customerEmail: "customer123@gmail.com",
        customerName: "김토스",
        customerMobilePhone: "01012341234",
      });
    } else if (selectedPaymentMethod === "CULTURE_GIFT_CERTIFICATE") {
      await payment.requestPayment({
        method: "CULTURE_GIFT_CERTIFICATE",
        amount,
        orderId: generateRandomString(),
        orderName: "토스 티셔츠 외 2건",
        successUrl: window.location.origin + "/success",
        failUrl: window.location.origin + "/fail",
        customerEmail: "customer123@gmail.com",
        customerName: "김토스",
        customerMobilePhone: "01012341234",
      });
    } else if (selectedPaymentMethod === "FOREIGN_EASY_PAY") {
      await payment.requestPayment({
        method: "FOREIGN_EASY_PAY",
        amount: {
          value: 100,
          currency: "USD",
        },
        orderId: generateRandomString(),
        orderName: "토스 티셔츠 외 2건",
        successUrl: window.location.origin + "/success",
        failUrl: window.location.origin + "/fail",
        customerEmail: "customer123@gmail.com",
        customerName: "김토스",
        customerMobilePhone: "01012341234",
        foreignEasyPay: {
          provider: "PAYPAL",
          country: "US",
        },
      });
    }
  }

  async function requestBillingAuth() {
    await payment.requestBillingAuth({
      method: "CARD",
      successUrl: window.location.origin + "/payment/billing",
      failUrl: window.location.origin + "/fail",
      customerEmail: "customer123@gmail.com",
      customerName: "김토스",
    });
  }

  return (
    <div className="wrapper">
      <div className="box_section">
        <h1>일반 결제</h1>
        <div
          id="payment-method"
          style={{ display: "flex" }}
        >
          <button
            id="CARD"
            className={`button2 ${
              selectedPaymentMethod === "CARD" ? "active" : ""
            }`}
            onClick={() => selectPaymentMethod("CARD")}
          >
            카드
          </button>
          <button
            id="TRANSFER"
            className={`button2 ${
              selectedPaymentMethod === "TRANSFER" ? "active" : ""
            }`}
            onClick={() => selectPaymentMethod("TRANSFER")}
          >
            계좌이체
          </button>
          <button
            id="VIRTUAL_ACCOUNT"
            className={`button2 ${
              selectedPaymentMethod === "VIRTUAL_ACCOUNT" ? "active" : ""
            }`}
            onClick={() => selectPaymentMethod("VIRTUAL_ACCOUNT")}
          >
            가상계좌
          </button>
          <button
            id="MOBILE_PHONE"
            className={`button2 ${
              selectedPaymentMethod === "MOBILE_PHONE" ? "active" : ""
            }`}
            onClick={() => selectPaymentMethod("MOBILE_PHONE")}
          >
            휴대폰
          </button>
          <button
            id="CULTURE_GIFT_CERTIFICATE"
            className={`button2 ${
              selectedPaymentMethod === "CULTURE_GIFT_CERTIFICATE"
                ? "active"
                : ""
            }`}
            onClick={() => selectPaymentMethod("CULTURE_GIFT_CERTIFICATE")}
          >
            문화상품권
          </button>
          <button
            id="FOREIGN_EASY_PAY"
            className={`button2 ${
              selectedPaymentMethod === "FOREIGN_EASY_PAY" ? "active" : ""
            }`}
            onClick={() => selectPaymentMethod("FOREIGN_EASY_PAY")}
          >
            해외간편결제
          </button>
        </div>
        <button
          className="button"
          onClick={() => requestPayment()}
        >
          결제하기
        </button>
      </div>
      <div className="box_section">
        <h1>정기 결제</h1>
        <button
          className="button"
          onClick={() => requestBillingAuth()}
        >
          빌링키 발급하기
        </button>
      </div>
    </div>
  );
}

function generateRandomString() {
  if (typeof window !== "undefined") {
    return window.btoa(Math.random().toString()).slice(0, 20);
  }
}

import { CourseTypeEnum } from "@shared/generated/api/model";

export const calculateDiscountedPrice = (
  price: number,
  discountRate: number,
): number => {
  return price * (1 - discountRate);
};

export const priceToLocaleString = (price: number): string => {
  return price.toLocaleString("ko-KR");
};

export const convertPriceString = (
  price: number | null,
  discountRate: number,
  type: CourseTypeEnum,
  totalSessions: number | null,
): string | null => {
  // 가격 또는 총 세션 수가 없는 경우 null 반환
  if (!price || (type === CourseTypeEnum.MULTI && !totalSessions)) return null;

  const isSingle = type === CourseTypeEnum.SINGLE;

  // SINGLE 타입의 경우 총 가격만 계산
  if (isSingle) {
    return discountRate === 0
      ? priceToLocaleString(price)
      : priceToLocaleString(calculateDiscountedPrice(price, discountRate));
  }

  // MULTI 타입의 경우 회당 가격 계산
  const perSessionPrice = price / (totalSessions as number);
  return discountRate === 0
    ? priceToLocaleString(perSessionPrice)
    : priceToLocaleString(
        calculateDiscountedPrice(perSessionPrice, discountRate),
      );
};

export const discountedTotalPrice = (price: number, discountRate: number) => {
  return discountRate === 0
    ? null
    : priceToLocaleString(calculateDiscountedPrice(price, discountRate));
};

// point사용 금액까지 반영한 최종 가격 계산
export const calculateFinalPrice = (
  price: number,
  discountRate: number,
  pointsToUse: number | null,
) => {
  if (discountRate === 0) {
    return null;
  } else {
    const discountedPrice = price - price * discountRate;
    const finalPrice = pointsToUse
      ? discountedPrice - pointsToUse
      : discountedPrice;
    return finalPrice;
  }
};

import { useQuery } from "@tanstack/react-query";
import { axiosInstance } from "@/config/axios";
import { useAccessToken } from "@/hooks/auth/use-access-token";
import { useState, useEffect } from "react";
import { PaginatedCoursesResponseDto } from "@shared/generated/api/model";

const fetchMyWishlistCourses = async (
  accessToken: string,
): Promise<PaginatedCoursesResponseDto> => {
  const { data } = await axiosInstance.get("/user/me/course/wishlist", {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });

  return data.body;
};

export const useMyWishlistCourses = () => {
  const { accessToken } = useAccessToken();
  const [isClient, setIsClient] = useState(false);

  useEffect(() => {
    setIsClient(true);
  }, []);

  return useQuery({
    queryKey: ["myWishlistCourse", accessToken],
    queryFn: () => fetchMyWishlistCourses(accessToken!),
    staleTime: 5 * 60 * 1000,
    enabled: isClient && !!accessToken,
  });
};

export const IMAGES_ACCEPT = [
  ".png",
  ".jpg",
  ".jpeg",
  ".bmp",
  ".webp",
  ".svg",
  ".ico",
  ".tif",
  ".tiff",
];

export const DOCUMENT_ACCEPT = [
  ".pdf",
  ".hwp",
  ".txt",
  ".doc",
  ".docx",
  ".xls",
  ".xlsx",
  ".ppt",
  ".pptx",
];

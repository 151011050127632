import { create } from "zustand";

export enum InquiryType {
  Schedule = "schedule",
  Class = "class",
}

interface InquiryState {
  inquiryType: InquiryType | null;
  setInquiryType: (type: InquiryType) => void;
}

export const useInquiryTypeStore = create<InquiryState>((set) => ({
  inquiryType: null,
  setInquiryType: (type) => set({ inquiryType: type }),
}));
